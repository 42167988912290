import select from 'vtree-select';
import deepcopy from 'clone';
import h from 'virtual-dom/h';

import Component from './Component';
import virtualize from './virtualize';
import { setVnodeClasses } from './set-vnode-classes';
import { format, gettext } from '../utils/filters';

import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';

function getState(formName) {
  return {
    validFields: FormStore.validFields(formName),
    formState: FormStore.currentFormState(formName),
    showValidationStatus: FormStore.showValidationStatus(formName),
    formSubmitting: FormStore.formIsSubmitting(formName),
    invalidFieldCount: FormStore.invalidFieldCount(formName),
    showFormError: FormStore.showFormError(formName),
    formErrorTitle: FormStore.errorTitle(formName),
  };
}

export class SimpleForm extends Component {
  constructor(element, options = {}) {
    super(element, options);

    this.template = virtualize(element);
    this.formName = element.getAttribute('data-form-name');

    this.addEventListener(
      'change',
      '[data-target="field-focusable"]',
      this.fieldChanged.bind(this)
    );
    this.addEventListener('input', '[data-target="field-focusable"]', this.fieldChanged.bind(this));
    this.addEventListener('blur', '[data-target="field-focusable"]', this.fieldBlur.bind(this));
    this._onChange = this._onChange.bind(this);
    this._onChange();
  }

  onSubmission(event) {
    event.preventDefault();
    FormActions.showError(this.formName);
    return this.state.invalidFieldCount === 0;
  }

  fieldChanged(event) {
    event.preventDefault && event.preventDefault();
    const { name, type, value, required } = event.target;
    FormActions.validate(this.formName, name, type, value, false, required);
  }

  fieldBlur(event) {
    const { name, type, value } = event.target;
    FormActions.showValidationStatus(this.formName, name, type, value);
  }

  componentHasMounted() {
    FormStore.addChangeListener(this._onChange);
    this.initialiseFields();
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this._onChange);
  }

  _onChange(childState = {}) {
    const newState = Object.assign(getState(this.formName), childState);
    this.setState(newState, false);
  }

  initialiseFields() {
    const fieldNames = [];
    const fieldContents = [];
    const fieldTypes = [];
    const fieldRequired = [];

    const focusableFields = select('[data-target="field-focusable"]')(this.template) || [];

    for (const field of focusableFields) {
      const element = this.rootNode.querySelector(
        `[data-target="field-focusable"][name="${field.properties.name}"]`
      );
      const { name, type, required } = element;
      let { value } = element;

      if (type === 'radio') {
        const checkedRadioElement = this.rootNode.querySelector(
          `[data-target="field-focusable"][name="${field.properties.name}"]:checked`
        );
        value = checkedRadioElement.value;
      }

      fieldNames.push(name);
      fieldContents.push(value);
      fieldTypes.push(type);
      fieldRequired.push(required);
    }

    FormActions.bulkValidate(this.formName, fieldNames, fieldTypes, fieldContents, fieldRequired);
  }

  render(template = this.template) {
    const vhtml = deepcopy(template, false);

    const validation = this.state.validFields;
    const showValidation = this.state.showValidationStatus;
    const formState = this.state.formState;

    const formErrorMessage = select('[data-target="form-error"]')(vhtml) || [];
    for (const field of formErrorMessage) {
      setVnodeClasses(field, {
        'is-hidden': !this.state.showFormError,
      });

      let errorMessage;
      if (this.state.invalidFieldCount > 0) {
        errorMessage = h(
          'p',
          format(gettext('%d form error(s) below, please fix them before continuing.'), [
            this.state.invalidFieldCount,
          ])
        );
      } else if (this.state.formErrorTitle) {
        errorMessage = h('span', this.state.formErrorTitle);
      }

      if (errorMessage) {
        field.children = [errorMessage];
      }
    }

    const fields = select('[data-target="field"]')(vhtml) || [];
    for (const field of fields) {
      const element = select('[data-target="field-focusable"]')(field);
      if (element) {
        const type = element[0].properties.type;

        if (type !== 'select-one' && type !== 'radio') {
          const fieldName = field.properties.attributes['data-field'];
          const fieldShowValidation = showValidation[fieldName] || this.state.showFormError;

          setVnodeClasses(field, {
            'is-invalid': !validation[fieldName] && fieldShowValidation,
            'is-valid': validation[fieldName] && fieldShowValidation && formState[fieldName],
          });
        }
      }
    }

    const submitButtons = select('[data-target="form-submit"]')(vhtml) || [];

    for (const button of submitButtons) {
      if (this.state.formSubmitting) {
        button.properties.attributes.disabled = 'disabled';
      }
      setVnodeClasses(button, { 'is-loading': this.state.formSubmitting });
    }

    return vhtml;
  }
}
