import window from 'window-shim';
import _ from 'lodash';
import { CountryToAlpha2 } from 'escrow-common-js/dist/constants';

import Constants from '../constants/VerifyUserConstants';
import FormActions from './FormActions';
import FormConstants from '../constants/FormConstants';
import ErrorMessages from '../constants/ErrorMessages';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { postJSON, patchJSON } from '../utils/FetchUtils';
import AuthenticationStore from '../stores/AuthenticationStore';

export class VerifyUserActions {
  initialiseForm(formName) {
    AppDispatcher.handleViewAction({
      actionType: Constants.INITIALISE_FORM,
      formName: formName,
    });
  }

  // Selects either individual or company
  selectKYCMethod(value) {
    AppDispatcher.handleViewAction({
      actionType: Constants.SELECT_KYC_METHOD,
      value: value,
    });
  }

  toggleTier(tier) {
    AppDispatcher.handleViewAction({
      actionType: Constants.VERIFY_TOGGLE_TIER,
      tier: tier,
    });
  }

  _post(url, body) {
    return window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  _patch(url, body) {
    return window.fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  _submitKYC(kycFields) {
    return this._post(window.config.kyc_api_endpoint, kycFields).then((response) => {
      if (response.status !== 201) {
        return window.Promise.reject(ErrorMessages.VERIFY_KYC_FAIL);
      }
      return window.Promise.resolve();
    });
  }

  _submitCustomerDetails(customerID, customerDetails) {
    return this._post(`${window.config.customer_api_endpoint}/${customerID}`, customerDetails).then(
      (response) => {
        if (response.status !== 200) {
          if (response.status === 400) {
            return response.json().then((body) => {
              if (body.Message === 'EmailAlreadyInUse') {
                return window.Promise.reject(ErrorMessages.EMAIL_ALREADY_EXISTS_SIMPLE);
              }
              return window.Promise.reject(ErrorMessages.VERIFY_SUBMISSION_FAIL);
            });
          }
          return window.Promise.reject(ErrorMessages.VERIFY_SUBMISSION_FAIL);
        }
        /* set ISO codes via v4 */
        const v4CustomerDetails = {
          address: {
            country: CountryToAlpha2[customerDetails.PersonalAddress.Country],
            state: customerDetails.PersonalAddress.State,
          },
        };
        if (customerDetails.CompanyAddress) {
          v4CustomerDetails.company = {
            address: {
              country: CountryToAlpha2[customerDetails.CompanyAddress.Country],
              state: customerDetails.CompanyAddress.State,
            },
          };
        }
        return this._patch(`${window.config.v4_api_endpoint}/customer/me`, v4CustomerDetails).then(
          (v4response) =>
            v4response.status !== 200
              ? window.Promise.reject(ErrorMessages.VERIFY_SUBMISSION_FAIL)
              : window.Promise.resolve()
        );
      }
    );
  }

  _submitPassword(customerID, currentPassword, newPassword) {
    return this._post(`${window.config.customer_api_endpoint}/${customerID}/Password`, {
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
    }).then((response) => {
      if (response.status !== 200) {
        return window.Promise.reject(ErrorMessages.PASSWORD_CHANGE_FAILED);
      }
      return window.Promise.resolve();
    });
  }

  submit(formName, customerID, kycFields, customerDetails) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    const submission = this._submitCustomerDetails(customerID, customerDetails).then(() =>
      this._submitKYC(kycFields)
    );

    // This first calls Address/Update, if successful, calls KYCVerification
    // Any failures will propogate an error to the reject handler
    return submission.then(
      () => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
      },
      (errorMessage) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: errorMessage,
        });
      }
    );
  }

  submitCustomerDetails(formName, customerID, customerDetails, otpState = {}) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    let submission = this._submitCustomerDetails(customerID, customerDetails);

    if (otpState.SMSCode && otpState.sent && !otpState.verified && otpState.attempts < 3) {
      submission = this._verifyOTPCode(customerID, otpState.SMSCode, customerDetails.Phone).then(
        () => this._submitCustomerDetails(customerID, customerDetails)
      );
    }

    return submission.then(
      () => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
      },
      (errorMessage) => {
        const smsVerifyErrors = [
          Constants.OTP_SMS_VERIFY_FAILURE,
          Constants.OTP_CODE_MALFORMED,
          Constants.PHONE_VERIFY_UNKNOWN_ERROR,
        ];

        if (
          _.includes(smsVerifyErrors, errorMessage) ||
          (typeof errorMessage === 'object' && _.includes(smsVerifyErrors, errorMessage.error))
        ) {
          this._handleVerifyOTPCodeError(
            formName,
            customerDetails.Phone,
            otpState.SMSCode,
            errorMessage
          );
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
          });
        } else {
          AppDispatcher.handleServerAction({
            actionType: FormConstants.SUBMISSION_FAILURE,
            name: formName,
            title: errorMessage,
          });
        }
      }
    );
  }

  submitKYC(formName, kycFields) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    this._submitKYC(kycFields).then(
      () => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: formName,
        });
      },
      (errorMessage) => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: formName,
          title: errorMessage,
        });
      }
    );
  }

  checkCurrentPassword(formName, fieldName, currentPassword) {
    const customerID = AuthenticationStore.getCustomerID();
    const attributes = {
      form: formName,
      field: fieldName,
      contents: currentPassword,
    };

    this._submitPassword(customerID, currentPassword, currentPassword).then(
      () => {
        AppDispatcher.handleViewAction({
          actionType: FormConstants.FIELD_VALID,
          attributes: attributes,
        });
      },
      () => {
        AppDispatcher.handleViewAction({
          actionType: FormConstants.FIELD_INVALID,
          attributes: attributes,
        });
      }
    );
  }

  submitEditPassword(
    formName,
    customerID,
    currentPassword,
    newPassword,
    handleSuccess,
    handleFailure
  ) {
    AppDispatcher.handleViewAction({
      actionType: FormConstants.SUBMISSION_START,
      name: formName,
    });

    this._submitPassword(customerID, currentPassword, newPassword).then(
      handleSuccess,
      (errorMessage) => {
        handleFailure(errorMessage);
      }
    );
  }

  requestSMSCode(formName, customerID, phoneNumber) {
    AppDispatcher.handleViewAction({
      actionType: Constants.START_OTP_SMS_REQUEST,
      name: formName,
      phoneNumber,
    });
    return postJSON(`${window.config.v4_api_endpoint}/customer/${customerID}/sms_verification`, {
      action: 'phone-verification-send',
      phone: phoneNumber,
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((body) => {
            AppDispatcher.handleViewAction({
              actionType: Constants.OTP_SMS_REQUEST_SUCCESS,
              name: formName,
              phoneNumber,
              expires: body.expires,
            });
          });
        }
        return window.Promise.reject(response);
      })
      .catch((error) => {
        AppDispatcher.handleViewAction({
          actionType: Constants.OTP_SMS_REQUEST_FAILURE,
          phoneNumber,
          name: formName,
          error,
        });
      });
  }

  sendCodeViaPhoneCall(formName, customerID, phoneNumber) {
    AppDispatcher.handleViewAction({
      actionType: Constants.START_OTP_SMS_REQUEST,
      name: formName,
      phoneNumber,
    });
    return postJSON(`${window.config.v4_api_endpoint}/customer/${customerID}/sms_verification`, {
      action: 'phone-verification-call',
      phone: phoneNumber,
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((body) => {
            AppDispatcher.handleViewAction({
              actionType: Constants.OTP_SMS_REQUEST_SUCCESS,
              name: formName,
              phoneNumber,
              expires: body.expires,
            });
          });
        }
        return window.Promise.reject();
      })
      .catch((error) => {
        AppDispatcher.handleViewAction({
          actionType: Constants.OTP_SMS_REQUEST_FAILURE,
          phoneNumber,
          name: formName,
          error,
        });
      });
  }

  _verifyOTPCode(customerID, smsCode, phoneNumber) {
    return patchJSON(`${window.config.v4_api_endpoint}/customer/${customerID}/sms_verification`, {
      action: 'phone-verification-validate',
      sms_code: smsCode,
      phone: phoneNumber,
    })
      .then((response) => {
        if (response.ok) {
          return window.Promise.resolve();
        }
        return window.Promise.reject(response.status);
      })
      .catch((status) => {
        if (status === 403) {
          return window.Promise.reject(Constants.OTP_SMS_VERIFY_FAILURE);
        } else if (status === 422) {
          return window.Promise.reject(Constants.OTP_CODE_MALFORMED);
        }
        return window.Promise.reject({
          error: Constants.PHONE_VERIFY_UNKNOWN_ERROR,
          status,
        });
      });
  }

  verifySMSCode(formName, customerID, smsCode, phoneNumber) {
    AppDispatcher.handleViewAction({
      actionType: Constants.OTP_SMS_VERIFY_START,
      name: formName,
      phoneNumber,
    });

    return this._verifyOTPCode(customerID, smsCode, phoneNumber)
      .then(() =>
        this._submitCustomerDetails(customerID, { Phone: phoneNumber }).then(() => {
          AppDispatcher.handleViewAction({
            actionType: Constants.OTP_SMS_VERIFY_SUCCESS,
            name: formName,
            phoneNumber,
          });
        })
      )
      .catch((error) => {
        this._handleVerifyOTPCodeError(formName, phoneNumber, smsCode, error);
      });
  }

  _handleVerifyOTPCodeError(formName, phoneNumber, smsCode, error) {
    let errorType;
    let errorCode;
    if (typeof error === 'object') {
      errorType = error.error;
      errorCode = error.status;
    } else {
      errorType = error;
    }
    AppDispatcher.handleViewAction({
      actionType: errorType,
      name: formName,
      phoneNumber,
      errorCode,
    });
    if (error === Constants.OTP_SMS_VERIFY_FAILURE) {
      FormActions.invalidateField(formName, 'SMSCode', smsCode);
    } else if (error === Constants.OTP_CODE_MALFORMED) {
      FormActions.validate(formName, 'SMSCode', 'tel', smsCode);
    }
    FormActions.showValidationStatus(formName, 'SMSCode', 'tel', smsCode);
  }

  togglePhoneVerificationStrategy(formName, phoneNumber) {
    AppDispatcher.handleViewAction({
      actionType: Constants.TOGGLE_PHONE_VERIFICATION_STRATEGY,
      name: formName,
      phoneNumber,
    });
  }
}

export default new VerifyUserActions();
