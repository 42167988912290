import { ChangeEmitter } from '../utils/ChangeEmitter';

export class CounterStore extends ChangeEmitter {
  constructor() {
    super();

    this.startingValue = 2470212980;
    this.startingTimestamp = 1437696000;
    this.dollarsPerSecond = 7.44;

    const currentTimestamp = Math.floor(Date.now() / 1000);
    this.value =
      this.startingValue +
      Math.floor(this.dollarsPerSecond * (currentTimestamp - this.startingTimestamp));
    this.max = this.value;
  }

  getValue() {
    return this.value;
  }

  incrementMaximumValue() {
    this.max += Math.floor(Math.random() * 100 + 75);
    this.emitChange();
  }

  incrementCurrentValue() {
    this.value += 1;
    this.emitChange();
  }

  start() {
    setInterval(() => {
      this.incrementMaximumValue();
      const interval = setInterval(() => {
        this.incrementCurrentValue();
        if (this.value >= this.max) {
          clearInterval(interval);
        }
      }, 30);
    }, 15000);
  }
}

const counterStore = new CounterStore();
counterStore.start();
export default counterStore;
