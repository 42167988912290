import deepcopy from 'clone';
import select from 'vtree-select';
import virtualize from '../utils/virtualize';
import { setVnodeClasses } from '../utils/set-vnode-classes';

import Component from '../utils/Component';

export class Carousel extends Component {
  constructor(element) {
    super();

    this.template = virtualize(element);

    // The value of each array item is the number of steps for the carousel
    // item at that key
    this.items = [];
    for (const item of element.querySelectorAll('[data-target="carousel-item"]')) {
      this.items.push(item.querySelectorAll('[data-target="step-item"]').length);
    }

    this.setState({
      currentCarousel: 0,
      currentStep: 0,
    });

    this.loop = setInterval(this._increment.bind(this), 2000);
  }

  _increment() {
    let step = this.state.currentStep + 1;
    let carousel = this.state.currentCarousel;

    if (this.state.currentStep === this.items[this.state.currentCarousel]) {
      step = 0;
      if (this.state.currentCarousel + 1 === this.items.length) {
        carousel = 0;
      } else {
        carousel = this.state.currentCarousel + 1;
      }
    }

    this.setState(
      {
        currentCarousel: carousel,
        currentStep: step,
      },
      false,
      true
    );
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    const carouselItems = select('[data-target="carousel-item"]')(vhtml);
    for (const [carouselIndex, carouselItem] of carouselItems.entries()) {
      setVnodeClasses(carouselItem, {
        'is-active': carouselIndex === this.state.currentCarousel,
      });

      if (carouselIndex === this.state.currentCarousel) {
        const stepItems = select('[data-target="step-item"]')(carouselItem);
        for (const [stepIndex, stepItem] of stepItems.entries()) {
          // Hold the last step for 2 intervals - set is-current when
          // currentStep is greater than then number of stepItems
          setVnodeClasses(stepItem, {
            'is-current':
              stepIndex === this.state.currentStep ||
              (this.state.currentStep === stepItems.length &&
                this.state.currentStep === stepIndex + 1),
            'is-disabled': stepIndex > this.state.currentStep,
          });
        }
      }
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="carousel"]')) {
    const component = new Carousel(elem);
    component.replace(elem);
  }
});
