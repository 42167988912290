import serialize from 'form-serialize';
import deepcopy from 'clone';
import APIKeyInputTemplate from 'templates/forms/apiKeyInput.html';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import APIKeyActions from '../actions/APIKeyActions';
import APIKeyStore from '../stores/APIKeyStore';

function getState() {
  return {
    submissionError: APIKeyStore.submissionError,
  };
}

export default class APIKeyInput extends Component {
  constructor() {
    super();
    this.addEventListener('submit', 'form', this.onSubmission.bind(this));
  }

  onSubmission(event) {
    event.preventDefault();
    const requestData = serialize(event.target, { hash: true });
    if (requestData.api_key_name) {
      APIKeyActions.submitAPIKey(requestData.api_key_name);
    }
  }

  componentHasMounted() {
    this._onChange = this._onChange.bind(this);
    APIKeyStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    APIKeyStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this._tree);
    vhtml.children = virtualize.fromHTML(
      APIKeyInputTemplate.render({
        error: this.state.submissionError,
      })
    ).children;
    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="api-key-input"]')) {
    new APIKeyInput().replace(e);
  }
});
