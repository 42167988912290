import deepcopy from 'clone';
import localStorage from 'local-storage';
import { includes, keys } from 'lodash';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import Constants from '../constants/FileUploadConstants';
import AuthenticationStore from './AuthenticationStore';
import TrackingActions from '../actions/TrackingActions';
import FormConstants from '../constants/FormConstants';

export class FileUploadStore extends ChangeEmitter {
  constructor() {
    super();
    this.files = {};
    this.fileDragged = {};
    this.persistFiles = {};
    this.showErrors = {};
  }

  initialiseUploadBox(boxName) {
    const customerID = AuthenticationStore.getCustomerID();
    this.initialiseLocalStorage(boxName);
    const persistentFiles = localStorage.get(Constants.LOCALSTORAGE_FILE_KEY);
    if (persistentFiles) {
      this.files[boxName] = persistentFiles[customerID][boxName];
    } else {
      this.files[boxName] = [];
    }
    this.showErrors[boxName] = false;
    this.fileDragged[boxName] = false;
    this.persistFiles[boxName] = false;
  }

  initialiseLocalStorage(boxName) {
    const customerID = AuthenticationStore.getCustomerID();
    const persistentFiles = localStorage.get(Constants.LOCALSTORAGE_FILE_KEY) || {};
    if (!persistentFiles[customerID]) {
      persistentFiles[customerID] = {};
    }
    if (!persistentFiles[customerID][boxName]) {
      persistentFiles[customerID][boxName] = [];
    }
    localStorage.set(Constants.LOCALSTORAGE_FILE_KEY, persistentFiles);
  }

  setFilePersistence(boxName, shouldPersist) {
    this.persistFiles[boxName] = shouldPersist;
  }

  willFilesPersist(boxName) {
    return this.persistFiles[boxName];
  }

  trackFile(boxName, fileStatus) {
    if (fileStatus !== Constants.UPLOAD_IN_PROGRESS && includes(boxName, 'verify')) {
      setTimeout(() => {
        TrackingActions.track({
          event: 'escrow_user_action',
          section: 'verify-form',
          action: 'file-upload',
          value: fileStatus,
        });
      });
    }
  }

  // only shows box errors for boxNames which inclued the formName
  showBoxError(formName) {
    for (const boxName of keys(this.files).filter((v) => includes(v, formName))) {
      this.showErrors[boxName] = true;
    }
  }

  // returns true if the box is allowd to display errors
  canShowErrors(boxName) {
    return this.showErrors[boxName];
  }

  boxValid(boxName) {
    return this.validFileCount(boxName) >= 1;
  }

  getFiles(boxName) {
    return deepcopy(this.files[boxName] || []);
  }

  getValidFiles(boxName) {
    return this.getFiles(boxName).filter((doc) => doc.uploadStatus === Constants.UPLOAD_OK);
  }

  getInvalidFiles(boxName) {
    return this.getFiles(boxName).filter((doc) => doc.uploadStatus !== Constants.UPLOAD_OK);
  }

  hasFile(boxName, file) {
    return this.getFiles(boxName).some(
      (formFile) =>
        formFile.fileName === file.name &&
        formFile.size === file.size &&
        formFile.uploadStatus === Constants.UPLOAD_OK
    );
  }

  validFileCount(boxName) {
    let count = 0;
    this.getFiles(boxName).map((file) => {
      count += file.uploadStatus === Constants.UPLOAD_OK;
    });
    return count;
  }

  fileDraggedStatus(boxName) {
    return this.fileDragged[boxName];
  }

  addFile(boxName, newFile) {
    if (
      newFile.uploadStatus === Constants.UPLOAD_OK &&
      newFile.progress === 100 &&
      this.willFilesPersist(boxName)
    ) {
      this.addFileToLocalStorage(boxName, newFile);
    }
    for (const file of this.files[boxName]) {
      // Assume file can be uniquely identified by file name and size
      if (file.fileName === newFile.fileName && file.size === newFile.size) {
        // Only allow increase in progress & ensure that an UPLOAD_OK action
        // always takes precedence over a progress action
        if (
          newFile.progress > file.progress ||
          newFile.uploadStatus !== Constants.UPLOAD_IN_PROGRESS
        ) {
          Object.assign(file, newFile);
          return;
        }
        return;
      }
    }
    this.files[boxName].push(newFile);
  }

  addFileToLocalStorage(boxName, newFile) {
    const customerID = AuthenticationStore.getCustomerID();
    const persistentFiles = localStorage.get(Constants.LOCALSTORAGE_FILE_KEY);
    if (persistentFiles) {
      const files = persistentFiles[customerID][boxName];
      files.push(deepcopy(newFile));
      localStorage.set(Constants.LOCALSTORAGE_FILE_KEY, persistentFiles);
    }
  }

  removeFile(boxName, fileNumber) {
    this.files[boxName].splice(fileNumber, 1);
    this.trackFile(boxName, Constants.REMOVE_FILE);
  }

  removeFileFromLocalStorage(boxName, fileNumber) {
    const customerID = AuthenticationStore.getCustomerID();
    const persistentFiles = localStorage.get(Constants.LOCALSTORAGE_FILE_KEY);
    if (persistentFiles) {
      persistentFiles[customerID][boxName].splice(fileNumber, 1);
      localStorage.set(Constants.LOCALSTORAGE_FILE_KEY, persistentFiles);
    }
  }

  // search through boxNames and delete only those which include the formName
  clearFilesFromLocalStorage(formName) {
    const customerID = AuthenticationStore.getCustomerID();
    const persistentFiles = localStorage.get(Constants.LOCALSTORAGE_FILE_KEY);
    if (persistentFiles) {
      const formSpecificBoxes = keys(persistentFiles[customerID]).filter((boxName) =>
        includes(boxName, formName)
      );
      for (const boxName of formSpecificBoxes) {
        persistentFiles[customerID][boxName] = [];
      }
      localStorage.set(Constants.LOCALSTORAGE_FILE_KEY, persistentFiles);
    }
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === Constants.INITIALISE_BOX) {
      this.initialiseUploadBox(action.boxName);
      this.emitChange();
    } else if (actionType === Constants.REMOVE_FILE) {
      this.removeFile(action.boxName, action.fileNumber);
      if (this.willFilesPersist(action.boxName)) {
        this.removeFileFromLocalStorage(action.boxName, action.fileNumber);
      }
      this.emitChange();
    } else if (actionType === Constants.DRAG_ENTER) {
      this.fileDragged[action.boxName] = true;
      this.emitChange();
    } else if (actionType === Constants.DRAG_LEAVE) {
      this.fileDragged[action.boxName] = false;
      this.emitChange();
    } else if (actionType === Constants.ADD_FILE) {
      this.emitChange();
    } else if (actionType === Constants.SET_FILE_PERSISTENCE) {
      this.setFilePersistence(action.boxName, action.shouldPersist);
      this.emitChange();
    } else if (actionType === FormConstants.SHOW_FORM_ERROR) {
      // only show box errors for boxes that contain the form name
      this.showBoxError(action.form);
      this.emitChange();
    }
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === Constants.ADD_FILE) {
      this.addFile(action.boxName, action.attributes);
      this.trackFile(action.boxName, action.attributes.uploadStatus);
      this.emitChange();
    } else if (actionType === FormConstants.SUBMISSION_SUCCESS) {
      this.clearFilesFromLocalStorage(action.name);
      this.emitChange();
    }
  }
}

const fileUploadStore = new FileUploadStore();
fileUploadStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    fileUploadStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    fileUploadStore.handleServerAction(action);
  }
});

export default fileUploadStore;
