import deepcopy from 'clone';
import select from 'vtree-select';
import VText from 'virtual-dom/vnode/vtext';
import formatNumber from 'format-number';
import classNames from 'classnames/dedupe';

import UploadList from 'templates/js/verifyUploadListItem.html';

import Component from '../utils/Component';
import virtualize from '../utils/virtualize';
import FileUploadActions from '../actions/FileUploadActions';
import FileUploadStore from '../stores/FileUploadStore';
import Constants from '../constants/FileUploadConstants';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import { gettext } from '../utils/filters';
import PhotoCapture from '../utils/PhotoCapture';

function getState(boxName) {
  return {
    files: FileUploadStore.getFiles(boxName),
    validFileCount: FileUploadStore.validFileCount(boxName),
    fileDragged: FileUploadStore.fileDraggedStatus(boxName),
    boxValid: FileUploadStore.boxValid(boxName),
    showErrors: FileUploadStore.canShowErrors(boxName),
  };
}

export class FileUploadBox extends Component {
  constructor(element, props = {}) {
    super();
    this.template = virtualize(element);
    this.props = props;

    this.addEventListener('change', 'input[data-file-upload]', this._uploadFiles.bind(this));
    this.addEventListener('click', 'button[data-remove-file]', this._removeFile.bind(this));
    this.addEventListener('click', 'span[data-take-photo]', this._takePhoto.bind(this));
    this.addEventListener('dragenter', 'input[data-file-upload]', this._fileDragEnter.bind(this));
    this.addEventListener('dragleave', 'input[data-file-upload]', this._fileDragLeave.bind(this));
    this.addEventListener('drop', 'input[data-file-upload]', this._fileDragLeave.bind(this));

    this._onChange = this._onChange.bind(this);
    this.boxName = element.getAttribute('data-box-name');
    FileUploadActions.initialiseUploadBox(this.boxName);
    FileUploadActions.setFilePersistence(this.boxName, this.props.shouldPersist);
    this.setState(getState(this.boxName));
    this._uploadPhoto = this._uploadPhoto.bind(this);
  }

  componentHasMounted() {
    FileUploadStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    FileUploadStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState(this.boxName));
  }

  async _takePhoto() {
    const handler = new PhotoCapture(document.getElementsByClassName('embeddedCameraModal')[0]);
    await handler.init(this._uploadPhoto);
  }

  _uploadPhoto(file) {
    FileUploadActions.postFile(this.boxName, file);
    const modal = document.getElementsByClassName('embeddedCameraModal')[0];
    modal.querySelector('button[data-target=modal-close]').click();
  }

  _uploadFiles(event) {
    const files = event.target.files;
    for (const file of Object.keys(files)) {
      FileUploadActions.postFile(this.boxName, files[file]);
    }
  }

  _fileDragEnter() {
    FileUploadActions.dragEnter(this.boxName);
  }

  _fileDragLeave() {
    FileUploadActions.dragLeave(this.boxName);
  }

  _removeFile(event, element) {
    event.preventDefault();
    const fileNumber = Number(element.getAttribute('data-remove-file'));
    FileUploadActions.removeFile(this.boxName, fileNumber);
  }

  _formatFileSize(size) {
    let formattedSize;
    if (size < Constants.MEGABYTE) {
      const formatter = formatNumber({
        round: 2,
        padRight: 2,
        suffix: ' KB',
      });
      formattedSize = formatter(size / Constants.KILOBYTE);
    } else {
      const formatter = formatNumber({
        round: 2,
        padRight: 2,
        suffix: ' MB',
      });
      formattedSize = formatter(size / Constants.MEGABYTE);
    }
    return formattedSize;
  }

  render() {
    const vhtml = deepcopy(this.template);
    if (this.state.files) {
      const listFiles = (files, target) => {
        const elements = select(`[data-target="${target}"]`)(vhtml);
        if (elements) {
          for (const file of files) {
            const listItem = UploadList.render({
              name: file.fileName,
              status: file.uploadStatus,
              number: this.state.files.indexOf(file),
              size: this._formatFileSize(file.size),
              progress: file.progress,
            });
            elements[0].children.push(virtualize.fromHTML(listItem));
          }
        }
      };

      listFiles(
        this.state.files.filter(
          (doc) =>
            doc.uploadStatus === Constants.UPLOAD_OK ||
            doc.uploadStatus === Constants.UPLOAD_IN_PROGRESS
        ),
        'file-list'
      );

      listFiles(
        this.state.files.filter(
          (doc) =>
            doc.uploadStatus === Constants.UPLOAD_FAIL ||
            doc.uploadStatus === Constants.UPLOAD_INVALID
        ),
        'file-list'
      );

      const fileCount = select('[data-target="file-count"]')(vhtml);
      if (fileCount) {
        for (const text of fileCount) {
          text.children = [new VText(this.state.validFileCount + gettext(' Uploaded Files'))];
        }
      }

      const fileFields = select('[data-target="file-uploader"]')(vhtml);
      if (fileFields) {
        for (const field of fileFields) {
          setVnodeClasses(field, { 'is-hovered': this.state.fileDragged });
        }
      }
    }

    if (!this.state.boxValid && this.state.showErrors) {
      const fields = select('[data-target="field"]')(vhtml);
      for (const field of fields || []) {
        field.properties.className = classNames(
          field.properties.className.split(' '),
          'is-invalid'
        );
      }
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="upload-documents-fieldset"]')) {
    const shouldPersist = elem.hasAttribute('data-file-persist');
    const component = new FileUploadBox(elem, {
      shouldPersist: shouldPersist,
    });
    component.replace(elem);
  }
});
