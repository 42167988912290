import select from 'vtree-select';
import h from 'virtual-dom/h';
// VNode is more appropriate for creating virtual nodes with no content
import VNode from 'virtual-dom/vnode/vnode';
import VText from 'virtual-dom/vnode/vtext';
import QueryString from 'query-string';
import window from 'window-shim';
import classNames from 'classnames/dedupe';

import tick from 'templates/js/tick.html';
import cross from 'templates/js/cross.html';

import virtualize from '../utils/virtualize';
import { gettext } from '../utils/filters';

import SignupFormConstants from '../constants/SignupFormConstants';
import FormActions from '../actions/FormActions';
import FormStore from '../stores/FormStore';
import FormConstants from '../constants/FormConstants';
import { Form } from './Form';

export class SignupForm extends Form {
  constructor(element, options = {}) {
    super(element, options);

    this.onChange = this.onChange.bind(this);

    if (this.formName === 'signup-partner') {
      FormActions.initialiseFields(
        this.formName,
        [
          'security-question-one-question',
          'security-question-two-question',
          'security-question-three-question',
        ],
        true
      );
      FormActions.formStatesChanged(this.formName, {
        'security-question-one-question': '1',
        'security-question-two-question': '2',
        'security-question-three-question': '3',
      });
    }
  }

  render() {
    const vhtml = super.render();

    // Security question exclusions
    const questionForms = select('[data-component="security-question"]')(vhtml);
    if (questionForms) {
      const state = this.state.formState;
      for (const form in questionForms) {
        if (questionForms.hasOwnProperty(form)) {
          const elem = questionForms[form];
          elem.children = [];
          for (const val in FormConstants.SECURITY_QUESTIONS) {
            if (
              !(
                (state['security-question-one-question'] === val && form !== '0') ||
                (state['security-question-two-question'] === val && form !== '1') ||
                (state['security-question-three-question'] === val && form !== '2')
              ) ||
              val === '0'
            ) {
              if (val === '0') {
                elem.children.push(
                  h(
                    'option',
                    { value: '---', disabled: true },
                    FormConstants.SECURITY_QUESTIONS[val]
                  )
                );
              } else if (
                (state['security-question-one-question'] === val && form === '0') ||
                (state['security-question-two-question'] === val && form === '1') ||
                (state['security-question-three-question'] === val && form === '2')
              ) {
                elem.children.push(
                  h(
                    'option',
                    { value: val, selected: 'selected' },
                    FormConstants.SECURITY_QUESTIONS[val]
                  )
                );
              } else {
                elem.children.push(
                  h('option', { value: val }, FormConstants.SECURITY_QUESTIONS[val])
                );
              }
            }
          }
        }
      }
    }

    const virtualTick = virtualize.fromHTML(
      tick.render({ specifier: 'signupForm-suggestion-icon' })
    );
    const virtualCross = virtualize.fromHTML(
      cross.render({ specifier: 'signupForm-suggestion-icon' })
    );

    // password helper
    const passwordSuggestions = select('[data-component="signupForm-suggestion-list"]')(vhtml);
    if (passwordSuggestions) {
      const passwordSuggestionTitle = h(
        'h3',
        {
          className: classNames(['signupForm-suggestion-title'], {
            'is-success': this.state.validFields.password,
            'is-invalid': !this.state.validFields.password && this.state.showFormError,
          }),
        },
        gettext('Strong passwords have: ')
      );
      const passwordSuggestionList = new VNode('ul', {
        className: 'signupForm-suggestion-list',
      });
      const suggestions = SignupFormConstants.PASSWORD_SUGGESTIONS;
      passwordSuggestionList.children = suggestions.map((suggestion) => {
        const test = suggestion.test(FormStore.formState[this.formName].password);
        const li = new VNode('li', {
          className: classNames(['signupForm-suggestion-item'], {
            'is-success': test,
            'is-invalid': !test && this.state.showFormError,
          }),
        });
        li.children = [
          !test && this.state.showFormError ? virtualCross : virtualTick,
          new VText(suggestion.label),
        ];
        return li;
      });
      passwordSuggestions[0].children = [passwordSuggestionTitle, passwordSuggestionList];
    }

    return vhtml;
  }

  onChange() {
    super._onChange();
  }

  componentHasMounted() {
    // Check for an email query param, and if present prefill the email field,
    // validate it and set the validation status to show
    const query = QueryString.parse(window.location.search);
    if (query.signup) {
      // query.signup is a base64 encoded email address.
      // We need to decode it so that we can use it
      const email = atob(query.signup);
      if (email) {
        const emailElem = this.rootNode.querySelector(
          '[data-target="field-focusable"][name="email-address"]'
        );
        emailElem.value = email;
        FormActions.setImmutableField(this.formName, emailElem.name, emailElem.value);
        FormActions.validate(this.formName, emailElem.name, emailElem.type, emailElem.value);
        FormActions.showValidationStatus(
          this.formName,
          emailElem.name,
          emailElem.type,
          emailElem.value
        );
      }
    }
    FormStore.addChangeListener(this.onChange);
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this.onChange);
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="signupForm"]')) {
    const component = new SignupForm(elem);
    component.replace(elem, component.initialTemplate);
  }
});
