import deepcopy from 'clone';
import select from 'vtree-select';
import window from 'window-shim';
import RemoveCredtCardTemplate from 'templates/forms/account-info/removeCreditCard.html';
import PaymentConstants from 'spa/constants/PaymentConstants';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import { gettext } from '../utils/filters';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import CreditCardStore from '../stores/CreditCardStore';
import AuthenticationStore from '../stores/AuthenticationStore';
import ModalActions from '../actions/ModalActions';
import API from '../api/API';

const getState = () => ({
  creditCard: CreditCardStore.getSelected(),
  error: null,
});

const getLogoType = (creditCard) => {
  if (creditCard.brand === 'American Express') return 'amex';
  return creditCard.brand.toLowerCase();
};

export default class RemoveCreditCardConfirmation extends Component {
  constructor(element, props = {}) {
    super();
    this.props = props;
    this.setState(getState());
    this.template = virtualize(element);

    this._onChange = this._onChange.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this.addEventListener('submit', 'form', this._onSubmission.bind(this));
  }

  componentWillMount() {
    CreditCardStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    CreditCardStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState());
  }

  _onSuccess() {
    ModalActions.close(this.props.name);
    window.location.reload();
  }

  _onSubmission(event) {
    event.preventDefault();
    const customerID = AuthenticationStore.customerID;
    this.setState({ processing: true });
    (window.js_context.credit_card_gateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN
      ? API.removeAdyenCreditCard(this.state.creditCard.id)
      : API.removeCreditCard(customerID, this.state.creditCard.id)
    )
      .then(() => this._onSuccess())
      .catch(() => {
        this.setState({
          error: gettext('Failed to remove credit card.'),
          processing: false,
        });
      });
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    if (this.state.creditCard) {
      vhtml.children = virtualize.fromHTML(
        RemoveCredtCardTemplate.render({
          credit_card: {
            ...this.state.creditCard,
            logoType: getLogoType(this.state.creditCard),
          },
          error: this.state.error,
        })
      ).children;
    }
    const submitButton = (select('[data-target="form-submit"]')(vhtml) || [])[0];
    setVnodeClasses(submitButton, { 'is-loading': this.state.processing });
    submitButton.properties.disabled = this.state.processing;
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="remove-card-confirmation"]')) {
    const component = new RemoveCreditCardConfirmation(elem, {
      name: elem.attributes.getNamedItem('data-target').value,
    });
    component.replace(elem);
  }
});
