import h from 'virtual-dom/h';
import select from 'vtree-select';
import classNames from 'classnames/dedupe';
import VText from 'virtual-dom/vnode/vtext';
import QueryString from 'query-string';
import window from 'window-shim';
import serialize from 'form-serialize';

import { gettext } from '../utils/filters';
import { Form } from './Form';
import CategorySearchActions from '../actions/CategorySearchActions';
import CategorySearchStore from '../stores/CategorySearchStore';
import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';
import StartTransactionStore from '../stores/StartTransactionStore';
import StartTransactionActions from '../actions/StartTransactionActions';
import UserActions from '../actions/UserActions';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import DomainInputStore from '../stores/DomainInputStore';
import AuthenticationStore from '../stores/AuthenticationStore';
import CalculatorStore from '../stores/Calculator';
import virtualize from '../utils/virtualize';
import { setVnodeClasses } from '../utils/set-vnode-classes';

function getState(formName) {
  return {
    role: StartTransactionStore.role,
    returning: StartTransactionStore.returning,
    shipmentMethod: StartTransactionStore.shipmentMethod,
    shippingPayer: StartTransactionStore.shippingPayer,
    advanced: StartTransactionStore.advanced,
    emailExists: StartTransactionStore.emailExists,
    loggedIn: AuthenticationStore.isAuthenticated(),
    validFields: StartTransactionStore.validFields(formName),
    invalidFieldCount: StartTransactionStore.invalidFieldCount(formName),
    allDomainsValid: DomainInputStore.allDomainsValid(),
    showingFormError: !!FormStore.formErrorTitle[formName],
    categorySearchQuery: CategorySearchStore.searchQuery,
    isCategoryProhibited: CategorySearchStore.isProhibited,
    selectedCategory: CategorySearchStore.selectedCategory,
  };
}

export class StartTransaction extends Form {
  constructor(element, options = {}) {
    super(element, options);
    this._onChange = this._onChange.bind(this);
    this.addEventListener('click', 'input[name="transaction-role"]', this._selectRole.bind(this));
    this.addEventListener('click', 'input[name="returning"]', this._selectReturning.bind(this));
    this.addEventListener(
      'click',
      'input[name="shipment-method"]',
      this._selectShipping.bind(this)
    );
    this.addEventListener(
      'click',
      'input[name="shipping-payer"]',
      this._selectShippingPayer.bind(this)
    );
    this.addEventListener(
      'click',
      '[data-component="show-advanced"]',
      this._toggleAdvanced.bind(this)
    );
    this.addEventListener(
      'mousedown',
      '[data-component="category-dropdown-item"]',
      this._handleCategoryItemClick.bind(this)
    );
    this.addEventListener(
      'mousedown',
      '[data-component="transaction-category-searchResults"]',
      this._handleCategoryScrollbarClick.bind(this)
    );
    this.addEventListener(
      'focus',
      '[data-component="transaction-category-search"]',
      this._handleSearchInputFocus.bind(this)
    );
    this._onChange();
  }

  componentHasMounted() {
    StartTransactionStore.addChangeListener(this._onChange);
    FormStore.addChangeListener(this._onChange);
    DomainInputStore.addChangeListener(this._onChange);
    AuthenticationStore.addChangeListener(this._onChange);
    CategorySearchStore.addChangeListener(this._onChange);
    this.prefillValues();
    this.validateRequiredFields();
    if (this.state.loggedIn) {
      const customerID = AuthenticationStore.getCustomerID();
      UserActions.getKycStatus(customerID);
      UserActions.checkIfCustDetailsUpdateRequired(customerID);
    }
    this.focusOnFirstEmptyField();
  }

  componentWillDismount() {
    StartTransactionStore.removeChangeListener(this._onChange);
    FormStore.removeChangeListener(this._onChange);
    AuthenticationStore.removeChangeListener(this._onChange);
    DomainInputStore.removeChangeListener(this._onChange);
    CategorySearchStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    super._onChange(getState(this.formName));
  }

  _selectRole(event) {
    StartTransactionActions.selectRole(event.target.value);
  }

  _selectReturning(event) {
    StartTransactionActions.selectReturning(event.target.value);
  }

  _selectShipping(event) {
    StartTransactionActions.selectShipping(event.target.value);
  }

  _selectShippingPayer(event) {
    StartTransactionActions.selectShippingPayer(event.target.value);
    if (event.target.value === StartTransactionConstants.SHIPPING_PAYER_SELLER) {
      this.disableShippingPrice();
    } else if (event.target.value !== StartTransactionConstants.SHIPPING_PAYER_SELLER) {
      this.enableShippingPrice();
    }
  }

  _toggleAdvanced(event) {
    event.preventDefault();
    StartTransactionActions.toggleAdvanced();
  }

  disableShippingPrice() {
    // Mark the shipping fields as immutable & clear their values
    const shippingPriceElems = this.rootNode.querySelectorAll(
      '[data-milestone-field="shippingPrice"]'
    );
    const fields = {};
    for (const shippingPriceElem of shippingPriceElems) {
      shippingPriceElem.value = '';
      fields[shippingPriceElem.name] = shippingPriceElem.value;
    }
    FormActions.clearValidations(this.formName, Object.keys(fields));
    FormActions.setImmutableFields(this.formName, fields);
  }

  enableShippingPrice() {
    // Clear the shipping price fields from being immutable & revalidate
    const shippingPriceElems = this.rootNode.querySelectorAll(
      '[data-milestone-field="shippingPrice"]'
    );
    const fields = [];
    const fieldsToValidate = [];
    const fieldContents = [];
    const fieldTypes = [];
    const fieldRequired = [];
    for (const shippingPriceElem of shippingPriceElems) {
      fields.push(shippingPriceElem.name);
      fieldRequired.push(shippingPriceElem.required);

      if (shippingPriceElem.required) {
        fieldsToValidate.push(shippingPriceElem.name);
        fieldContents.push(shippingPriceElem.value);
        fieldTypes.push(shippingPriceElem.type);
      } else {
        const validationGroup = shippingPriceElem.dataset.validationRequireGroup;
        const groupElements = this.rootNode.querySelectorAll(
          `[data-validation-require-group="${validationGroup}"]`
        );
        // Determine whether any element in the group has a value & if so mark the
        // current shippingPriceElem for validation
        for (const element of groupElements) {
          if (element.value) {
            fieldsToValidate.push(shippingPriceElem.name);
            fieldContents.push(shippingPriceElem.value);
            fieldTypes.push(shippingPriceElem.type);
            break;
          }
        }
      }
    }
    FormActions.clearImmutableFields(this.formName, fields);
    FormActions.bulkValidate(
      this.formName,
      fieldsToValidate,
      fieldTypes,
      fieldContents,
      fieldRequired
    );
  }

  prefillValues() {
    // Add data from calculator or query string into form.
    // Preference role and currency from the query string in the case that user
    // comes from the legacy site.
    const query = QueryString.parse(window.location.search);
    const calcData = CalculatorStore.getData() || {};

    if (calcData.amount) {
      const priceElem = this.rootNode.querySelector('[name="price"]');
      if (priceElem) {
        priceElem.value = calcData.amount;
      }
    }

    const currency = query.currency || calcData.currency;
    if (currency) {
      const currencyElem = this.rootNode.querySelector('[name="currency"]');
      const flag = this.rootNode.querySelector('[data-select-icon="currency"]');
      flag.setAttribute('data-select-value', currency);
      currencyElem.value = currency;
      // Validate so that prefix is set in next render
      FormActions.validate(this.formName, currencyElem.name, currencyElem.type, currency, true);
    }

    const role = query.role || calcData.role;
    if (role) {
      StartTransactionActions.selectRole(role);
      const buyer = this.rootNode.querySelector('input[name="transaction-role"][value="buyer"]');
      const seller = this.rootNode.querySelector('input[name="transaction-role"][value="seller"]');
      buyer.checked = role === 'buyer';
      seller.checked = role === 'seller';
    }

    // if this is a vehicle transaction, set the default shipping method to none
    if (this.rootNode.querySelector('[data-component="vehicle-input"]')) {
      StartTransactionActions.selectShipping(StartTransactionConstants.SHIPPING_METHOD_CARGO);
    }

    const email = query.email;
    if (email) {
      const emailElement = this.rootNode.querySelector('input[name="email-address"]');
      emailElement.value = decodeURIComponent(email);
    }

    if (!this.state.loggedIn) {
      const newUser = this.rootNode.querySelector('input[name="returning"][value="register"]');
      const returningUser = this.rootNode.querySelector('input[name="returning"][value="login"]');
      newUser.checked = !this.state.returning;
      returningUser.checked = this.state.returning;
    }
  }

  _validateField(event) {
    const fieldName = event.target.name;
    const contents = event.target.value;
    const type = event.target.type;
    const userExists = this.state.emailExists[this.state.formState['email-address']];
    if (userExists && fieldName === 'password') {
      // Force valid validation for password when user already exists (login)
      FormActions.validate(this.formName, fieldName, type, contents, true);
    } else if (fieldName === 'email-address' || fieldName === 'email-address-other') {
      super._validateField(event);
      this.validateEmailPair();
    } else if (event.target.dataset.component === 'phone-prefix-field') {
      // Add the numeric prefix value to the Form Store (needed for libphonenumber validation)
      this.storePhonePrefixValue(event.target);
      super._validateField(event);
    } else if (event.target.dataset.component === 'transaction-category-search') {
      this._changeSearchField(event);
      super._validateField(event);
      this.validateItemCategory(event);
    } else {
      super._validateField(event);
    }
  }

  _onBlur(event) {
    super._onBlur(event);
    const name = event.target.name;
    if (name === 'email-address' || name === 'email-address-other') {
      StartTransactionActions.signupShadow(event.target.value, name);
    }
  }

  getTransactionType() {
    let transactionType = StartTransactionConstants.GENERAL_TRANSACTION_TYPE;
    if (this.rootNode.querySelector('[data-component="domain-input"]')) {
      transactionType = StartTransactionConstants.DOMAIN_TRANSACTION_TYPE;
    } else if (this.rootNode.querySelector('[data-component="vehicle-input"]')) {
      transactionType = StartTransactionConstants.VEHICLE_TRANSACTION_TYPE;
    }
    return transactionType;
  }

  _onSubmission(event) {
    event.preventDefault();
    this.validateRequiredFields();
    this.validateEmailPair();
    this.validateItemCategory();
    try {
      this.validateMinimumPrice();
    } catch (e) {
      return;
    }
    let domainsValid = true;
    const transactionType = this.getTransactionType();
    if (transactionType === StartTransactionConstants.DOMAIN_TRANSACTION_TYPE) {
      domainsValid = DomainInputStore.allDomainsValid();
    }
    if (!this.state.invalidFieldCount && this.options.handleSubmission && domainsValid) {
      const data = serialize(event.target, { hash: true });
      StartTransactionActions.submit(data, this.formName, transactionType);
    } else {
      this.showFormErrors();
    }

    const selectedElement = document.querySelector('input[name=additional-services]:checked');
    let selectedService = 'none';
    if (selectedElement) {
      selectedService = selectedElement.value;
    }

    const vinField = document.querySelector('[data-component="vin-field"]');
    if (vinField) {
      if (selectedService) {
        FormActions.validate(
          this.formName,
          vinField.name,
          vinField.type,
          vinField.value,
          false,
          selectedService !== 'none'
        );
      }
    }
  }

  validateMinimumPrice() {
    const price = this.state.formState.price;
    const feePayer = this.state.formState['fee-payer'];

    if ((price < 10 && feePayer === '1') || (price < 5 && feePayer === '2')) {
      FormActions.showError(this.formName, 'Item price needs to be above minimum fee.', true);
      throw new Error('price too low');
    }
  }

  validateRequiredFields() {
    super.validateRequiredFields();

    // Clear unused fields
    if (this.state.loggedIn) {
      FormActions.clearValidation(this.formName, 'email-address');
      FormActions.clearValidation(this.formName, 'password');
    }

    if (this.state.emailExists[this.state.formState['email-address']]) {
      const password = this.rootNode.querySelector(
        '[data-target="field-focusable"][name="password"]'
      );
      FormActions.validate(this.formName, password.name, password.type, password.value, true);
    }
  }

  /**
   * Validate both email fields to check for distinct email addresses
   */
  validateEmailPair() {
    if (this.state.showingFormError) {
      FormActions.clearError(this.formName);
    }
    const email = AuthenticationStore.getEmail() || this.state.formState['email-address'];
    const emailOther = this.state.formState['email-address-other'];
    if (email && emailOther && email.toLowerCase() === emailOther.toLowerCase()) {
      // the emails are the same
      FormActions.invalidateField(this.formName, 'email-address', email);
      FormActions.invalidateField(this.formName, 'email-address-other', emailOther);
      FormActions.showError(
        this.formName,
        "The buyer and seller can't be the same person, please use different email addresses.",
        false
      );
    }
  }

  validateItemCategory() {
    if (this.state.isCategoryProhibited) {
      FormActions.invalidateField(this.formName, 'title', this.state.categorySearchQuery);
    }
  }

  generateCategoryItemHtml(categoryLabel, categoryValue) {
    return `
        <li class="dropdown-item" data-component="category-dropdown-item">
            <a class="dropdown-link" data-value="${categoryValue}" data-e2e-target="${categoryValue}">${categoryLabel}</a>
        </li>
    `;
  }

  _changeSearchField(event) {
    CategorySearchActions.updateSearchString(event.target.value);
    this._onChange();
  }

  _handleCategoryItemClick(event) {
    const dropdownOption = event.target.innerText;
    const searchResults = this.rootNode.querySelector(
      '[data-component="transaction-category-searchResults"]'
    );
    const categorySearchInput = this.rootNode.querySelector(
      '[data-component="transaction-category-search"]'
    );
    categorySearchInput.value = dropdownOption;
    setTimeout(() => {
      searchResults.classList.add('is-hidden');
      categorySearchInput.blur();
    });

    CategorySearchActions.selectCategory(dropdownOption);
    this.validateRequiredFields();
    this.validateItemCategory();
    this._onChange();
  }

  _handleSearchInputFocus() {
    const searchResults = this.rootNode.querySelector(
      '[data-component="transaction-category-searchResults"]'
    );

    searchResults.classList.remove('is-hidden');
  }

  _handleCategoryScrollbarClick(event) {
    event.preventDefault();
    const categorySearchInput = this.rootNode.querySelector(
      '[data-component="transaction-category-search"]'
    );
    setTimeout(() => {
      categorySearchInput.focus();
    });

    this._onChange();
  }

  render() {
    const vhtml = super.render();

    // Change email address label of other party to buyer or seller
    const otherEmail = select('[data-target="field-label-email-address-other"]')(vhtml);
    if (otherEmail) {
      if (this.state.role === StartTransactionConstants.ROLE_BUYER) {
        otherEmail[0].children[0].text = gettext("The seller's email address");
      } else {
        otherEmail[0].children[0].text = gettext("The buyer's email address");
      }
    }

    const title = select('[data-target="field-label-title"]')(vhtml);
    if (title) {
      if (this.state.role === StartTransactionConstants.ROLE_BUYER) {
        title[0].children[0].text = gettext('What are you buying?');
      } else {
        title[0].children[0].text = gettext('What are you selling?');
      }
    }

    const emailAddressHint = select('[id="hint-email-address-other"]')(vhtml);
    if (emailAddressHint) {
      if (this.state.role === StartTransactionConstants.ROLE_BUYER) {
        emailAddressHint[0].children[0].text = gettext(`NOTE:
The seller needs to sign up or log in using this email address in order to see the transaction.
        `);
      } else {
        emailAddressHint[0].children[0].text = gettext(`NOTE:
The buyer needs to sign up or log in using this email address in order to see the transaction.
        `);
      }
    }

    const categorySearchInput = select('[data-component="transaction-category-search"]')(vhtml);
    if (categorySearchInput) {
      const categorySearchResults = select('[data-component="transaction-category-searchResults"]')(
        vhtml
      );
      const transactionType = this.getTransactionType();
      const filteredCategories = CategorySearchStore.filterCategories(transactionType);
      let categoryListHtml = [];
      for (const category of filteredCategories) {
        categoryListHtml.push(`${this.generateCategoryItemHtml(category.label, category.value)}`);
      }
      categoryListHtml = `<div> ${categoryListHtml.join('')} </div>`;
      categorySearchResults[0].children = virtualize.fromHTML(categoryListHtml).children;
    }

    // Category error message
    const categorySearchContainer = select('[data-component="category-container"]')(vhtml);
    if (categorySearchContainer) {
      const prohibitedErrorNode = select('[data-attr="error-not-allow"]')(
        categorySearchContainer[0]
      );
      const invalidErrorNode = select('[data-attr="error-invalid"]')(categorySearchContainer[0]);
      if (this.state.isCategoryProhibited) {
        if (prohibitedErrorNode) {
          setVnodeClasses(prohibitedErrorNode[0], { 'is-hidden': false, 'field-error': true });
          // Fill in category name to the error message
          const errorTitleNode = select('[id="error-title"]')(prohibitedErrorNode[0]);
          if (errorTitleNode) {
            errorTitleNode[0].children = [];
            errorTitleNode[0].children.push(
              new VText(`${gettext('We do not allow transactions for')}
              ${this.state.categorySearchQuery}. ${gettext('Please refer to our')} `)
            );
            errorTitleNode[0].children.push(
              h('a', { href: 'legal' }, gettext('Terms of Using the Escrow Platform'))
            );
            errorTitleNode[0].children.push(new VText(` ${gettext('for more information.')}`));
          }
        }
        if (invalidErrorNode) {
          setVnodeClasses(invalidErrorNode[0], { 'is-hidden': true, 'field-error': true });
        }
      }
    }

    // Show logged out signup fields
    const loggedOut = select('[data-component="logged-out"]')(vhtml);
    if (loggedOut) {
      loggedOut[0].properties.className = classNames(loggedOut[0].properties.className.split(' '), {
        'is-hidden': this.state.loggedIn,
      });
    }

    if (!this.state.loggedIn) {
      const initiatorPhone = select('[data-target="initiator-phone"]')(vhtml);
      if (initiatorPhone) {
        initiatorPhone[0].properties.className = classNames(
          initiatorPhone[0].properties.className.split(' '),
          { 'is-hidden': this.state.returning }
        );
      }

      const passwordDynamic = select('[data-target="field-label-password"]')(vhtml);
      if (passwordDynamic) {
        if (this.state.returning) {
          passwordDynamic[0].children[0].text = gettext('Please enter your password');
        } else {
          passwordDynamic[0].children[0].text = gettext('Please enter a new password');
        }
      }
    }

    const advanced = select('[data-component="advanced-options"]')(vhtml);
    if (advanced) {
      advanced[0].properties.className = classNames(advanced[0].properties.className.split(' '), {
        'is-hidden': !this.state.advanced,
      });

      if (this.state.advanced) {
        const advButton = select('[data-component="show-advanced"]')(vhtml);
        if (advButton) {
          advButton[0].children = [new VText(StartTransactionConstants.HIDE_ADVANCED_OPTIONS)];
        }
      }
    }

    // currency prefix
    const currencyPrefix = select('[data-related-name="price-prefix"]')(vhtml);
    const currency = window.config.currencies[this.state.formState.currency];
    if (currencyPrefix) {
      currencyPrefix[0].children[0].text = currency ? currency.symbol : '$';
    }

    // currency prefix in shipping fee
    const shippingPrefix = select('[data-related-name="shipment-fee-prefix"]')(vhtml);
    if (shippingPrefix) {
      shippingPrefix[0].children[0].text = currency ? currency.symbol : '$';
    }

    // Hide shipment options when shipment method is 'No Shipping'
    const shipping = select('[data-component="shipping-options"]')(vhtml);
    if (shipping) {
      shipping[0].properties.className = classNames(shipping[0].properties.className.split(' '), {
        'is-hidden': this.state.shipmentMethod === StartTransactionConstants.SHIPPING_METHOD_NONE,
      });
    }

    // Hide shipping price when seller is paying shipping
    const shippingPrice = select('[data-component="shipment-fee"]')(vhtml);
    if (
      shippingPrice &&
      this.state.shippingPayer === StartTransactionConstants.SHIPPING_PAYER_SELLER
    ) {
      shippingPrice[0].properties.className = classNames(
        shippingPrice[0].properties.className.split(' '),
        'is-hidden'
      );
    }

    const domainInput = select('[data-component="domain-url-field"]')(vhtml);
    if (
      domainInput &&
      domainInput[0].properties.className.split(' ').indexOf('is-invalid') === -1
    ) {
      domainInput[0].properties.className = classNames(
        domainInput[0].properties.className.split(' '),
        { 'is-invalid': this.state.showFormError && !this.state.allDomainsValid }
      );
    }

    // Change phone label of other party to buyer or seller
    const otherPhone = select('[data-target="field-label-non-initiator-phone"]')(vhtml);
    if (otherPhone) {
      if (this.state.role === StartTransactionConstants.ROLE_BUYER) {
        otherPhone[0].children = [new VText(gettext("The seller's phone number (if known)"))];
      } else {
        otherPhone[0].children = [new VText(gettext("The buyer's phone number (if known)"))];
      }
    }

    // Vehicle Transaction specifics
    // Lien holder and Title collection not available for Euro transactions
    const additionalServices = select('[data-component="additional-services"]')(vhtml);
    if (additionalServices) {
      additionalServices[0].properties.className = classNames({
        'is-hidden': this.state.formState.currency === StartTransactionConstants.CURRENCY_EUR,
      });
    }

    const selectedElement = document.querySelector('input[name=additional-services]:checked');
    let selectedService = 'none';
    if (selectedElement) {
      selectedService = selectedElement.value;
    }

    const vinFields = select('[data-component="vin-field"]')(vhtml);
    let vinField;
    if (vinFields) {
      vinField = vinFields[0];
    }

    if (vinField) {
      if (selectedService && selectedService !== 'none') {
        vinField.properties.required = true;
      } else {
        vinField.properties.required = false;
      }
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="signupCreate"]')) {
    const component = new StartTransaction(elem);
    component.replace(elem, component.initialTemplate);
  }
});
