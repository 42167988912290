import deepcopy from 'clone';
import select from 'vtree-select';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import ContentFeedbackStore from '../stores/ContentFeedbackStore';
import ContentFeedbackActions from '../actions/ContentFeedbackActions';
import ContentFeedbackConstants from '../constants/ContentFeedbackConstants';

function getState() {
  return {
    currentPanel: ContentFeedbackStore.getCurrentPanel(),
  };
}

export default class ContentFeedback extends Component {
  constructor(element) {
    super();

    this.setState(getState());
    this.template = virtualize(element);
    this._onChange = this._onChange.bind(this);

    this.addEventListener('click', '[data-target="yes-button"]', this.clickYes.bind(this));

    this.addEventListener('click', '[data-target="no-button"]', this.clickNo.bind(this));

    this.addEventListener('click', '[data-target="submit-button"]', this.submitFeedback.bind(this));
  }

  _onChange() {
    this.setState(getState());
  }

  componentHasMounted() {
    ContentFeedbackStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    ContentFeedbackStore.removeChangeListener(this._onChange);
  }

  clickYes(event) {
    event.preventDefault();
    ContentFeedbackActions.clickYes();
  }

  clickNo(event) {
    event.preventDefault();
    ContentFeedbackActions.clickNo();
  }

  submitFeedback(event) {
    event.preventDefault();

    const feedback = this.rootNode.querySelector('[data-text-target="feedback-textarea"]').value;
    const section = event.target.getAttribute('data-tracking-section');
    ContentFeedbackActions.sendFeedback(feedback, section);
  }

  render() {
    const vhtml = deepcopy(this.template);

    const basePanel = select('[data-target="base-panel"]')(vhtml);
    if (basePanel) {
      setVnodeClasses(basePanel[0], {
        'is-hidden': this.state.currentPanel !== ContentFeedbackConstants.BASE_PANEL,
      });
    }

    const feedbackPanel = select('[data-target="feedback-panel"]')(vhtml);
    if (feedbackPanel) {
      setVnodeClasses(feedbackPanel[0], {
        'is-hidden': this.state.currentPanel !== ContentFeedbackConstants.FEEDBACK_PANEL,
      });
    }

    const thanksPanel = select('[data-target="thanks-panel"]')(vhtml);
    if (thanksPanel) {
      setVnodeClasses(thanksPanel[0], {
        'is-hidden': this.state.currentPanel !== ContentFeedbackConstants.THANKS_PANEL,
      });
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="content-feedback"]')) {
    const component = new ContentFeedback(elem);
    component.replace(elem);
  }
});
