import select from 'vtree-select';
import classNames from 'classnames/dedupe';
import QueryString from 'query-string';
import window from 'window-shim';

import { Form } from './Form';
import ModifyTransactionStore from '../stores/ModifyTransactionStore';
import ModifyTransactionActions from '../actions/ModifyTransactionActions';
import ModifyTransactionConstants from '../constants/ModifyTransactionConstants';

export class ModifyForm extends Form {
  constructor(element, options = {}) {
    super(element, options);
    this._onModify = this._onModify.bind(this);
  }

  componentHasMounted() {
    super.componentHasMounted();
    ModifyTransactionStore.addChangeListener(this._onModify);
    const query = QueryString.parse(window.location.search);
    if (query.tid && query.token) {
      ModifyTransactionActions.getTransaction(query.tid, query.token);
    }
  }

  componentWillDismount() {
    ModifyTransactionStore.removeChangeListener(this._onModify);
  }

  _onModify() {
    const fields = ModifyTransactionStore.savedState();
    for (const field of Object.keys(fields)) {
      const formField = this.rootNode.querySelector(`[name=${field}]`);
      if (formField) {
        formField.value = fields[field];
      }
    }
  }

  render() {
    const vhtml = super.render();

    if (this.state.formErrorTitle) {
      const fields = select('[data-target="formFields"]')(vhtml)[0];
      fields.properties.className = classNames(fields.properties.className.split(' '), {
        'is-hidden': true,
      });
      return vhtml;
    }

    const shipmentPayment = this.state.formState.ShipmentPayment;
    if (shipmentPayment) {
      const shippingCost = select('[data-field="ShipmentFee"]')(vhtml)[0];
      shippingCost.properties.className = classNames({
        'is-hidden': shipmentPayment === ModifyTransactionConstants.SHIPMENT_PAYMENT_SELLER,
      });
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="modifyForm"]')) {
    const component = new ModifyForm(elem);
    component.replace(elem, component.initialTemplate);
  }
});
