import select from 'vtree-select';
import { Currency, currencyToSymbol } from 'escrow-common-js/dist/constants';
import { SimpleForm } from '../utils/SimpleForm';
import CalculatorActions from '../actions/Calculator';

import { gettext } from '../utils/filters';

export class SimpleCalculator extends SimpleForm {
  constructor(element, options = {}) {
    super(element, options);
    this.addEventListener('submit', 'form', this.onSubmission.bind(this));
  }

  fieldChanged(event) {
    super.fieldChanged(event);
    CalculatorActions.input(this.state.formState);
  }

  render() {
    const vhtml = super.render();
    const submitBtn = document.querySelector('[data-simple-calculator-submit]');

    if (this.state.formState.currency) {
      const currencyPrefix = select('[data-related-name="amount-prefix"]')(vhtml)[0];
      currencyPrefix.children[0].text = gettext(
        `for ${currencyToSymbol[Currency[this.state.formState.currency]]}`
      );
    }

    if (this.state.formState.role === 'broker') {
      submitBtn.href = '/transaction/start?ttype=GMQP&role=broker';
    } else {
      submitBtn.href = '/transaction/start';
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="simple-calculator"]')) {
    const component = new SimpleCalculator(elem);
    component.replace(elem, component.initialTemplate);
  }
});
