import AppDispatcher from '../dispatcher/AppDispatcher';
import ContentFeedbackConstants from '../constants/ContentFeedbackConstants';

export class ContentFeedbackActions {
  clickYes() {
    AppDispatcher.handleViewAction({
      actionType: ContentFeedbackConstants.CHANGE_DISPLAYED_PANEL,
      attributes: {
        panel: ContentFeedbackConstants.THANKS_PANEL,
      },
    });
  }

  clickNo() {
    AppDispatcher.handleViewAction({
      actionType: ContentFeedbackConstants.CHANGE_DISPLAYED_PANEL,
      attributes: {
        panel: ContentFeedbackConstants.FEEDBACK_PANEL,
      },
    });
  }

  sendFeedback(feedback, section) {
    AppDispatcher.handleViewAction({
      actionType: ContentFeedbackConstants.SEND_FEEDBACK,
      attributes: {
        feedback: feedback,
        section: section,
      },
    });
  }
}

export default new ContentFeedbackActions();
