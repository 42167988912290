import select from 'vtree-select';

import { SimpleForm } from '../utils/SimpleForm';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import StartTransactionActions from '../actions/StartTransactionActions';
import AuthenticationActions from '../actions/AuthenticationActions';
import FormActions from '../actions/FormActions';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import StartTransactionStore from '../stores/StartTransactionStore';
import FormStore from '../stores/FormStore';

const sectionFields = {
  buyer: [
    'buyer_email_address',
    'buyer_password',
    'buyer_phone',
    'buyer_first_name',
    'buyer_last_name',
  ],
  seller: [
    'seller_email_address',
    'seller_password',
    'seller_phone',
    'seller_first_name',
    'seller_last_name',
  ],
  vehicle: ['vehicle_year', 'vehicle_make', 'vehicle_model', 'vehicle_price', 'vehicle_vin'],
};

function getState(name) {
  const multiStepProgress = { ...StartTransactionStore.multiStepProgress };
  const validFields = FormStore.validFields(name);

  let invalidFieldCount = 0;

  let activeFields = [];

  if (!multiStepProgress.buyerAuthenticated && !multiStepProgress.sellerAuthenticated) {
    activeFields = sectionFields.buyer;
  } else if (multiStepProgress.buyerAuthenticated && !multiStepProgress.sellerAuthenticated) {
    activeFields = sectionFields.seller;
  } else {
    activeFields = sectionFields.vehicle;
  }

  for (const field of activeFields) {
    invalidFieldCount += !validFields[field];
  }

  return {
    multiStepProgress,
    invalidFieldCount,
  };
}

export class AAAStartTransaction extends SimpleForm {
  constructor(element, options = {}) {
    super(element, options);
    this.addEventListener('submit', 'form[data-form-section]', this.onContinue.bind(this));
  }

  _onChange() {
    super._onChange(getState(this.formName));
  }

  componentHasMounted() {
    super.componentHasMounted();
    StartTransactionStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    super.componentWillDismount();
    StartTransactionStore.removeChangeListener(this._onChange);
  }

  fieldBlur(event) {
    super.fieldBlur(event);
    if (event.target.type === 'email') {
      AuthenticationActions.userExists(event.target.value, this.formName);
    }
  }

  onContinue(event) {
    event.preventDefault();

    if (this.state.invalidFieldCount !== 0) {
      FormActions.showError(this.formName);
      return false;
    }

    const formSection = event.target.getAttribute('data-form-section');
    const form = this.state.formState;

    const buyerDetails = {
      email: form.buyer_email_address,
      password: form.buyer_password,
      phone: form.buyer_phone,
      first_name: form.buyer_first_name,
      last_name: form.buyer_last_name,
    };

    const sellerDetails = {
      email: form.seller_email_address,
      password: form.seller_password,
      phone: form.seller_phone,
      first_name: form.seller_first_name,
      last_name: form.seller_last_name,
    };

    const formData = {
      Buyer: {
        Email: form.buyer_email_address,
        Initiator: false,
        CompanyChk: false,
        AutoAgree: false,
        AgreementChecked: false,
      },
      Seller: {
        Email: form.seller_email_address,
        Initiator: true,
        CompanyChk: false,
        AutoAgree: false,
        AgreementChecked: false,
      },
      Partner: { ParnterId: '0' },
      LineItems: [],
      VehicleLineItem: {
        Accept: true,
        BuyComm: '0',
        Description: `${form.vehicle_year} ${form.vehicle_make} ${form.vehicle_model}`,
        ExpirationDate: '',
        ItemName: `${form.vehicle_year} ${form.vehicle_make} ${form.vehicle_model}`,
        Make: form.vehicle_make,
        Model: form.vehicle_model,
        Odometer: '0',
        OtherNotes: '',
        Price: form.vehicle_price,
        Quantity: '1',
        SellComm: '0',
        VIN: form.vehicle_vin,
        Year: form.vehicle_year,
      },
      EscrowPayment: '1',
      InspectionLength: '1',
      Currency: 'USD',
      CommissionType: '1',
      TermsLocked: false,
      AllowReject: true,
      Source: StartTransactionConstants.WWW_SOURCE_TRACKING,
      Title: `${form.vehicle_year} ${form.vehicle_make} ${form.vehicle_model}`,
      TransactionType: StartTransactionConstants.VEHICLE_TRANSACTION_TYPE,
      Description: `${form.vehicle_year} ${form.vehicle_make} ${form.vehicle_model}`,
      Fulfillment: StartTransactionConstants.SHIPPING_METHOD_NONE,
      ShipmentFee: '0',
      ShipmentPayment: '0',
    };

    if (formSection === 'buyer-details') {
      StartTransactionActions.buyerAuthenticate(buyerDetails, this.formName);
    } else if (formSection === 'seller-details') {
      StartTransactionActions.sellerAuthenticate(sellerDetails, this.formName);
    } else {
      StartTransactionActions.submitAAAStartTransaction(formData, buyerDetails, this.formName);
    }
  }

  render() {
    const vhtml = super.render();

    const multiStepProgress = this.state.multiStepProgress;
    const stepsHeader = select('[data-target="steps-header"]')(vhtml);
    const buyerDetails = select('[data-form-section="buyer-details"]')(vhtml);
    const sellerDetails = select('[data-form-section="seller-details"]')(vhtml);
    const vehicleDetails = select('[data-form-section="vehicle-details"]')(vhtml);

    let currentStep;
    if (!multiStepProgress.buyerAuthenticated) {
      currentStep = '1';
    } else if (!multiStepProgress.sellerAuthenticated) {
      currentStep = '2';
    } else {
      currentStep = '3';
    }

    setVnodeClasses(buyerDetails[0], {
      'is-hidden': currentStep !== '1',
    });

    setVnodeClasses(sellerDetails[0], {
      'is-hidden': currentStep !== '2',
    });

    setVnodeClasses(vehicleDetails[0], {
      'is-hidden': currentStep !== '3',
    });

    stepsHeader[0].children[0].text = `Start your Vehicle Escrow - Step ${currentStep} of 3`;

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="aaa-start-transaction"]')) {
    const component = new AAAStartTransaction(elem);
    component.replace(elem, component.initialTemplate);
  }
});
