import window from 'window-shim';
import QueryString from 'query-string';
import localStorage from 'local-storage';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';

import ABTestActions from '../actions/ABTestActions';
import ABTestConstants from '../constants/ABTestConstants';
import TrackingActions from '../actions/TrackingActions';

export class ABTestStore extends ChangeEmitter {
  constructor() {
    super();

    this.variations = {};
    this.experiments = localStorage.get('currentABTests') || {};
  }

  getVariant(description) {
    return this.variations[description];
  }

  getExperiment(experimentName) {
    return this.experiments[experimentName];
  }

  /**
   * show the variant IFF the user is included in the current stage and an odd bucket
   */
  isInOlarkHomepageVariant() {
    return true;
  }

  /**
   * This handles the REGISTER_VARIANT action from the ABTestActions object.
   *
   * @param {Object} action The given action object
   */
  _onRegisterVariant(description, parameter) {
    const query = QueryString.parse(window.location.search);
    const variant = query[parameter];
    if (variant) {
      this.variations[description] = variant;
    }
  }

  _enrol(experimentName) {
    const currentABTests = localStorage.get('currentABTests') || {};
    let bucket;
    if (currentABTests[experimentName]) {
      bucket = currentABTests[experimentName];
    } else {
      // partition the group into buckets of 5% each
      //  odd buckets control
      //  even buckets variant
      // by specifying an int [0, 10), a 5050 split of users can be activated
      // for a given stage (4 -> 40% of users enrolled with 5050)
      const rand = Math.random();
      bucket = Math.floor(rand * 20);

      currentABTests[experimentName] = bucket;
      localStorage.set('currentABTests', currentABTests);
    }
    this.experiments[experimentName] = bucket;
    setTimeout(() => {
      ABTestActions.enrolComplete(experimentName, bucket);
      TrackingActions.trackABTest(experimentName, bucket);
    });
    this.emitChange();
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === ABTestConstants.REGISTER_VARIANT) {
      this._onRegisterVariant(action.testDescription, action.parameter);
      this.emitChange();
    } else if (actionType === ABTestConstants.ENROL) {
      this._enrol(action.attributes.experimentName);
    }
  }
}

const abStore = new ABTestStore();
abStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    abStore.handleViewAction(action);
  }
});

export default abStore;
