var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/phone-verify-card-sent-desc.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div>\n  ";
if(runtime.contextOrFrameLookup(context, frame, "useVoice")) {
output += "\n    You will be called on ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "phoneNumber"), env.opts.autoescape);
output += " and the code will be read to you. Didn't receive a call?\n    <a href=\"#\" data-target=\"send-otp-code-link\">Call again.</a><br/><br/>If you are verifying a mobile,\n    <a href=\"#\" data-target='toggle-phone-verification-strategy'>click here to get an SMS instead</a>\n  ";
;
}
else {
output += "\n    We've sent a text message to ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "phoneNumber"), env.opts.autoescape);
output += ". Didn't receive one?\n    <a href=\"#\" data-target=\"send-otp-code-link\">Resend a new code</a><br/><br/>If you are verifying a landline,\n    <a href=\"#\" data-target='toggle-phone-verification-strategy'>click here to get a call instead</a>\n  ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/phone-verify-card-sent-desc.html"] , dependencies)