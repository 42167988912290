import deepcopy from 'clone';
import formatNumber from 'format-number';
import window from 'window-shim';
import _ from 'lodash';
import { formatPhoneNumber } from '../utils/parse-phone';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import Constants from '../constants/VerifyUserConstants';
import FormConstants from '../constants/FormConstants';
import NavigationConstants from '../constants/NavigationConstants';
import FormStore from './FormStore';
import TrackingActions from '../actions/TrackingActions';
import { removePrefix } from '../utils/ObjectUtils';
import FileUploadStore from '../stores/FileUploadStore';

export class VerifyUserStore extends ChangeEmitter {
  constructor() {
    super();
    this.kycMethod = undefined;
    this.tiersExpanded = {
      1: false,
      2: false,
      3: false,
    };

    this.otpState = {};
    this.phoneVerificationUseVoice = false;

    // Initial form state is the same for all forms
    this.initialFormState = {};
    this.initialVerificationState = {};
  }

  initialiseForm(formName) {
    if (window.js_context && window.js_context.customer_details) {
      this.initialFormState = deepcopy(
        _.pick(window.js_context.customer_details, _.keys(Constants.CUSTOMER_DETAILS))
      );
    } else {
      this.initialFormState = deepcopy(Constants.CUSTOMER_DETAILS);
    }
    if (window.js_context && window.js_context.verification) {
      this.initialVerificationState = deepcopy(
        _.pick(window.js_context.verification, _.keys(Constants.VERIFICATION))
      );
    } else {
      this.initialVerificationState = deepcopy(Constants.VERIFICATION);
    }
    this.otpState[formName] = {};
    if (this.initialFormState.Phone) {
      this._resetOtpState(formName, this.initialFormState.Phone);
    }
    for (const verifiedPhoneNumber of this.initialVerificationState.verified_phones) {
      this._resetOtpState(formName, verifiedPhoneNumber, true);
    }
  }

  toggleTier(tier) {
    this.tiersExpanded[tier] = !this.tiersExpanded[tier];
  }

  getTiersExpanded() {
    return deepcopy(this.tiersExpanded);
  }

  /**
   * Checks if the form can be submitted
   *
   * @param {String} Form Name
   * @returns {Boolean} true if all fields are valid
   */
  formValid(formName) {
    return this.invalidFieldCount(formName) === 0;
  }

  addressValid(formName) {
    const fields = this.validAddressFields(formName);
    return this.invalidFieldCount(formName, fields) === 0;
  }

  validAddressFields(formName) {
    const validFields = FormStore.validFields(formName);
    const addressFields = deepcopy(Constants.ADDRESS_FIELDS);
    for (const field of Object.keys(addressFields)) {
      addressFields[field] = validFields[field];
    }

    return addressFields;
  }

  // Constructs the required JSON in correct format for KYCVerification API
  getKycFields(formName) {
    const fields = FormStore.currentFormState(formName);
    const apiData = {
      Files: this.validFiles(formName).map((doc) => doc.uniqueId),
    };

    if (fields.IdContainsAddress === 'true') {
      fields.AddressProofType = fields.IDType;
    }

    if (formName === Constants.METHOD_TIER_2 && window.js_context.extended_kyc) {
      fields.HasPhotoVerification = 'true';
    }

    apiData.TextInputs = [];
    Constants.KYC_FIELDS.map((field) => {
      if (fields[field]) {
        apiData.TextInputs.push({
          Key: field,
          Value: fields[field],
        });
      }
    });

    return apiData;
  }

  // These forms should implicitly indicate that IsCompany should be true
  isCompanyForm(formName) {
    return formName === Constants.METHOD_TIER_3;
  }

  _getUpdatedPersonalAddress(formName) {
    const fields = FormStore.currentFormState(formName);
    return _.pick(fields, Object.keys(Constants.PERSONAL_ADDRESS_FIELDS));
  }

  _getUpdatedCompanyAddress(formName) {
    const fields = FormStore.currentFormState(formName);
    const companyAddressFields = _.pick(fields, Object.keys(Constants.COMPANY_ADDRESS_FIELDS));
    return removePrefix('CompanyAddress', companyAddressFields);
  }

  _getUpdatedShippingAddress(formName, companyAddress, personalAddress) {
    const fields = FormStore.currentFormState(formName);

    let shippingAddressFields;
    if (this.companyAddressAsShipping(formName)) {
      shippingAddressFields = deepcopy(companyAddress);
      return shippingAddressFields;
    } else if (this.personalAddressAsShipping(formName)) {
      shippingAddressFields = deepcopy(personalAddress);
      return shippingAddressFields;
    }

    shippingAddressFields = _.pick(fields, Object.keys(Constants.SHIPPING_ADDRESS_FIELDS));
    return removePrefix('ShippingAddress', shippingAddressFields);
  }

  _getUpdatedCustomerFields(formName) {
    const fields = FormStore.currentFormState(formName);
    if (fields.Phone && fields['Phone-prefix']) {
      fields.Phone = formatPhoneNumber(fields['Phone-prefix'], fields.Phone);
    }
    if (fields.PhoneAlternate && fields['PhoneAlternate-prefix']) {
      fields.PhoneAlternate = formatPhoneNumber(
        fields['PhoneAlternate-prefix'],
        fields.PhoneAlternate
      );
    }
    if (fields.DateOfBirthDay && fields.DateOfBirthMonth && fields.DateOfBirthYear) {
      fields.DateOfBirth = this.formatDate(
        fields.DateOfBirthYear,
        fields.DateOfBirthMonth,
        fields.DateOfBirthDay
      );
    }

    if (!('IsCompany' in fields)) {
      fields.IsCompany = this.isCompanyForm(formName);
    }

    return _.pick(fields, Object.keys(Constants.CUSTOMER_DETAILS));
  }

  /**
   * The customer details consists of all company address fields and the company name
   * along with personal contact details and address.
   * This should be sent to the new /Customer/id endpoint
   */
  getCustomerDetails(formName) {
    const details = this.getInitialFormState();
    const personal = this._getUpdatedCustomerFields(formName);
    Object.assign(details, personal);

    // If the user is submitting KYC for company, we assume that they will be
    // trading using their company name by default

    const personalAddress = this._getUpdatedPersonalAddress(formName);
    if (!_.isEmpty(personalAddress)) {
      if (!details.PersonalAddress) {
        details.PersonalAddress = {};
      }
      Object.assign(details.PersonalAddress, personalAddress);
    }

    const companyAddress = this._getUpdatedCompanyAddress(formName);
    if (!_.isEmpty(companyAddress)) {
      if (!details.CompanyAddress) {
        details.CompanyAddress = {};
      }
      Object.assign(details.CompanyAddress, companyAddress);
    }

    if (details.IsCompany === 'false') {
      delete details.CompanyAddress;
      delete details.CompanyName;
      if (details.DisplayNameType === 'company') {
        details.DisplayNameType = 'name';
      }
    }

    if (formName.indexOf('verify-tier-') > -1 && details.CompanyName) {
      details.IsCompany = true;
      details.UseUsernameAsDisplayName = false;
    } else if (details.DisplayNameType === 'name') {
      details.IsCompany = false;
      details.UseUsernameAsDisplayName = false;
    } else if (details.DisplayNameType === 'company') {
      details.IsCompany = true;
      details.UseUsernameAsDisplayName = false;
    } else if (details.DisplayNameType === 'username') {
      details.UseUsernameAsDisplayName = true;
      details.IsCompany = false;
      if (details.CompanyName) {
        details.IsCompany = true;
      }
    }

    return details;
  }

  companyAddressAsShipping(formName) {
    const fields = FormStore.currentFormState(formName);
    return fields[Constants.COMPANY_AS_SHIPPING_FIELD] === 'true';
  }

  personalAddressAsShipping(formName) {
    const fields = FormStore.currentFormState(formName);
    return fields[Constants.PERSONAL_AS_SHIPPING_FIELD] === 'true';
  }

  formatDate(year, month, day) {
    const format = formatNumber({
      padLeft: 2,
    });
    return `${year}-${format(month)}-${format(day)}`;
  }

  getBoxNameList(formName) {
    let requiredFiles = deepcopy(Constants.REQUIRED_FILE_BOXES[formName]) || [];
    if (window.js_context.extended_kyc) {
      requiredFiles = requiredFiles.concat(deepcopy(Constants.EXTENDED_KYC_BOX[formName]) || []);
    }

    return requiredFiles;
  }

  getFiles(formName) {
    let files = [];
    for (const boxName of this.getBoxNameList(formName)) {
      files = files.concat(FileUploadStore.getFiles(boxName));
    }
    return files;
  }

  validFiles(formName) {
    let files = [];
    for (const boxName of this.getBoxNameList(formName)) {
      files = files.concat(FileUploadStore.getValidFiles(boxName));
    }
    return files;
  }

  showValidationStatus(formName) {
    const showValidationStatus = FormStore.showValidationStatus(formName);
    for (const boxName of this.getBoxNameList(formName)) {
      showValidationStatus[boxName] = false;
    }
    return showValidationStatus;
  }

  validFileCount(formName) {
    return this.validFiles(formName).length;
  }

  validFields(formName) {
    const fields = FormStore.currentFormState(formName);
    const validFields = FormStore.validFields(formName);
    if (
      fields[Constants.COMPANY_AS_SHIPPING_FIELD] === 'true' ||
      fields[Constants.PERSONAL_AS_SHIPPING_FIELD] === 'true'
    ) {
      for (const key of Object.keys(Constants.SHIPPING_ADDRESS_FIELDS)) {
        validFields[key] = true;
      }
    }

    for (const boxName of this.getBoxNameList(formName)) {
      validFields[boxName] = FileUploadStore.validFileCount(boxName) > 0;
    }

    if (FormConstants.BLOCKED_COUNTRIES.includes(fields.Country)) {
      validFields.Country = false;
    }
    if (FormConstants.BLOCKED_COUNTRIES.includes(fields.CompanyAddressCountry)) {
      validFields.CompanyAddressCountry = false;
    }

    if (formName === 'verify-tier-2' && fields.IdContainsAddress === 'true') {
      validFields['verify-tier-2-address-box'] = true;
    }

    if (
      formName === 'verify-tier-2' &&
      window.js_context.kyc_status.T2 === 'KYC_DOCUMENT_REQUESTED'
    ) {
      validFields['verify-tier-2-identity-box'] = true;
      validFields['verify-tier-2-address-box'] = true;
    }

    return validFields;
  }

  invalidFieldsList(formName) {
    const validFields = this.validFields(formName);
    const invalidFields = [];
    for (const field of Object.keys(validFields)) {
      if (!validFields[field]) {
        invalidFields.push(field);
      }
    }
    return invalidFields;
  }

  invalidFieldCount(formName, validFields = this.validFields(formName)) {
    let invalidFieldCount = 0;
    for (const field of Object.keys(validFields)) {
      if (validFields[field] === false && field !== 'SMSCode') {
        invalidFieldCount++;
      }
    }
    return invalidFieldCount;
  }

  /**
   * Checks if there exists at least one valid file in each upload box in the form
   *
   * @param {Object[]} files Array of files
   * @returns {Boolean} true if there is at least one valid file
   */
  checkFiles(formName) {
    let isValid = true;
    for (const boxName of this.getBoxNameList(formName)) {
      isValid = isValid && FileUploadStore.validFileCount(boxName) >= 1;
    }
    return isValid;
  }

  invalidUploadBoxCount(formName) {
    let count = 0;
    for (const boxName of this.getBoxNameList(formName)) {
      count += FileUploadStore.validFileCount(boxName) < 1;
    }
    return count;
  }

  onFormSubmissionError(action) {
    setTimeout(() => {
      TrackingActions.track({
        event: 'escrow_user_action',
        section: action.form,
        action: 'form-submission',
        value: 'validation-error',
        label: this.invalidFieldsList(action.form),
      });
    });
  }

  getInitialFormState() {
    return deepcopy(this.initialFormState);
  }

  canSMSCodeBeSent(formName, phoneNumber) {
    return !this.getOtpState(formName, phoneNumber).verified && this.validFields(formName).Phone;
  }

  getOtpState(formName, phoneNumber) {
    return deepcopy(this.otpState[formName][phoneNumber] || {});
  }

  _resetOtpState(formName, phoneNumber, isVerified = false) {
    this.otpState[formName][phoneNumber] = {
      verifying: false,
      verified: false || isVerified,
      expires: null,
      sending: false,
      sent: false,
      attempts: 0,
      invalidCode: false,
      apiError: false,
      hidePhoneNumber: false,
    };
  }

  _initOtpStateIfNotExists(formName, phoneNumber) {
    if (!(phoneNumber in this.otpState[formName])) {
      this._resetOtpState(formName, phoneNumber);
    }
  }

  registerOtpSent(formName, phoneNumber, expires) {
    this.otpState[formName][phoneNumber].verified = false;
    this.otpState[formName][phoneNumber].expires = new Date(expires);
    this.otpState[formName][phoneNumber].sent = true;
    this.otpState[formName][phoneNumber].sending = false;
    this.otpState[formName][phoneNumber].invalidCode = false;
    this.otpState[formName][phoneNumber].apiError = false;
    setTimeout(() => {
      TrackingActions.trackVerificationCodeRequest(
        formName,
        this.phoneVerificationUseVoice ? 'call' : 'SMS',
        true
      );
    });
  }

  setOtpSuccessful(formName, phoneNumber) {
    this._resetOtpState(formName, phoneNumber);
    this.otpState[formName][phoneNumber].verified = true;
    this.otpState[formName][phoneNumber].verifying = false;
    this.otpState[formName][phoneNumber].hidePhoneNumber = true;
    setTimeout(() => {
      TrackingActions.trackPhoneVerificationAttempt(formName, 'success');
    });
  }

  setOtpFailed(formName, phoneNumber) {
    let attempts = this.otpState[formName][phoneNumber].attempts;
    if (!attempts) {
      attempts = 0;
    }
    this.otpState[formName][phoneNumber].attempts = attempts + 1;
    this.otpState[formName][phoneNumber].invalidCode = true;
    this.otpState[formName][phoneNumber].verifying = false;
    setTimeout(() => {
      TrackingActions.trackPhoneVerificationAttempt(formName, 'invalid-code');
    });
  }

  togglePhoneVerificationStrategy(formName, phoneNumber) {
    this.phoneVerificationUseVoice = !this.phoneVerificationUseVoice;
    this.otpState[formName][phoneNumber].sending = false;
    this.otpState[formName][phoneNumber].sent = false;
    this.otpState[formName][phoneNumber].expires = null;
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === Constants.INITIALISE_FORM) {
      this.initialiseForm(action.formName);
      this.emitChange();
    } else if (actionType === Constants.VERIFY_TOGGLE_TIER) {
      this.toggleTier(action.tier);
      this.emitChange();
    } else if (actionType === Constants.SELECT_KYC_METHOD) {
      this.kycMethod = action.value;
      this.emitChange();
    } else if (actionType === NavigationConstants.HISTORY_POP) {
      if (
        action.state &&
        action.state[Constants.NAVIGATION_STATE] &&
        action.state[Constants.NAVIGATION_STATE] === Constants.NAVIGATION_STATE_INITIAL
      ) {
        this.kycMethod = undefined;
        setTimeout(() => {
          TrackingActions.track({
            event: 'escrow_user_action',
            section: 'verify-account',
            action: 'view',
          });
        });
        this.emitChange();
      }
    } else if (action.actionType === FormConstants.SHOW_FORM_ERROR) {
      if (
        action.form === Constants.METHOD_TIER_1 ||
        action.form === Constants.METHOD_TIER_2 ||
        action.form === Constants.METHOD_TIER_3
      ) {
        this.onFormSubmissionError(action);
      }
    } else if (actionType === Constants.START_OTP_SMS_REQUEST) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.otpState[action.name][action.phoneNumber].sending = true;
      this.emitChange();
    } else if (actionType === Constants.OTP_SMS_REQUEST_SUCCESS) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.registerOtpSent(action.name, action.phoneNumber, action.expires);
      this.emitChange();
    } else if (actionType === Constants.OTP_SMS_REQUEST_FAILURE) {
      this.otpState[action.name][action.phoneNumber].sending = false;
      this.otpState[action.name][action.phoneNumber].apiError = true;
      setTimeout(() => {
        TrackingActions.trackVerificationCodeRequest(
          action.name,
          this.phoneVerificationUseVoice ? 'call' : 'SMS',
          false,
          action.error.status
        );
      });
      this.emitChange();
    } else if (actionType === Constants.OTP_SMS_VERIFY_START) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.otpState[action.name][action.phoneNumber].verifying = true;
      this.emitChange();
    } else if (actionType === Constants.OTP_SMS_VERIFY_SUCCESS) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.setOtpSuccessful(action.name, action.phoneNumber);
      this.emitChange();
    } else if (actionType === Constants.OTP_SMS_VERIFY_FAILURE) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.setOtpFailed(action.name, action.phoneNumber);
      this.emitChange();
    } else if (actionType === Constants.OTP_CODE_MALFORMED) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.otpState[action.name][action.phoneNumber].invalidCode = false;
      this.otpState[action.name][action.phoneNumber].verifying = false;
      setTimeout(() => {
        TrackingActions.trackPhoneVerificationAttempt(action.name, 'malformed-code');
      });
      this.emitChange();
    } else if (actionType === Constants.PHONE_VERIFY_UNKNOWN_ERROR) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.otpState[action.name][action.phoneNumber].verifying = false;
      this.otpState[action.name][action.phoneNumber].apiError = true;
      setTimeout(() => {
        TrackingActions.trackPhoneVerificationAttempt(
          action.name,
          `unknown-error: ${action.errorCode}`
        );
      });
      this.emitChange();
    } else if (actionType === Constants.TOGGLE_PHONE_VERIFICATION_STRATEGY) {
      this._initOtpStateIfNotExists(action.name, action.phoneNumber);
      this.togglePhoneVerificationStrategy(action.name, action.phoneNumber);
      this.emitChange();
    }
  }

  handleServerAction() {}
}

const verifyUserStore = new VerifyUserStore();
verifyUserStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    verifyUserStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    verifyUserStore.handleServerAction(action);
  }
});

export default verifyUserStore;
