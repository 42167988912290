var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["icons/hex.html"] = require( "icons/hex.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/timeline.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/buttons.html", false, "escrow_www/templates/components/timeline.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("button", t_1);
env.getTemplate("icons/hex.html", false, "escrow_www/templates/components/timeline.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(t_4.hasOwnProperty("hex")) {
var t_7 = t_4.hex;
} else {
cb(new Error("cannot import 'hex'")); return;
}
context.setVariable("hexIcon", t_7);
var macro_t_8 = runtime.makeMacro(
["date", "title", "summary"], 
["link", "flip"], 
function (l_date, l_title, l_summary, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("date", l_date);
frame.set("title", l_title);
frame.set("summary", l_summary);
frame.set("link", kwargs.hasOwnProperty("link") ? kwargs["link"] : "");
frame.set("flip", kwargs.hasOwnProperty("flip") ? kwargs["flip"] : "");
var t_9 = "";t_9 += "<a href=\"";
t_9 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
t_9 += "\" class=\"timeline-item ";
if(runtime.contextOrFrameLookup(context, frame, "flip")) {
t_9 += "timeline-item--flip";
;
}
t_9 += "\" href=\"#\" title=\"";
t_9 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_10 = "";t_10 += "Click to read the full article";
cb(null, t_10);
;
return t_10;
}
), true && env.opts.autoescape);
t_9 += "\">\n  <div class=\"timeline-intro\">\n    <h2 class=\"timeline-title\">\n      ";
t_9 += runtime.suppressValue(l_title, env.opts.autoescape);
t_9 += "\n    </h2>\n    <div class=\"timeline-date\">";
t_9 += runtime.suppressValue(l_date, env.opts.autoescape);
t_9 += "</div>\n  </div><span class=\"timeline-summary\">\n    ";
t_9 += runtime.suppressValue(l_summary, env.opts.autoescape);
t_9 += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "link")) {
t_9 += "<span class=\"timeline-link\">";
t_9 += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_11 = "";t_11 += "Read more";
cb(null, t_11);
;
return t_11;
}
), true && env.opts.autoescape);
t_9 += "</span>";
;
}
t_9 += "\n  </span>\n  <div class=\"timeline-icon\">";
t_9 += runtime.suppressValue((lineno = 17, colno = 36, runtime.callWrap(t_7, "hexIcon", context, [])), env.opts.autoescape);
t_9 += "</div>\n</a>";
;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
context.addExport("item");
context.setVariable("item", macro_t_8);
output += "\n\n";
var macro_t_12 = runtime.makeMacro(
["title", "summary"], 
[], 
function (l_title, l_summary, kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("title", l_title);
frame.set("summary", l_summary);
var t_13 = "";t_13 += "<div class=\"timeline-event\">\n  <div class=\"timeline-intro\">\n    <h2 class=\"timeline-title\">";
t_13 += runtime.suppressValue(l_title, env.opts.autoescape);
t_13 += "</h2>\n  </div>\n  <span class=\"timeline-eventSummary\">\n    ";
t_13 += runtime.suppressValue(l_summary, env.opts.autoescape);
t_13 += "\n  </span>\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_13);
});
context.addExport("event");
context.setVariable("event", macro_t_12);
output += "\n\n";
var macro_t_14 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_15 = "";t_15 += "<div class=\"timeline-action\" data-target=\"load-more-news\">\n    ";
t_15 += runtime.suppressValue((lineno = 38, colno = 19, runtime.callWrap(runtime.memberLookup((t_1),"primary"), "button[\"primary\"]", context, [(lineno = 38, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Load more"])),runtime.makeKeywordArgs({"specifier": "timeline-more"})])), env.opts.autoescape);
t_15 += "\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_15);
});
context.addExport("more");
context.setVariable("more", macro_t_14);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/timeline.html"] , dependencies)