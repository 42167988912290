var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/inputs.html"] = require( "components/inputs.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/companyDocuments.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/inputs.html", false, "escrow_www/templates/js/companyDocuments.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("input", t_1);
var macro_t_4 = runtime.makeMacro(
[], 
["companyType"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("companyType", kwargs.hasOwnProperty("companyType") ? kwargs["companyType"] : "sole-proprietor");
var t_5 = "";t_5 += "\n  <div data-target=\"companyDocuments\">\n  ";
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "sole-proprietor") {
t_5 += "\n    ";
var t_6;
t_6 = [{"name": (lineno = 6, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Fictitious Name Certificate or Statement"])),"value": "fictitious-certificate"},{"name": (lineno = 7, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Assumed Name"])),"value": "certificate-assumed-name"},{"name": (lineno = 8, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Business License"])),"value": "business-license"},{"name": (lineno = 9, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Registration of Trade Name"])),"value": "registration-of-trade-name"}];
frame.set("options", t_6, true);
if(frame.topLevel) {
context.setVariable("options", t_6);
}
if(frame.topLevel) {
context.addExport("options", t_6);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "general-partnership") {
t_5 += "\n    ";
var t_7;
t_7 = [{"name": (lineno = 13, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Partnership Agreemeent"])),"value": "partnership-agreement"},{"name": (lineno = 14, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Fictitious Name Certificate or Statement"])),"value": "fictitious-certificate"},{"name": (lineno = 15, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Assumed Name"])),"value": "certificate-assumed-name"},{"name": (lineno = 16, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Business License"])),"value": "business-license"}];
frame.set("options", t_7, true);
if(frame.topLevel) {
context.setVariable("options", t_7);
}
if(frame.topLevel) {
context.addExport("options", t_7);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "limited-partnership") {
t_5 += "\n    ";
var t_8;
t_8 = [{"name": (lineno = 20, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Limited Partnership"])),"value": "certificate-limited-partnership"},{"name": (lineno = 21, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Limited Partnership Document"])),"value": "limited-partnership-document"}];
frame.set("options", t_8, true);
if(frame.topLevel) {
context.setVariable("options", t_8);
}
if(frame.topLevel) {
context.addExport("options", t_8);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "limited-liability-partnership") {
t_5 += "\n    ";
var t_9;
t_9 = [{"name": (lineno = 25, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Statement of Qualification"])),"value": "statement-of-qualification"},{"name": (lineno = 26, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Limited Partnership Document"])),"value": "limited-partnership-document"},{"name": (lineno = 27, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Limited Liability Partnership Election"])),"value": "limited-liability-partnership-election"}];
frame.set("options", t_9, true);
if(frame.topLevel) {
context.setVariable("options", t_9);
}
if(frame.topLevel) {
context.addExport("options", t_9);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "limited-liability-company") {
t_5 += "\n    ";
var t_10;
t_10 = [{"name": (lineno = 31, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Articles of Organization"])),"value": "articles-of-organization"},{"name": (lineno = 32, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Organization"])),"value": "certificate-of-organization"},{"name": (lineno = 33, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Formation"])),"value": "certificate-of-formation"}];
frame.set("options", t_10, true);
if(frame.topLevel) {
context.setVariable("options", t_10);
}
if(frame.topLevel) {
context.addExport("options", t_10);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "corporation") {
t_5 += "\n    ";
var t_11;
t_11 = [{"name": (lineno = 37, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Articles of Incorporation"])),"value": "articles-of-incorporation"},{"name": (lineno = 38, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Good Standing"])),"value": "certificate-good-standing"}];
frame.set("options", t_11, true);
if(frame.topLevel) {
context.setVariable("options", t_11);
}
if(frame.topLevel) {
context.addExport("options", t_11);
}
t_5 += "\n  ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "companyType") == "unincorporated-association") {
t_5 += "\n    ";
var t_12;
t_12 = [{"name": (lineno = 42, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Business License"])),"value": "business-license"},{"name": (lineno = 43, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Fictitious Name Certificate or Statement"])),"value": "fictitious-certificate"},{"name": (lineno = 44, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Assumed Name"])),"value": "certificate-assumed-name"},{"name": (lineno = 45, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Organization/Association Articles or Bylaws"])),"value": "organization-articles"},{"name": (lineno = 46, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Good Standing"])),"value": "certificate-good-standing"},{"name": (lineno = 47, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Formation"])),"value": "certificate-of-formation"},{"name": (lineno = 48, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Organization"])),"value": "certificate-of-organization"},{"name": (lineno = 49, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Statement of Organization"])),"value": "statement-of-organization"},{"name": (lineno = 50, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Registration"])),"value": "certificate-of-registration"}];
frame.set("options", t_12, true);
if(frame.topLevel) {
context.setVariable("options", t_12);
}
if(frame.topLevel) {
context.addExport("options", t_12);
}
t_5 += "\n  ";
;
}
else {
t_5 += "\n    ";
var t_13;
t_13 = [{"name": (lineno = 54, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Fictitious Name Certificate or Statement"])),"value": "fictitious-certificate"},{"name": (lineno = 55, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Certificate of Assumed Name"])),"value": "certificate-assumed-name"},{"name": (lineno = 56, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Business License"])),"value": "business-license"},{"name": (lineno = 57, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Registration of Trade Name"])),"value": "registration-of-trade-name"}];
frame.set("options", t_13, true);
if(frame.topLevel) {
context.setVariable("options", t_13);
}
if(frame.topLevel) {
context.addExport("options", t_13);
}
t_5 += "\n  ";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
t_5 += "\n\n  ";
t_5 += runtime.suppressValue((lineno = 61, colno = 19, runtime.callWrap(runtime.memberLookup((t_1),"formSelect"), "input[\"formSelect\"]", context, [(lineno = 61, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Document Type"])),"CompanyProofType",runtime.makeKeywordArgs({"required": true,"options": runtime.contextOrFrameLookup(context, frame, "options"),"attrs": {"data-e2e-target": "company-details-type-dropdown","data-component": "company-proof-dropdown"},"placeholder": "-- Please select a document type --"})])), env.opts.autoescape);
t_5 += "\n  </div>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
context.addExport("companyDocuments");
context.setVariable("companyDocuments", macro_t_4);
output += "\n\n";
output += runtime.suppressValue((lineno = 67, colno = 17, runtime.callWrap(macro_t_4, "companyDocuments", context, [runtime.contextOrFrameLookup(context, frame, "companyType")])), env.opts.autoescape);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/companyDocuments.html"] , dependencies)