import { ChangeEmitter } from '../utils/ChangeEmitter';

export class CreditCardStore extends ChangeEmitter {
  constructor() {
    super();
    this.selectedCreditCard = null;
  }

  getSelected() {
    return this.selectedCreditCard;
  }

  setSelected(creditCard) {
    this.selectedCreditCard = creditCard;
    this.emitChange();
  }
}

const creditCardStore = new CreditCardStore();
export default creditCardStore;
