import window from 'window-shim';
import document from 'document-shim';
import { fetchRequest } from '../utils/FetchUtils';

const getCustomerButtonsRequest = () =>
  fetchRequest('GET', `${window.config.ecart_url}/request/GetCustomerButtons`);

const handleGetCustomerButtonResponse = (response) =>
  response.json().then((body) => {
    if (body.ClientData.length > 0) {
      return window.Promise.reject();
    }
    return window.Promise.resolve();
  });

const legacyButtonsCheck = () => {
  // Check if a previous cookie value has been set. If not, then call the Ecart
  // endpoint.
  const userHasButtonsCookie = document.cookie.match(/user_has_legacy_buttons=([^;]+)/);
  if (userHasButtonsCookie) {
    const value = userHasButtonsCookie[1];
    if (value === '1') {
      return window.Promise.reject();
    } else if (value === '0') {
      return window.Promise.resolve();
    }
  }

  return getCustomerButtonsRequest().then((response) => {
    // The first call of a session to Ecart may return 302, but include a
    // set-cookie value which will allow an authorised subsequent call.
    if (response.status === 302) {
      return getCustomerButtonsRequest().then(handleGetCustomerButtonResponse);
    }
    return handleGetCustomerButtonResponse(response);
  });
};

export default legacyButtonsCheck;
