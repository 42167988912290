import deepcopy from 'clone';
import window from 'window-shim';
import formatNumber from 'format-number';
import select from 'vtree-select';
import VText from 'virtual-dom/vnode/vtext';
import classNames from 'classnames/dedupe';

import SearchResultsTemplate from 'templates/js/calculatorSearchResults.html';

import virtualize from '../utils/virtualize';
import CalculatorActions from '../actions/Calculator';
import CalculatorStore from '../stores/Calculator';
import CalculatorConstants from '../constants/Calculator';
import SearchResultsStore from '../stores/SearchResultsStore';
import Component from '../utils/Component';
import { gettext } from '../utils/filters';
import TrackingActions from '../actions/TrackingActions';

function getState() {
  return {
    fee: CalculatorStore.getFee(),
    baseFee: CalculatorStore.getBaseFee(),
    currency: CalculatorStore.getCurrency(),
    value: CalculatorStore.amount,
    role: CalculatorStore.role,
    searchQuery: CalculatorStore.searchQuery,
    searchSelection: CalculatorStore.searchSelection,
    searchHoverText: CalculatorStore.searchHoverText,
    searchHoverIdx: CalculatorStore.searchHoverIdx,
    searchResults: SearchResultsStore.getResults('CALC_SEARCH').results,
    type: CalculatorStore.type,
  };
}

export default class Calculator extends Component {
  constructor(element) {
    super();

    this.template = virtualize(element);
    this._onChange = this._onChange.bind(this);

    this.addEventListener(
      'keyup',
      'input[data-component="calculator-price"]',
      this._onInputChange.bind(this)
    );
    this.addEventListener(
      'input',
      'input[data-component="calculator-price"]',
      this._onPriceChange.bind(this)
    );
    this.addEventListener(
      'change',
      'select[data-component="calculator-currency"]',
      this._onInputChange.bind(this)
    );
    this.addEventListener(
      'change',
      'select[data-component="calculator-role"]',
      this._onInputChange.bind(this)
    );
    this.addEventListener(
      'keypress',
      'input[data-component="calculator-price"]',
      this._onInputKeyPress.bind(this)
    );
    this.addEventListener('blur', 'input', this._onBlur.bind(this));
    this.addEventListener('blur', 'select', this._onBlur.bind(this));
    this.addEventListener(
      'mouseover',
      '[data-component="calculator-dropdown-item"]',
      this._onSearchHover.bind(this)
    );
    this.addEventListener(
      'mousedown',
      '[data-component="calculator-searchResults"]',
      this._onSearchSelect.bind(this)
    );
    // mousedown doesn't work on iOS, use touchstart instead
    this.addEventListener(
      'touchstart',
      '[data-component="calculator-searchResults"]',
      this._onSearchSelect.bind(this)
    );
    this.addEventListener(
      'keyup',
      '[data-component="calculator-search"]',
      this._onSearchKeyUp.bind(this)
    );
    this.addEventListener(
      'keydown',
      '[data-component="calculator-search"]',
      this._onSearchKeyDown.bind(this)
    );
    this.addEventListener(
      'keypress',
      '[data-component="calculator-search"]',
      this._onSearchKeyPress.bind(this)
    );
    this.addEventListener('blur', '[data-component="calculator-search"]', this._onBlur.bind(this));
    // iOS focusing
    this.addEventListener(
      'click',
      '[data-component="calculator-search"]',
      this._onSearchClick.bind(this)
    );
    // chrome
    this.addEventListener(
      'pointerdown',
      '[data-component="calculator-search"]',
      this._onSearchClick.bind(this)
    );
    this.addEventListener(
      'focus',
      '[data-component="calculator-search"]',
      this._onSearchFocus.bind(this)
    );

    this.partner = element.getAttribute('data-calculator-partner');

    this.setState(getState());
  }

  componentHasMounted() {
    CalculatorStore.addChangeListener(this._onChange);
    SearchResultsStore.addChangeListener(this._onChange);
    this._updateValues();
  }

  componentWillDismount() {
    CalculatorStore.removeChangeListener(this._onChange);
    SearchResultsStore.removeChangeListener(this._onChange);
  }

  /*
   * Removes the disallowed option of motor vehicle brokering, adds hover class
   *
   * @param [List] results A {phrase, category} dict of possible search results
   */
  _filteredResults(results) {
    let filteredResults;
    if (this.state.role === CalculatorConstants.ROLE_BROKER) {
      filteredResults = results.filter(
        (result) => result.category !== CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE
      );
    } else {
      filteredResults = results;
    }

    return filteredResults.map((result) => {
      // Add "hover" class to a result for keyboard-control purposes
      if (result.phrase === this.state.searchHoverText) {
        return {
          phrase: result.phrase,
          category: result.category,
          mutator: 'is-dropdown-item-hover',
        };
      }

      return {
        phrase: result.phrase,
        category: result.category,
      };
    });
  }

  _onSearchHover(event) {
    const results = this.rootNode.querySelector(
      '[data-component="calculator-searchResults"]'
    ).children;
    let idx = 0;
    for (let i = 0; i < results.length; i++) {
      if (results[i].children[0].text === event.target.text) {
        idx = i;
        break;
      }
    }

    CalculatorActions.searchHover(event.target.text, idx);
  }

  _onSearchSelect(event) {
    CalculatorActions.searchSelect(event.target.getAttribute('data-value'), event.target.text);
    this._updateValues();
  }

  _onSearchKeyUp(event) {
    // Don't trigger a search on the up/down arrow keys
    const keyCodeIgnore = [38, 40];
    if (keyCodeIgnore.indexOf(event.keyCode) === -1) {
      CalculatorActions.search(event.target.textContent);
    }
  }

  _onSearchKeyDown(event) {
    let modifier = 0;
    if (event.keyCode === 38) {
      // Up key
      modifier = -1;
    } else if (event.keyCode === 40) {
      // Down key
      modifier = 1;
    }

    if (modifier !== 0) {
      const results = this.rootNode.querySelector('[data-component="calculator-searchResults"]');
      const lastResult = results.children.length - 1;
      if (this.state.searchHoverIdx + modifier > lastResult) {
        CalculatorActions.searchHover(results.children[0].children[0].text, 0);
      } else if (this.state.searchHoverIdx + modifier < 0) {
        CalculatorActions.searchHover(results.children[lastResult].children[0].text, lastResult);
      } else {
        CalculatorActions.searchHover(
          results.children[this.state.searchHoverIdx + modifier].children[0].text,
          this.state.searchHoverIdx + modifier
        );
      }
      event.preventDefault();
    }
  }

  _onSearch(event) {
    CalculatorActions.search(event.target.textContent);
    this._updateValues();
  }

  _onSearchClick(event) {
    event.target.focus();
  }

  _onSearchFocus() {
    // Selects contents of search box AFTER focus
    setTimeout(() => {
      document.execCommand('selectAll', false, null);
    });
  }

  _onSearchKeyPress(event) {
    // Track alphanumeric keystrokes
    if (/[a-zA-Z0-9]/.test(String.fromCharCode(event.keyCode))) {
      CalculatorActions.incrementKeystrokes();
    }
    // Prevent user string overflowing input box
    if (event.target.textContent.length > 24) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      // On Enter keypress, select hovered (or first result) and focus on next field
      const results = this.rootNode.querySelector('[data-component="calculator-searchResults"]');
      if (results) {
        let result;
        if (this.state.searchHoverIdx !== -1) {
          result = results.children[this.state.searchHoverIdx].children[0];
          if (result) {
            CalculatorActions.searchSelect(result.getAttribute('data-value'), result.text);
          }
        } else {
          result = results.children[0].children[0];
          if (result) {
            CalculatorActions.searchSelect(result.getAttribute('data-value'), result.text);
          }
        }
        const price = this.rootNode.querySelector('input[data-component="calculator-price"]');
        price.focus();
      }
      event.preventDefault();
    }
  }

  _updateValues() {
    const amount = Number(
      this.rootNode.querySelector('input[data-component="calculator-price"]').value
    );
    const currency = this.rootNode.querySelector(
      'select[data-component="calculator-currency"]'
    ).value;
    const role = this.rootNode.querySelector('select[data-component="calculator-role"]').value;
    const descriptionField = this.rootNode.querySelector('[data-component="calculator-search"]');

    CalculatorActions.input({
      currency: currency,
      amount: amount,
      role: role,
      description: descriptionField.textContent,
    });

    if (
      role === CalculatorConstants.ROLE_BROKER &&
      this.state.type === CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE
    ) {
      CalculatorActions.searchSelect('', '');
    }
  }

  _onBlur(event) {
    const trackingDict = {
      event: 'escrow_user_action',
      section: 'calculator',
      label: event.target.name || event.target.attributes.name.value,
      action: 'field-blur',
      value: event.target.value || event.target.textContent,
    };

    if (trackingDict.label === 'calculator-search') {
      // Add subsection to original dict
      trackingDict.subsection = 'calculator-search-selected';
      // Send event for keystroke count and clear count
      TrackingActions.track({
        event: 'escrow_user_action',
        section: 'calculator',
        subsection: 'calculator-search-keystrokes',
        label: 'calculator-search',
        action: 'field-blur',
        value: CalculatorStore.searchKeystrokes,
      });
      CalculatorActions.clearKeystrokes();
    }

    TrackingActions.track(trackingDict);
  }

  _onPriceChange(event) {
    event.preventDefault();
    // Restrict the length of the input field to protect against integer
    // overflow in the fee calculation
    if (event.target.value.length > 12) {
      event.target.value = event.target.value.slice(0, 12);
    }
    if (event.target.value !== '') {
      this._updateValues();
    }
    return false;
  }

  _onInputChange(event) {
    event.preventDefault();
    this._updateValues();
    return false;
  }

  _onInputKeyPress(event) {
    // Only allow the user to enter numbers into the input field.
    const keyCodeWhitelist = [8, 9, 35, 36, 37, 39, 46];
    const whichWhitelist = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    if (event.keyCode === 38) {
      // Up key
      event.target.increment();
    } else if (event.keyCode === 40) {
      // Down key
      event.target.decrement();
    } else if (
      keyCodeWhitelist.indexOf(event.keyCode) === -1 &&
      whichWhitelist.indexOf(event.which) === -1
    ) {
      event.preventDefault();
    }
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    const currency = window.config.currencies[this.state.currency];
    const forLabel = gettext('for');
    const prefix = `${forLabel} ${currency.symbol}`;
    const currencyPrefix = currency.symbol;

    const formatter = formatNumber({
      prefix: currencyPrefix,
      round: 2,
      padRight: 2,
    });

    const prefixElements = select(
      '[data-component="calculator-price-field"] [data-component="field-prefix"]'
    )(vhtml);
    if (prefixElements) {
      const prefixElem = prefixElements[0];
      prefixElem.properties.innerHTML = prefix;
    }

    let fee;
    if (this.partner === 'shopify') {
      // Shopify should have the base fee without the minimum
      fee = formatter(this.state.baseFee);
    } else {
      fee = formatter(this.state.fee);
    }

    const feeElem = select('[data-text="calculator-showFee"]')(vhtml);
    if (feeElem) {
      feeElem[0].children.push(new VText(fee));
    }

    const searchQuery = this.rootNode.querySelector('[data-component="calculator-search"]');
    // Clears the selection box in this scenario as motor vehicles cannot be brokered.
    if (searchQuery) {
      if (
        this.state.role === CalculatorConstants.ROLE_BROKER &&
        this.state.type === CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE
      ) {
        searchQuery.textContent = '';
      } else if (this.state.searchSelection !== '') {
        searchQuery.textContent = this.state.searchSelection;
      } else if (this.state.searchQuery === '' && searchQuery.innerHTML !== '') {
        searchQuery.innerHTML = '';
      }
    }

    const resultsElement = select('[data-component="calculator-searchResults"]')(vhtml);
    if (resultsElement) {
      let results;
      if (this.state.searchResults.length > 0) {
        results = this._filteredResults(this.state.searchResults);
      } else if (this.state.searchQuery) {
        // No results for query - default to blank category
        // This will go to transaction choice page
        results = this._filteredResults([{ phrase: this.state.searchQuery, category: '' }]);
      } else if (this.partner !== 'shopify') {
        // No search query inputted - show defaults
        results = this._filteredResults(CalculatorConstants.SEARCH_DEFAULTS);
      }
      // SearchResultsTemplate will return a div with the items as children.
      // This is so that virtualize will properly create nodes for all of items.
      const list = virtualize.fromHTML(
        SearchResultsTemplate.render({
          items: results,
          specifier: 'calculator-dropdown-item',
        })
      );

      resultsElement[0].children = list.children;
    }

    const roleSpecifier = select('[data-target="calculator-content-role"]')(vhtml);
    if (roleSpecifier) {
      CalculatorStore.lockRole(roleSpecifier[0].properties.value);
      const roleField = this.rootNode.querySelector('select[data-component="calculator-role"]')
        .parentNode.parentNode.parentNode;
      roleField.className = classNames(roleField.className.split(' '), { 'is-hidden': true });
    }
    const typeSpecifier = select('[data-target="calculator-content-type"]')(vhtml);
    if (typeSpecifier) {
      CalculatorStore.lockType(typeSpecifier[0].properties.value);
      const typeField = this.rootNode.querySelector('div[data-component="calculator-search"]')
        .parentNode.parentNode;
      typeField.className = classNames(typeField.className.split(' '), { 'is-hidden': true });
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="calculator"]')) {
    new Calculator(e).replace(e);
  }
});
