import deepcopy from 'clone';
import select from 'vtree-select';
import velocity from 'velocity-animate';
import VText from 'virtual-dom/vnode/vtext';
import h from 'virtual-dom/h';

import CompanyDocuments from 'templates/js/companyDocuments.html';

import virtualize from '../utils/virtualize';
import { Form } from '../components/Form';
import Constants from '../constants/VerifyUserConstants';
import FormStore from '../stores/FormStore';
import VerifyUserStore from '../stores/VerifyUserStore';
import VerifyUserActions from '../actions/VerifyUserActions';
import AuthenticationStore from '../stores/AuthenticationStore';
import FileUploadStore from '../stores/FileUploadStore';
import FormActions from '../actions/FormActions';
import { gettext } from '../utils/filters';
import { setVnodeClasses } from '../utils/set-vnode-classes';

function getState(formName) {
  return {
    customerID: AuthenticationStore.getCustomerID(),
    validFileCount: VerifyUserStore.validFileCount(formName),
    invalidFieldCount: VerifyUserStore.invalidFieldCount(formName),
    validFields: VerifyUserStore.validFields(formName),
    showValidationStatus: VerifyUserStore.showValidationStatus(formName),
  };
}

export class VerifyUser extends Form {
  constructor(element, props = {}) {
    super(element, props);
    this.template = virtualize(element);
    this._onChange = this._onChange.bind(this);
    this.props = props;

    VerifyUserActions.initialiseForm(this.formName);
    this._onChange();
  }

  componentHasMounted() {
    VerifyUserStore.addChangeListener(this._onChange);
    AuthenticationStore.addChangeListener(this._onChange);
    FormStore.addChangeListener(this._onChange);
    this.prefillPhoneFields(VerifyUserStore.initialFormState);
    FileUploadStore.addChangeListener(this._onChange);
    this.validateRequiredFields();
  }

  componentWillDismount() {
    VerifyUserStore.removeChangeListener(this._onChange);
    AuthenticationStore.removeChangeListener(this._onChange);
    FormStore.removeChangeListener(this._onChange);
    FileUploadStore.removeChangeListener(this._onChange);
  }

  showFormErrors() {
    FormActions.showError(this.formName);

    const firstInvalidField = this.rootNode.querySelector('[data-target="field"].is-invalid');

    if (firstInvalidField) {
      velocity(firstInvalidField, 'scroll', { duration: 500, offset: -80 });
      firstInvalidField.focus();
    }
  }

  _onSubmission(event) {
    event.preventDefault();
    if (VerifyUserStore.formValid(this.formName)) {
      const type = event.target.getAttribute('data-submit-type');
      if (type === Constants.VERIFY_SUBMIT_KYC) {
        VerifyUserActions.submitKYC(this.formName, VerifyUserStore.getKycFields(this.formName));
      } else if (type === Constants.VERIFY_SUBMIT_CUSTOMER_KYC) {
        VerifyUserActions.submit(
          this.formName,
          this.state.customerID,
          VerifyUserStore.getKycFields(this.formName),
          VerifyUserStore.getCustomerDetails(this.formName)
        );
      }
    } else {
      this.showFormErrors();
    }
  }

  _onChange() {
    super._onChange(getState(this.formName));
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    const firstName = this.state.formState.FirstName;
    const lastName = this.state.formState.LastName;
    const companyName = this.state.formState.CompanyName;

    const idAddress = this.state.formState.IdContainsAddress;
    const proofOfAddress = select('[data-target="address-proof"]')(vhtml);
    if (idAddress === 'true') {
      setVnodeClasses(proofOfAddress[0], { 'is-hidden': true });
    } else if (proofOfAddress) {
      setVnodeClasses(proofOfAddress[0], { 'is-hidden': false });
    }
    if (firstName && lastName) {
      const idRequirement = `Government issued photo ID of ${firstName} ${lastName} (e.g. passport or drivers licence)`;
      const addressRequirement = `Proof of personal address of ${firstName} ${lastName} (e.g. bank statement or utility bill issued within last 3 months)`;

      const dynamicIdentities = select('[data-kyc-requirement="identity"]')(vhtml);
      const dynamicAddresses = select('[data-kyc-requirement="address"]')(vhtml);
      if (dynamicIdentities) {
        for (const dynamicIdentity of dynamicIdentities) {
          dynamicIdentity.children = [new VText(gettext(idRequirement))];
        }
      }
      if (dynamicAddresses) {
        for (const dynamicAddress of dynamicAddresses) {
          dynamicAddress.children = [new VText(gettext(addressRequirement))];
        }
      }
    }

    if (companyName) {
      const companyRequirement = `Company registration documents for ${companyName}`;
      const dynamicCompanies = select('[data-kyc-requirement="company"]')(vhtml);
      if (dynamicCompanies) {
        for (const dynamicCompany of dynamicCompanies) {
          dynamicCompany.children = [new VText(gettext(companyRequirement))];
        }
      }
    }

    if (this.state.formState.CompanyType) {
      const docs = select('[data-target="companyDocuments"]')(vhtml);
      if (docs) {
        docs[0].children = virtualize.fromHTML(
          CompanyDocuments.render({
            companyType: this.state.formState.CompanyType,
          })
        ).children;
      }
    }

    if (this.state.formState.CompanyRelationship === 'representative') {
      const additionalInfoDivs = select('[data-component="additional-info-text"]')(vhtml);
      const extraProofRequirementDiv = select('[data-component="extra-proof-requirements"]')(vhtml);

      const authorizedRepresentativeText = new VText(
        'You are required to submit a letter signed ' +
          "by one of the company Director's confirming you are " +
          'an Authorized Representative. '
      );

      const authorisedRepresentativeTemplateLink = h(
        'a',
        {
          href: 'https://s3-us-west-2.amazonaws.com/escrow-prod-us-west-2-assets/pdf/Appointment+of+Authorized+Representative.pdf',
          target: '_blank',
        },
        '(download template)'
      );

      const dotPoint = h('li', {}, new VText('You submit an authorization letter'));

      if (additionalInfoDivs) {
        additionalInfoDivs[0].children = [
          authorizedRepresentativeText,
          authorisedRepresentativeTemplateLink,
        ];
      }
      if (extraProofRequirementDiv) {
        extraProofRequirementDiv[0].children = [dotPoint, authorisedRepresentativeTemplateLink];
      }
    }

    return super.render(vhtml);
  }

  componentDidUpdate(oldState = null) {
    if (oldState && oldState.formState.CompanyType !== this.state.formState.CompanyType) {
      // new company type was selected, reset company proof type
      const companyProofType = this.rootNode.querySelector(
        '[data-component="company-proof-dropdown"]'
      );
      companyProofType.value = '---';
    }
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="verifyForm"]')) {
    const component = new VerifyUser(elem);
    component.replace(elem, component.initialTemplate);
  }
});
