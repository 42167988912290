import select from 'vtree-select';
import window from 'window-shim';
import Clipboard from 'clipboard';
import uuidv1 from 'uuid/v1';
import { setVnodeClasses } from '../utils/set-vnode-classes';

import { SimpleForm } from '../utils/SimpleForm';
import legacyButtonsCheck from '../utils/legacy-buttons-check';
import { createButtonSnippet } from '../utils/create-button-snippet';
import AuthenticationStore from '../stores/AuthenticationStore';
import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';
import ButtonCreationConstants from '../constants/ButtonCreationConstants';

function getState(formName) {
  const data = FormStore.currentFormState(formName);
  const transactionType = ButtonCreationConstants.DOMAIN_TRANSACTION_TYPE;
  const userID = AuthenticationStore.getCustomerID();
  return {
    buttonText: data.button_text,
    offerButtonText: data.offer_button_text,
    buttonStyle: data.button_style,
    buttonData: {
      type: transactionType,
      non_initiator_email: AuthenticationStore.getEmail(),
      non_initiator_id: userID,
      non_initiator_role: 'seller',
      title: data.title,
      currency: data.currency,
      domain: data.domain,
      price: data.button_types === 'make_offer' ? 0.0 : data.price,
      concierge: data.concierge,
      with_content: data.with_content,
      inspection_period: data.inspection_period,
      fee_payer: data.fee_payer,
      return_url: data.return_url,
      button_types: data.button_types,
      auto_accept: data.auto_accept,
      auto_reject: data.auto_reject,
      item_key: data.item_key,
    },
    buttonBehavior: data.button_behavior,
  };
}

export class ButtonCreation extends SimpleForm {
  constructor(element, options = {}) {
    super(element, options);
    this._onChange = this._onChange.bind(this);
    this.itemKey = uuidv1();
  }

  componentHasMounted() {
    super.componentHasMounted();
    this.buttonTextElement = this.rootNode.querySelector('[name="button_text"]');
    const copyToClipboard = new Clipboard('[data-target="button-copy-code"]');
    copyToClipboard.on('success', this.showCopySuccessMessage);
    const copyOfferToClipboard = new Clipboard('[data-target="offer-button-copy-code"]');
    copyOfferToClipboard.on('success', this.showOfferCopySuccessMessage);
  }

  componentWillDismount() {
    super.componentWillDismount();
  }

  _onChange() {
    const newState = getState(this.formName);
    this.updateButtonStyleText(newState);
    super._onChange(newState);
  }

  updateButtonStyleText(newState) {
    if (this.buttonTextElement && newState.buttonStyle !== this.state.buttonStyle) {
      if (newState.buttonStyle === 'user') {
        this.buttonTextElement.value = ButtonCreationConstants.USER_STYLE_DEFAULT_TEXT;
      } else if (this.buttonTextElement.value === ButtonCreationConstants.USER_STYLE_DEFAULT_TEXT) {
        this.buttonTextElement.value = ButtonCreationConstants.BUTTON_DEFAULT_TEXT;
      }
      setTimeout(() => {
        this.fieldChanged({ target: this.buttonTextElement });
      });
    }
  }

  fieldChanged(event) {
    super.fieldChanged(event);
    if (event.type === 'change' && event.target.getAttribute('name') === 'with_content') {
      const conciergeFalse = document.querySelector('[name="concierge"][value="false"]');
      conciergeFalse.checked = true;
      FormActions.validate(this.formName, 'concierge', 'radio', false);
    }

    if (event.type === 'change' && event.target.getAttribute('name') === 'button_types') {
      const priceField = document.querySelector('input[name="price"]');
      const required = event.target.value !== 'make_offer';
      FormActions.validate(this.formName, 'price', 'number', priceField.value, false, required);
    }
  }

  showCopySuccessMessage() {
    const successMessage = document.querySelector('[data-target="copy-success-message"]');
    successMessage.classList.add('is-visible');

    setTimeout(() => {
      successMessage.classList.remove('is-visible');
    }, 2000);
  }

  showOfferCopySuccessMessage() {
    const successMessage = document.querySelector('[data-target="offer-copy-success-message"]');
    successMessage.classList.add('is-visible');

    setTimeout(() => {
      successMessage.classList.remove('is-visible');
    }, 2000);
  }

  render() {
    const vhtml = super.render();
    setVnodeClasses(vhtml, { 'is-hidden': false });

    const buttonCode = select('textarea[name="button-code"]')(vhtml)[0];
    const buttonPreview = select('[data-target="button-preview"]')(vhtml)[0];
    const buyNowSection = select('[data-target="buy-now-section"]')(vhtml)[0];
    const offerButtonCode = select('textarea[name="offer-button-code"]')(vhtml)[0];
    const offerButtonPreview = select('[data-target="offer-button-preview"]')(vhtml)[0];
    const validButtonAlert = select('[data-target="valid-button-alert"]')(vhtml)[0];
    const copyBtn = select('[data-target="button-copy-code"]')(vhtml)[0];
    const offerCopyBtn = select('[data-target="offer-button-copy-code"]')(vhtml)[0];
    const offerSection = select('[data-target="offer-section"]')(vhtml)[0];

    const buttonValid = this.state.invalidFieldCount === 0;
    setVnodeClasses(validButtonAlert, { 'is-hidden': buttonValid });

    const buttonDict = createButtonSnippet(
      this.state.buttonBehavior,
      this.state.buttonData,
      this.state.buttonStyle,
      this.state.buttonText,
      buttonValid,
      'checkout'
    );

    buttonCode.properties.textContent = buttonDict.buttonSnippetCode;
    buttonCode.properties.disabled = !buttonValid;
    buttonPreview.properties.innerHTML = buttonDict.buttonPreviewCode;

    const offerDict = createButtonSnippet(
      this.state.buttonBehavior,
      this.state.buttonData,
      this.state.buttonStyle,
      this.state.offerButtonText,
      buttonValid,
      'offer',
      this.itemKey
    );

    offerButtonCode.properties.textContent = offerDict.buttonSnippetCode;
    offerButtonCode.properties.disabled = !buttonValid;
    offerButtonPreview.properties.innerHTML = offerDict.buttonPreviewCode;

    copyBtn.properties.disabled = !buttonValid;
    offerCopyBtn.properties.disabled = !buttonValid;

    const conciergeField = select('[data-field="concierge"]')(vhtml)[0];
    if (this.state.buttonData.with_content === 'true') {
      setVnodeClasses(conciergeField, { 'is-hidden': true });
    } else {
      setVnodeClasses(conciergeField, { 'is-hidden': false });
    }
    const autoAcceptField = select('[data-field="auto_accept"]')(vhtml)[0];
    const autoRejectField = select('[data-field="auto_reject"]')(vhtml)[0];
    const itemKeyField = select('[data-field="item_key"]')(vhtml)[0];
    const buttonTextField = select('[data-field="button_text"]')(vhtml)[0];
    const offerButtonTextField = select('[data-field="offer_button_text"]')(vhtml)[0];
    const offerbuttonTextFieldLabel = select('label[for="field-offer_button_text"]')(vhtml)[0];
    const priceField = select('[data-field="price"]')(vhtml)[0];
    const domainPriceGroup = select('[data-group="domainTransaction-title-price"]')(vhtml)[0];

    // Hide offer specific fields
    if (this.state.buttonData.button_types === 'buy_now') {
      setVnodeClasses(autoAcceptField, { 'is-hidden': true });
      setVnodeClasses(autoRejectField, { 'is-hidden': true });
      setVnodeClasses(itemKeyField, { 'is-hidden': true });
      setVnodeClasses(offerButtonTextField, { 'is-hidden': true });
      setVnodeClasses(offerButtonPreview, { 'is-hidden': true });
      setVnodeClasses(offerButtonCode, { 'is-hidden': true });
      setVnodeClasses(offerSection, { 'is-hidden': true });
    } else {
      setVnodeClasses(autoAcceptField, { 'is-hidden': false });
      setVnodeClasses(autoRejectField, { 'is-hidden': false });
      setVnodeClasses(itemKeyField, { 'is-hidden': false });
      setVnodeClasses(offerButtonTextField, { 'is-hidden': false });
      setVnodeClasses(offerButtonPreview, { 'is-hidden': false });
      setVnodeClasses(offerButtonCode, { 'is-hidden': false });
      setVnodeClasses(offerSection, { 'is-hidden': false });
    }

    // Hide BIN-specific fields
    if (this.state.buttonData.button_types === 'make_offer') {
      setVnodeClasses(buttonCode, { 'is-hidden': true });
      setVnodeClasses(buttonPreview, { 'is-hidden': true });
      setVnodeClasses(buttonTextField, { 'is-hidden': true });
      setVnodeClasses(buyNowSection, { 'is-hidden': true });
      setVnodeClasses(priceField, { 'is-hidden': true });
      setVnodeClasses(offerbuttonTextFieldLabel, { 'is-hidden': false });
      setVnodeClasses(domainPriceGroup, { 'defaultForm-group': false });
    } else {
      setVnodeClasses(buttonCode, { 'is-hidden': false });
      setVnodeClasses(buttonPreview, { 'is-hidden': false });
      setVnodeClasses(buttonTextField, { 'is-hidden': false });
      setVnodeClasses(buyNowSection, { 'is-hidden': false });
      setVnodeClasses(priceField, { 'is-hidden': false });
      setVnodeClasses(offerbuttonTextFieldLabel, { 'is-hidden': true });
      setVnodeClasses(domainPriceGroup, { 'defaultForm-group': true });
    }

    return vhtml;
  }
}

for (const elem of document.querySelectorAll('[data-component="buttonCreation"]')) {
  setTimeout(() => {
    const component = new ButtonCreation(elem);
    component.replace(elem, component.initialTemplate);
  });

  legacyButtonsCheck().then(
    () => {
      document.cookie = `user_has_legacy_buttons=0;domain=${window.config.cookie_domain};path=/`;
    },
    () => {
      document.cookie = `user_has_legacy_buttons=1;domain=${window.config.cookie_domain};path=/`;
    }
  );
}
