import AppDispatcher from '../dispatcher/AppDispatcher';
import CalculatorConstants from '../constants/Calculator';
import SearchInputActions from '../actions/SearchInput';

class CalculatorActions {
  input(values) {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.CALCULATOR_VALUE_UPDATED,
      values: values,
    });
  }

  changePaymentMethod(method) {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.PAYMENT_METHOD_CHANGED,
      method: method,
    });
  }

  search(query) {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.CALCULATOR_SEARCH,
      query: query,
    });
    SearchInputActions.fetchSearchResults(query, 'CALC_SEARCH');
  }

  searchSelect(value, text) {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.SEARCH_SELECT,
      value: value,
      text: text,
    });
  }

  searchHover(value, idx) {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.SEARCH_HOVER,
      value: value,
      idx: idx,
    });
  }

  incrementKeystrokes() {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.SEARCH_KEYSTROKE_INC,
    });
  }

  clearKeystrokes() {
    AppDispatcher.handleViewAction({
      actionType: CalculatorConstants.SEARCH_KEYSTROKE_CLEAR,
    });
  }
}

export default new CalculatorActions();
