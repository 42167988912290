import deepcopy from 'clone';
import select from 'vtree-select';
import queryString from 'query-string';

import virtualize from '../utils/virtualize';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import { gettext } from '../utils/filters';

import Component from '../utils/Component';
import VerifyUserStore from '../stores/VerifyUserStore';
import VerifyUserActions from '../actions/VerifyUserActions';
import VerifyUserConstants from '../constants/VerifyUserConstants';
import OlarkActions from '../actions/OlarkActions';
import OlarkStore from '../stores/OlarkStore';
import OlarkConstants from '../constants/OlarkConstants';
import OlarkApi from '../utils/OlarkApi';
import NavigationActions from '../actions/NavigationActions';

function getState() {
  return {
    kycMethod: VerifyUserStore.kycMethod,
    tiersExpanded: VerifyUserStore.getTiersExpanded(),
    olarkOperatorAvailable:
      OlarkApi.isAvailable() &&
      OlarkStore.getCurrentGroup() === OlarkConstants.VT_GROUP &&
      OlarkStore.isOperatorAvailable(),
  };
}

export class VerifySelector extends Component {
  constructor(element) {
    super();
    this._onChange = this._onChange.bind(this);
    this.template = virtualize(element);

    this.addEventListener('click', '[data-verify-select]', this._selectKycMethod.bind(this));

    this.addEventListener('click', '[data-expand]', this._expandTier.bind(this));

    this._onChange();
  }

  componentHasMounted() {
    VerifyUserStore.addChangeListener(this._onChange);
    OlarkStore.addChangeListener(this._onChange);

    const navState = {};
    navState[VerifyUserConstants.NAVIGATION_STATE] = VerifyUserConstants.NAVIGATION_STATE_INITIAL;
    NavigationActions.replaceState(navState, null, null);
    if (queryString.parse(window.location.search).newSubmission === 'true') {
      OlarkActions.notifyKYCSubmission();
    }
  }

  componentWillDismount() {
    VerifyUserStore.removeChangeListener(this._onChange);
    OlarkStore.removeChangeListener(this._onChange);
  }

  _scrollTop() {
    window.scrollTo(0, 0);
  }

  // Select which KYC method (individual or company)
  _selectKycMethod(event, element) {
    event.preventDefault();
    const option = element.getAttribute('data-verify-select');
    VerifyUserActions.selectKYCMethod(option);
    const navState = {};
    navState[VerifyUserConstants.NAVIGATION_STATE] = VerifyUserConstants.NAVIGATION_STATE_FORM;
    NavigationActions.pushState(navState, null, null);
    this._scrollTop();
  }

  _expandTier(event, element) {
    const tier = element.getAttribute('data-expand');
    VerifyUserActions.toggleTier(tier);
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    if (this.state.kycMethod) {
      // Unhide the active form
      const activeForm = select(`[data-form="${this.state.kycMethod}"]`)(vhtml)[0];
      setVnodeClasses(activeForm, { 'account-container--hidden': false });

      // Hide the selection buttons
      const options = select('[data-target="verify-select"]')(vhtml)[0];
      setVnodeClasses(options, { 'account-container--hidden': true });
    }

    for (const tier of Object.keys(this.state.tiersExpanded)) {
      const element = select(`[data-expand=${tier}]`)(vhtml);
      if (element) {
        setVnodeClasses(element[0], { 'is-expanded': this.state.tiersExpanded[tier] });
      }
    }

    if (this.state.olarkOperatorAvailable) {
      const tierDescriptions = select('[data-verify-pending-text="true"]')(vhtml);
      for (const description of tierDescriptions || []) {
        description.children = virtualize.fromHTML(
          gettext(`
          <span>Thanks, your submission is being processed. While it may take up to 24h to review your submission,
          most submissions take only a few minutes to review. You can wait on this page and one of our agents will
          contact you via chat if there any issues with your submission.<br/><br/>
          We will also notify you via email when your submission is processed. If you need to correct any details, you can do so</span>
        `)
        ).children;
      }
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="verifySelector"]')) {
    const component = new VerifySelector(elem);
    component.replace(elem, component.initialTemplate);
  }
});
