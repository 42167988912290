var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/user.html"] = require( "icons/user.html" );
dependencies["icons/logout.html"] = require( "icons/logout.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/navigation/loggedIn.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/user.html", false, "escrow_www/templates/navigation/loggedIn.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("user")) {
var t_4 = t_1.user;
} else {
cb(new Error("cannot import 'user'")); return;
}
context.setVariable("userIcon", t_4);
env.getTemplate("icons/logout.html", false, "escrow_www/templates/navigation/loggedIn.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("logout")) {
var t_8 = t_5.logout;
} else {
cb(new Error("cannot import 'logout'")); return;
}
context.setVariable("logoutIcon", t_8);
output += "<div class=\"loggedIn ";
if(!runtime.contextOrFrameLookup(context, frame, "isLoggedIn")) {
output += "is-hidden";
;
}
output += "\" data-component=\"userHeader-loggedIn\">\n    <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "myTransactionsPage"), env.opts.autoescape);
output += "\" class=\"loggedIn-userEmail\">";
output += runtime.suppressValue((lineno = 4, colno = 69, runtime.callWrap(t_4, "userIcon", context, [])), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "email"), env.opts.autoescape);
output += "</a>\n    <a href=\"#\" class=\"loggedIn-logout\" data-target=\"logout\">";
output += runtime.suppressValue((lineno = 5, colno = 72, runtime.callWrap(t_8, "logoutIcon", context, [])), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_9 = "";t_9 += "Log out";
cb(null, t_9);
;
return t_9;
}
), true && env.opts.autoescape);
output += "</a>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/navigation/loggedIn.html"] , dependencies)