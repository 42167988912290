var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/creditCardLogo.html"] = require( "components/creditCardLogo.html" );
dependencies["components/buttons.html"] = require( "components/buttons.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/forms/account-info/removeCreditCard.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/creditCardLogo.html", false, "escrow_www/templates/forms/account-info/removeCreditCard.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("creditCardLogo", t_1);
env.getTemplate("components/buttons.html", false, "escrow_www/templates/forms/account-info/removeCreditCard.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("button", t_4);
output += "<div data-component=\"remove-card-confirmation\" data-target=\"modal-remove-credit-card\">\n  <h3>";
output += runtime.suppressValue((lineno = 4, colno = 14, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Remove this payment method?"])), env.opts.autoescape);
output += "</h3>\n  <div class=\"accountInfo-paymentMethod-note\">";
output += runtime.suppressValue((lineno = 5, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Removing this card means you won't be able to use it for your future transactions without adding it again."])), env.opts.autoescape);
output += "</div>\n  ";
if(runtime.contextOrFrameLookup(context, frame, "credit_card")) {
output += "\n    <div class=\"accountInfo-paymentMethod\">\n      ";
output += runtime.suppressValue((lineno = 8, colno = 29, runtime.callWrap(runtime.memberLookup((t_1),"default"), "creditCardLogo[\"default\"]", context, [runtime.makeKeywordArgs({"type": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "credit_card")),"logoType"),"cardNumber": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "credit_card")),"last_four_digits")})])), env.opts.autoescape);
output += "\n    </div>\n  ";
;
}
output += "\n  <form novalidate class=\"accountInfo-paymentMethod-footer\">\n    ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
output += "\n      <div class=\"defaultForm-errors\" data-target=\"form-error\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n    ";
output += runtime.suppressValue((lineno = 18, colno = 21, runtime.callWrap(runtime.memberLookup((t_4),"secondary"), "button[\"secondary\"]", context, [(lineno = 18, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Remove"])),runtime.makeKeywordArgs({"specifier": "accountForm-btn","attrs": {"data-target": "form-submit"},"type": "submit"})])), env.opts.autoescape);
output += "\n    ";
output += runtime.suppressValue((lineno = 19, colno = 17, runtime.callWrap(runtime.memberLookup((t_4),"clear"), "button[\"clear\"]", context, [(lineno = 19, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Cancel"])),runtime.makeKeywordArgs({"specifier": "accountForm-btn accountForm-btn--cancel","attrs": {"data-target": "modal-close"},"type": "button"})])), env.opts.autoescape);
output += "\n  </form>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/forms/account-info/removeCreditCard.html"] , dependencies)