import deepcopy from 'clone';
import select from 'vtree-select';
import VText from 'virtual-dom/vnode/vtext';

import { Alpha2VatCountries } from 'spa/constants/ISOCountryCodes';
import API from '../api/API';
import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';
import Component from '../utils/Component';
import virtualize from '../utils/virtualize';
import { updateIcon } from '../utils/select';
import { lookupCountry } from '../utils/DataMapping';
import { setVnodeClasses } from '../utils/set-vnode-classes';

function getState(parentFormName) {
  const parentFormState = FormStore.currentFormState(parentFormName);
  const userCountry = lookupCountry(parentFormState.Country);
  const userCompanyCountry = lookupCountry(parentFormState.CompanyAddressCountry);
  return {
    parentFormState,
    inVatCountry:
      Alpha2VatCountries.includes(userCountry.alpha2) ||
      Alpha2VatCountries.includes(userCompanyCountry.alpha2),
    parentFormValidationState: FormStore.validFields(parentFormName),
  };
}

export class VatDetails extends Component {
  constructor(element, props = {}) {
    super();
    this.element = element;
    this.template = virtualize(element);
    this.props = props;

    this.addEventListener(
      'click',
      '[data-target="verify-vat-number"]',
      this.handleVatValidate.bind(this)
    );

    this.addEventListener(
      'change',
      '[data-field="vatNumber"] [name="vatNumber"]',
      this.handleVatNumberChange.bind(this)
    );

    this._onChange = this._onChange.bind(this);
    this._onChange();

    this.setState({
      ...getState(this.props.parentFormName),
      validated: false,
    });
  }

  _onChange() {
    this.setState(getState(this.props.parentFormName));
    this.initializeVatCountry();
    this.clearFieldValidationStateIfHidden();
  }

  componentHasMounted() {
    FormStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this._onChange);
  }

  initializeVatCountry() {
    // if the user is in a vat country but the country field for VAT number is not defined
    if (this.state.inVatCountry && !this.state.parentFormState.vatCountry) {
      const vatCountryElem = this.element.querySelector(
        '[data-field="vatCountry"] [name="vatCountry"]'
      );
      const userCountry = lookupCountry(this.state.parentFormState.Country);
      const userCompanyCountry = lookupCountry(this.state.parentFormState.CompanyAddressCountry);
      let vatCountry;
      if (Alpha2VatCountries.includes(userCountry.alpha2)) {
        vatCountry = userCountry;
      } else if (Alpha2VatCountries.includes(userCompanyCountry.alpha2)) {
        vatCountry = userCompanyCountry;
      }

      if (vatCountry) {
        vatCountryElem.value = vatCountry.alpha3;
        updateIcon(vatCountryElem);
        setTimeout(() => {
          FormActions.validate(
            this.props.parentFormName,
            vatCountryElem.name,
            vatCountryElem.type,
            vatCountryElem.value
          );
        });
      }
    }
  }

  clearFieldValidationStateIfHidden() {
    if (!this.state.inVatCountry && this.state.parentFormValidationState.vatNumber === false) {
      setTimeout(() => {
        FormActions.clearValidation(this.props.parentFormName, 'vatNumber');
      });
    }
  }

  handleVatNumberChange() {
    if (this.state.validated) {
      this.setState({
        ...getState(this.props.parentFormName),
        validated: false,
      });
    }
  }

  async handleVatValidate() {
    let countryCode = lookupCountry(this.state.parentFormState.vatCountry).alpha2;
    const vatNumber = this.state.parentFormState.vatNumber;
    if (countryCode === 'GR') {
      countryCode = 'EL';
    }

    this.setState({
      ...getState(this.props.parentFormName),
      isValidating: true,
    });

    try {
      // validate-only post
      await API.postTaxNumber('vat', `${countryCode}${vatNumber}`, true);
      FormActions.setFieldValidationState(this.props.parentFormName, 'vatCountry', true);
      FormActions.setFieldValidationState(this.props.parentFormName, 'vatNumber', true);
      FormActions.showValidationStates(this.props.parentFormName, ['vatCountry', 'vatNumber']);
      this.setState({
        ...getState(this.props.parentFormName),
        validated: true,
        isValid: true,
      });
    } catch (error) {
      FormActions.setFieldValidationState(this.props.parentFormName, 'vatNumber', false);
      FormActions.showValidationStates(this.props.parentFormName, ['vatNumber']);
      this.setState({
        ...getState(this.props.parentFormName),
        validated: true,
        isValid: false,
      });
    } finally {
      this.setState({
        ...getState(this.props.parentFormName),
        isValidating: false,
      });
    }
  }

  async submitVatNumber() {
    let countryCode = lookupCountry(this.state.parentFormState.vatCountry).alpha2;
    const vatNumber = this.state.parentFormState.vatNumber;
    if (countryCode === 'GR') {
      countryCode = 'EL';
    }

    await API.postTaxNumber('vat', `${countryCode}${vatNumber}`);
  }

  isValidationDone() {
    return this.state.validated;
  }

  isVatNumberValid() {
    return this.state.isValid;
  }

  isVatEmpty() {
    return !this.state.parentFormState.vatNumber;
  }

  userInVatCountry() {
    return this.state.inVatCountry;
  }

  render() {
    const vhtml = deepcopy(this.template);

    const verifyVatNumberButton = (select('[data-target="verify-vat-number"]')(vhtml) || [])[0];
    const vatSection = (select('[data-component="vat-details"]')(vhtml) || [])[0];

    if (vatSection) {
      setVnodeClasses(vatSection, { 'is-hidden': !this.state.inVatCountry });
    }

    if (verifyVatNumberButton) {
      if (this.state.isValidating) {
        setVnodeClasses(verifyVatNumberButton, { 'is-loading': true });
      }
      if (!this.state.isValid) {
        const successIcon = (select('[class="btn-icon"]')(verifyVatNumberButton) || [])[0];
        setVnodeClasses(successIcon, { 'is-hidden': true });
      } else if (this.state.validated && this.state.isValid) {
        const textIndex = verifyVatNumberButton.children.findIndex(
          (child) => child instanceof VText
        );
        verifyVatNumberButton.children[textIndex] = new VText('');

        // disable all vat fields
        verifyVatNumberButton.properties.disabled = true;
        const vatNumberInput = (select('[data-field="vatNumber"] [name="vatNumber"]')(vhtml) ||
          [])[0];
        vatNumberInput.properties.disabled = true;
        // disabling the country dropdown requires two html elements to have the disabled property
        const countryWrapper = (select('[data-field="vatCountry"] [data-target="select-wrapper"]')(
          vhtml
        ) || [])[0];
        const countryField = (select('[data-field="vatCountry"] [name="vatCountry"]')(vhtml) ||
          [])[0];
        setVnodeClasses(countryWrapper, { 'is-disabled': true });
        countryField.properties.disabled = true;
      }
    }

    const selectedCountryCode = this.state.parentFormState.vatCountry;
    const selectedOption = (select(
      `[data-field="vatCountry"] option[value="${selectedCountryCode}"]`
    )(vhtml) || [])[0];
    if (selectedOption) {
      selectedOption.children = [
        new VText(selectedOption.children[0].text.replace(/.*\((.+)\).*/, '$1')),
      ];
    }
    return vhtml;
  }
}
