import AppDispatcher from '../dispatcher/AppDispatcher';
import APIKeyConstants from '../constants/APIKeyConstants';
import { ChangeEmitter } from '../utils/ChangeEmitter';

export class APIKeyStore extends ChangeEmitter {
  constructor() {
    super();
    this.handleServerAction = this.handleServerAction.bind(this);
    this.apiKey = null;
    this.submissionError = null;
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === APIKeyConstants.API_KEY_CREATED) {
      this.apiKey = action.key;
      this.submissionError = null;
      this.emitChange();
    } else if (actionType === APIKeyConstants.API_KEY_CREATION_FAILED) {
      this.apiKey = null;
      this.submissionError = action.error;
      this.emitChange();
    }
  }
}

const apiKeyStore = new APIKeyStore();
apiKeyStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'SERVER_ACTION') {
    apiKeyStore.handleServerAction(action);
  }
});

export default apiKeyStore;
