import request from 'browser-request';
import url from 'url';
import AppDispatcher from '../dispatcher/AppDispatcher';
import TimelineConstants from '../constants/TimelineConstants';

export class TimelineAction {
  fetchNewsItems(from, to) {
    request.get(
      {
        url: url.format({
          pathname: '/api/news',
          query: {
            fromPage: from,
            toPage: to,
          },
        }),
        json: true,
      },
      (error, response, body) => {
        if (!error && response.status === 200) {
          AppDispatcher.handleServerAction({
            actionType: TimelineConstants.NEWS_ITEMS,
            result: body.result,
            pageCount: body.pageCount,
          });
        } else if (response.statusCode === 422) {
          AppDispatcher.handleServerAction({
            actionType: TimelineConstants.ITEMS_ENDS,
          });
        }
      }
    );
  }
}

export default new TimelineAction();
