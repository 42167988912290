var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/timeline.html"] = require( "components/timeline.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/sections/news.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/timeline.html", false, "escrow_www/templates/sections/news.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("timeline", t_1);
output += "<div class=\"timeline timeline--join\" data-component=\"timeline\">\n  ";
frame = frame.push();
var t_6 = runtime.contextOrFrameLookup(context, frame, "newsItems");
if(t_6) {var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("item", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n  ";
if(runtime.memberLookup((t_7),"type") == "news") {
output += "\n    ";
output += runtime.suppressValue((lineno = 5, colno = 18, runtime.callWrap(runtime.memberLookup((t_1),"item"), "timeline[\"item\"]", context, [runtime.memberLookup((t_7),"date"),runtime.memberLookup((t_7),"title"),runtime.memberLookup((t_7),"rubric"),runtime.memberLookup((t_7),"route")])), env.opts.autoescape);
output += "\n  ";
;
}
else {
output += "\n    ";
output += runtime.suppressValue((lineno = 7, colno = 18, runtime.callWrap(runtime.memberLookup((t_1),"event"), "timeline[\"event\"]", context, [runtime.memberLookup((t_7),"year")])), env.opts.autoescape);
output += "\n  ";
;
}
output += "\n  ";
;
}
}
frame = frame.pop();
output += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "showLoadBtn")) {
output += "\n    ";
output += runtime.suppressValue((lineno = 11, colno = 18, runtime.callWrap(runtime.memberLookup((t_1),"more"), "timeline[\"more\"]", context, [])), env.opts.autoescape);
output += "\n  ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/sections/news.html"] , dependencies)