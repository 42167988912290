var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/logout.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg version=\"1.1\" class=\"icon icon--logout\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 20 20\" enable-background=\"new 0 0 20 20\" xml:space=\"preserve\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0,17.2L0,2.8c0-0.7,0.6-1.2,1.2-1.2h11.3c0.7,0,1.2,0.6,1.2,1.2v3.4\n\tc0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9V3.4h-10v13.1h10v-2.8c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v3.4\n\tc0,0.7-0.6,1.2-1.2,1.2H1.2C0.6,18.4,0,17.9,0,17.2z M15,13.1c0-0.3,0.1-0.5,0.3-0.7l1.5-1.5H6.6c-0.5,0-0.9-0.4-0.9-0.9\n\tc0-0.5,0.4-0.9,0.9-0.9h10.2l-1.5-1.5C15.1,7.4,15,7.1,15,6.9c0-0.5,0.4-0.9,0.9-0.9c0.3,0,0.5,0.1,0.7,0.3l3.1,3.1\n\tC19.9,9.5,20,9.7,20,10c0,0.3-0.1,0.5-0.3,0.7l-3.1,3.1c-0.2,0.2-0.4,0.3-0.7,0.3C15.4,14.1,15,13.6,15,13.1z\"/>\n</svg>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("logout");
context.setVariable("logout", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/logout.html"] , dependencies)