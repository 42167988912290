import deepcopy from 'clone';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import CreditCardStore from '../stores/CreditCardStore';

export class CreditCardSelector extends Component {
  constructor(element) {
    super();
    this.template = virtualize(element);

    this.addEventListener(
      'click',
      '[data-component="credit-card-selector"]',
      this._selectCreditCard.bind(this)
    );
  }

  _selectCreditCard(event) {
    const creditCard = JSON.parse(event.target.dataset.creditCard);
    CreditCardStore.setSelected(creditCard);
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="credit-card-selector"]')) {
    const component = new CreditCardSelector(elem);
    component.replace(elem, component.initialTemplate);
  }
});
