import select from 'vtree-select';
import window from 'window-shim';

import { SimpleForm } from '../utils/SimpleForm';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import StartTransactionActions from '../actions/StartTransactionActions';
import AuthenticationActions from '../actions/AuthenticationActions';
import FormActions from '../actions/FormActions';
import StartTransactionConstants from '../constants/StartTransactionConstants';
import StartTransactionStore from '../stores/StartTransactionStore';
import FormStore from '../stores/FormStore';

const sectionFields = {
  buyer: [
    'buyer_email_address',
    'buyer_password',
    'buyer_phone',
    'buyer_first_name',
    'buyer_last_name',
  ],
  seller: [
    'seller_email_address',
    'seller_password',
    'seller_phone',
    'seller_first_name',
    'seller_last_name',
  ],
  domain: [
    'transaction_title',
    'currency',
    'domain',
    'price',
    'fee_payer',
    'concierge',
    'with_content',
    'inspection_period',
  ],
};

function getState(name) {
  const multiStepProgress = { ...StartTransactionStore.multiStepProgress };
  const validFields = FormStore.validFields(name);

  let invalidFieldCount = 0;

  let activeFields = [];

  if (!multiStepProgress.buyerAuthenticated && !multiStepProgress.sellerAuthenticated) {
    activeFields = sectionFields.buyer;
  } else if (multiStepProgress.buyerAuthenticated && !multiStepProgress.sellerAuthenticated) {
    activeFields = sectionFields.seller;
  } else {
    activeFields = sectionFields.domain;
  }

  for (const field of activeFields) {
    invalidFieldCount += !validFields[field];
  }

  return {
    multiStepProgress,
    invalidFieldCount,
  };
}

export class NamesconStartTransaction extends SimpleForm {
  constructor(element, options = {}) {
    super(element, options);
    this.addEventListener('submit', 'form[data-form-section]', this.onContinue.bind(this));
  }

  _onChange() {
    super._onChange(getState(this.formName));
  }

  componentHasMounted() {
    super.componentHasMounted();
    StartTransactionStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    super.componentWillDismount();
    StartTransactionStore.removeChangeListener(this._onChange);
  }

  fieldBlur(event) {
    super.fieldBlur(event);
    if (event.target.type === 'email') {
      AuthenticationActions.userExists(event.target.value, this.formName);
    }
  }

  onContinue(event) {
    event.preventDefault();

    if (this.state.invalidFieldCount !== 0) {
      FormActions.showError(this.formName);
      return false;
    }

    const formSection = event.target.getAttribute('data-form-section');
    const form = this.state.formState;

    const buyerDetails = {
      email: form.buyer_email_address,
      password: form.buyer_password,
      phone: form.buyer_phone,
      first_name: form.buyer_first_name,
      last_name: form.buyer_last_name,
    };

    const sellerDetails = {
      email: form.seller_email_address,
      password: form.seller_password,
      phone: form.seller_phone,
      first_name: form.seller_first_name,
      last_name: form.seller_last_name,
    };

    const formData = {
      Buyer: {
        Email: form.buyer_email_address,
        Initiator: false,
        CompanyChk: false,
        AutoAgree: false,
        AgreementChecked: false,
      },
      Seller: {
        Email: form.seller_email_address,
        Initiator: true,
        CompanyChk: false,
        AutoAgree: false,
        AgreementChecked: false,
      },
      Partner: { ParnterId: '0' },
      LineItems: [
        {
          ItemName: form.domain,
          Description: form.domain,
          Quantity: '1',
          Price: form.price,
          Accept: true,
          SellComm: '0',
          BuyComm: '0',
        },
      ],
      EscrowPayment: '0',
      InspectionLength: form.inspection_period,
      Currency: form.currency,
      CommissionType: '1',
      TermsLocked: false,
      AllowReject: true,
      Source: StartTransactionConstants.WWW_SOURCE_TRACKING,
      Title: form.transaction_title,
      TransactionType: StartTransactionConstants.DOMAIN_TRANSACTION_TYPE,
      Description: form.domain,
      Fulfillment: StartTransactionConstants.SHIPPING_METHOD_DOMAIN,
      ShipmentFee: '0',
      ShipmentPayment: '0',
      Concierge: form.concierge,
      DomainNameType: form.with_content,
    };

    if (form.fee_payer === 'buyer') {
      formData.EscrowPayment = '0';
    } else if (form.fee_payer === 'seller') {
      formData.EscrowPayment = '1';
    } else {
      formData.EscrowPayment = '2';
    }

    if (formSection === 'buyer-details') {
      StartTransactionActions.buyerAuthenticate(buyerDetails, this.formName);
    } else if (formSection === 'seller-details') {
      StartTransactionActions.sellerAuthenticate(sellerDetails, this.formName);
    } else {
      StartTransactionActions.submitNamesconStartTransaction(formData, buyerDetails, this.formName);
    }
  }

  render() {
    const vhtml = super.render();

    const multiStepProgress = this.state.multiStepProgress;
    const stepsHeader = select('[data-target="steps-header"]')(vhtml);
    const buyerDetails = select('[data-form-section="buyer-details"]')(vhtml);
    const sellerDetails = select('[data-form-section="seller-details"]')(vhtml);
    const domainDetails = select('[data-form-section="domain-details"]')(vhtml);
    const currencyPrefix = select('[data-placeholder="currency-placeholder"]')(vhtml);

    let currentStep;
    if (!multiStepProgress.buyerAuthenticated) {
      currentStep = '1';
    } else if (!multiStepProgress.sellerAuthenticated) {
      currentStep = '2';
    } else {
      currentStep = '3';
    }

    if (currencyPrefix) {
      const currency = window.config.currencies[this.state.formState.currency];
      currencyPrefix[0].properties.placeholder = currency ? currency.symbol : '$';
    }

    setVnodeClasses(buyerDetails[0], {
      'is-hidden': currentStep !== '1',
    });

    setVnodeClasses(sellerDetails[0], {
      'is-hidden': currentStep !== '2',
    });

    setVnodeClasses(domainDetails[0], {
      'is-hidden': currentStep !== '3',
    });

    stepsHeader[0].children[0].text = `Start Your NamesCon Transaction - Step ${currentStep} of 3`;

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="namescon-start-transaction"]')) {
    const component = new NamesconStartTransaction(elem);
    component.replace(elem, component.initialTemplate);
  }
});
