import AppDispatcher from '../dispatcher/AppDispatcher';
import AnnouncementConstants from '../constants/Announcement';

export class AnnouncementActions {
  hide(announcementId, persist) {
    AppDispatcher.handleViewAction({
      actionType: AnnouncementConstants.ANNOUNCEMENT_CLOSE,
      announcementId: announcementId,
      persist: persist,
    });
  }

  expand(announcementId) {
    AppDispatcher.handleViewAction({
      actionType: AnnouncementConstants.ANNOUNCEMENT_EXPAND,
      announcementId: announcementId,
    });
  }
}

export default new AnnouncementActions();
