import AppDispatcher from '../dispatcher/AppDispatcher';
import CategorySearchConstants from '../constants/CategorySearchConstants';

export class CategorySearchActions {
  selectCategory(value) {
    AppDispatcher.handleViewAction({
      actionType: CategorySearchConstants.SELECT_CATEGORY,
      value: value,
    });
  }

  updateSearchString(value) {
    AppDispatcher.handleViewAction({
      actionType: CategorySearchConstants.SEARCH_FIELD_CHANGED,
      value: value,
    });
  }
}

export default new CategorySearchActions();
