var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/components/creditCardLogo.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["type", "size", "cardNumber", "text"], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", kwargs.hasOwnProperty("type") ? kwargs["type"] : "");
frame.set("size", kwargs.hasOwnProperty("size") ? kwargs["size"] : "");
frame.set("cardNumber", kwargs.hasOwnProperty("cardNumber") ? kwargs["cardNumber"] : "");
frame.set("text", kwargs.hasOwnProperty("text") ? kwargs["text"] : "");
var t_2 = "";t_2 += "<div class=\"creditCardLogo-container\">\n  <span class=\"creditCardLogo creditCardLogo--";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
t_2 += " creditCardLogo--";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
t_2 += "\"></span>\n  ";
if(runtime.contextOrFrameLookup(context, frame, "text")) {
t_2 += "\n  \t<span class=\"creditCardLogo-text\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
t_2 += "</span>\n  ";
;
}
t_2 += "\n  ";
if(runtime.contextOrFrameLookup(context, frame, "cardNumber")) {
t_2 += "\n    <span class=\"creditCardLogo-text\">\n      <span class=\"creditCardLogo-type\">";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "type"), env.opts.autoescape);
t_2 += "</span>\n      <span>\n      \t<img src=\"../../../../build/images/global/truncate.svg\" alt=\"\">\n      </span>\n      <span>";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cardNumber"), env.opts.autoescape);
t_2 += "</span>\n    </span>\n  ";
;
}
t_2 += "\n</div>";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("default");
context.setVariable("default", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/components/creditCardLogo.html"] , dependencies)