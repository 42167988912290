import deepcopy from 'clone';
import APIKeyConfirmationTemplate from 'templates/sections/api_key_confirmation.html';

import virtualize from '../utils/virtualize';
import Component from '../utils/Component';

import APIKeyStore from '../stores/APIKeyStore';

function getState() {
  return {
    apiKey: APIKeyStore.apiKey,
  };
}

export default class APIKeyConfirmation extends Component {
  componentHasMounted() {
    this._onChange = this._onChange.bind(this);
    APIKeyStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    APIKeyStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this._tree);
    if (this.state.apiKey) {
      vhtml.children = virtualize.fromHTML(
        APIKeyConfirmationTemplate.render({
          api_key_name: this.state.apiKey.name,
          api_key_value: this.state.apiKey.key,
        })
      ).children;
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="api-key-confirmation"]')) {
    new APIKeyConfirmation().replace(e);
  }
});
