var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/inputs.html"] = require( "components/inputs.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/trading-name-selectbox.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/inputs.html", false, "escrow_www/templates/js/trading-name-selectbox.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("input", t_1);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"CompanyName")) {
output += "\n  ";
var t_4;
t_4 = (lineno = 3, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Company Trading Name (" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"CompanyName") + ")"]));
frame.set("companyText", t_4, true);
if(frame.topLevel) {
context.setVariable("companyText", t_4);
}
if(frame.topLevel) {
context.addExport("companyText", t_4);
}
output += "\n";
;
}
else {
output += "\n  ";
var t_5;
t_5 = (lineno = 5, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Company Trading Name"]));
frame.set("companyText", t_5, true);
if(frame.topLevel) {
context.setVariable("companyText", t_5);
}
if(frame.topLevel) {
context.addExport("companyText", t_5);
}
output += "\n";
;
}
output += "\n\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"FullName")) {
output += "\n  ";
var t_6;
t_6 = (lineno = 9, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Representative Name (" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"FullName") + ")"]));
frame.set("representativeText", t_6, true);
if(frame.topLevel) {
context.setVariable("representativeText", t_6);
}
if(frame.topLevel) {
context.addExport("representativeText", t_6);
}
output += "\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"ab_test_username")) {
output += "\n    ";
var t_7;
t_7 = (lineno = 11, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Personal Name (" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"FullName") + ")"]));
frame.set("representativeText", t_7, true);
if(frame.topLevel) {
context.setVariable("representativeText", t_7);
}
if(frame.topLevel) {
context.addExport("representativeText", t_7);
}
output += "\n  ";
;
}
output += "\n";
;
}
else {
output += "\n  ";
var t_8;
t_8 = (lineno = 14, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Representative Name"]));
frame.set("representativeText", t_8, true);
if(frame.topLevel) {
context.setVariable("representativeText", t_8);
}
if(frame.topLevel) {
context.addExport("representativeText", t_8);
}
output += "\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"ab_test_username")) {
output += "\n    ";
var t_9;
t_9 = (lineno = 16, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Personal Name"]));
frame.set("representativeText", t_9, true);
if(frame.topLevel) {
context.setVariable("representativeText", t_9);
}
if(frame.topLevel) {
context.addExport("representativeText", t_9);
}
output += "\n  ";
;
}
output += "\n";
;
}
output += "\n\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"Username")) {
output += "\n  ";
var t_10;
t_10 = (lineno = 21, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Custom Display Name (" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"Username") + ")"]));
frame.set("usernameText", t_10, true);
if(frame.topLevel) {
context.setVariable("usernameText", t_10);
}
if(frame.topLevel) {
context.addExport("usernameText", t_10);
}
output += "\n";
;
}
else {
output += "\n  ";
var t_11;
t_11 = (lineno = 23, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Custom Display Name (Select to set one now)"]));
frame.set("usernameText", t_11, true);
if(frame.topLevel) {
context.setVariable("usernameText", t_11);
}
if(frame.topLevel) {
context.addExport("usernameText", t_11);
}
output += "\n";
;
}
output += "\n\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"DisplayNameType") == "name") {
output += "\n  ";
var t_12;
t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"FullName");
frame.set("display_name", t_12, true);
if(frame.topLevel) {
context.setVariable("display_name", t_12);
}
if(frame.topLevel) {
context.addExport("display_name", t_12);
}
output += "\n";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"DisplayNameType") == "company") {
output += "\n  ";
var t_13;
t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"CompanyName");
frame.set("display_name", t_13, true);
if(frame.topLevel) {
context.setVariable("display_name", t_13);
}
if(frame.topLevel) {
context.addExport("display_name", t_13);
}
output += "\n";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"DisplayNameType") == "username") {
output += "\n  ";
var t_14;
t_14 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"Username");
frame.set("display_name", t_14, true);
if(frame.topLevel) {
context.setVariable("display_name", t_14);
}
if(frame.topLevel) {
context.addExport("display_name", t_14);
}
output += "\n";
;
}
;
}
;
}
output += "\n\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"ab_test_username")) {
output += "\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"CompanyForm") > -1) {
output += "\n    ";
var t_15;
t_15 = [{"name": runtime.contextOrFrameLookup(context, frame, "companyText"),"value": "company"},{"name": runtime.contextOrFrameLookup(context, frame, "representativeText"),"value": "name"},{"name": runtime.contextOrFrameLookup(context, frame, "usernameText"),"value": "username"}];
frame.set("options", t_15, true);
if(frame.topLevel) {
context.setVariable("options", t_15);
}
if(frame.topLevel) {
context.addExport("options", t_15);
}
output += "\n  ";
;
}
else {
output += "\n    ";
var t_16;
t_16 = [{"name": runtime.contextOrFrameLookup(context, frame, "representativeText"),"value": "name"},{"name": runtime.contextOrFrameLookup(context, frame, "usernameText"),"value": "username"}];
frame.set("options", t_16, true);
if(frame.topLevel) {
context.setVariable("options", t_16);
}
if(frame.topLevel) {
context.addExport("options", t_16);
}
output += "\n  ";
;
}
output += "\n";
;
}
else {
output += "\n    ";
var t_17;
t_17 = [{"name": runtime.contextOrFrameLookup(context, frame, "companyText"),"value": "company"},{"name": runtime.contextOrFrameLookup(context, frame, "representativeText"),"value": "name"}];
frame.set("options", t_17, true);
if(frame.topLevel) {
context.setVariable("options", t_17);
}
if(frame.topLevel) {
context.addExport("options", t_17);
}
output += "\n";
;
}
output += "\n\n<div>\n";
output += runtime.suppressValue((lineno = 55, colno = 17, runtime.callWrap(runtime.memberLookup((t_1),"formSelect"), "input[\"formSelect\"]", context, [(lineno = 55, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Display Name Type"])),"DisplayNameType",runtime.makeKeywordArgs({"required": "true","selected": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"DisplayNameType"),"options": runtime.contextOrFrameLookup(context, frame, "options"),"attrs": {"data-e2e-target": "company-role-dropdown","data-dynamic-select": "trading-name-select"}})])), env.opts.autoescape);
output += "\n\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customer")),"ab_test_username")) {
output += "\n    ";
output += runtime.suppressValue((lineno = 63, colno = 15, runtime.callWrap(runtime.memberLookup((t_1),"text"), "input[\"text\"]", context, [(lineno = 63, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Set custom display name"])),runtime.makeKeywordArgs({"name": "Username","placeholder": (lineno = 64, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Display Name"])),"value": runtime.contextOrFrameLookup(context, frame, "username"),"required": "true","attrs": {"data-max-length": "150","data-e2e-target": "username-field"}})])), env.opts.autoescape);
output += "\n  ";
;
}
output += "\n\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/trading-name-selectbox.html"] , dependencies)