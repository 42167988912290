import QueryString from 'query-string';

import ModalActions from '../actions/ModalActions';
import NavigationActions from '../actions/NavigationActions';

require('../common');
require('../footer');
require('../search');
require('../components');
require('../stores/ABTestStore.js');

setTimeout(() => {
  // returnTo query param for email links
  const query = QueryString.parse(window.location.search);
  if (query.loginLocation) {
    NavigationActions.setLoginLocation(query.loginLocation);
  }

  // If the hash starts with 'modal-', redirect to login or signup page
  // Need to keep functionality because #modal- is linked in emails
  if (window.location.hash.substring(1).startsWith('modal-')) {
    const modal = window.location.hash.substring(1).replace('modal-', '');
    if (modal === 'signup' || modal === 'login') {
      if (document.referrer) {
        query.referrerOverride = document.referrer;
      } else {
        query.referrerOverride = 'none';
      }
      window.location = `${modal}-page?${QueryString.stringify(query)}`;
    } else {
      ModalActions.open(window.location.hash.substring(1));
    }
  }
});
