var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/buttons.html"] = require( "components/buttons.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/sections/api_key_confirmation.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/buttons.html", false, "escrow_www/templates/sections/api_key_confirmation.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("button", t_1);
output += "<div data-component=\"api-key-confirmation\">\n  <h3 class=\"apiDashboard-modal-title\">Name: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "api_key_name"), env.opts.autoescape);
output += "</h3>\n  <p class=\"apiDashboard-modal-key\">Key: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "api_key_value"), env.opts.autoescape);
output += "</p>\n\n  ";
output += runtime.suppressValue((lineno = 6, colno = 19, runtime.callWrap(runtime.memberLookup((t_1),"secondary"), "button[\"secondary\"]", context, [(lineno = 6, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Done"])),runtime.makeKeywordArgs({"attrs": {"data-target": "window-reload"}})])), env.opts.autoescape);
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/sections/api_key_confirmation.html"] , dependencies)