import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import NavigationActions from '../actions/NavigationActions';
import NavigationStore from '../stores/NavigationStore';
import TimelineActions from '../actions/TimelineActions';
import TimelineConstants from '../constants/TimelineConstants';

export class TimelineStore extends ChangeEmitter {
  constructor() {
    super();
    this.showLoadBtn = true;
    if (typeof window.initialNewsItems === 'undefined') {
      this.newsItems = [];
    } else {
      this.newsItems = window.initialNewsItems;
    }
    this.page = 2;
    const savedPage = NavigationStore.getState().newsPage;
    if (savedPage) {
      TimelineActions.fetchNewsItems(this.page, savedPage - 1);
    }
  }

  handleServerAction(action) {
    if (action.actionType === TimelineConstants.NEWS_ITEMS) {
      this.newsItems = this.newsItems.concat(action.result);
      this.page += action.pageCount;
      setTimeout(() => {
        NavigationActions.pushState(
          {
            newsPage: this.page,
          },
          null,
          null
        );
      });
      this.emitChange();
    } else if (action.actionType === TimelineConstants.ITEMS_ENDS) {
      this.showLoadBtn = false;
      this.emitChange();
    }
  }
}

const timelineStore = new TimelineStore();
timelineStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'SERVER_ACTION') {
    timelineStore.handleServerAction(action);
  }
});

export default timelineStore;
