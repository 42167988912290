import classNames from 'classnames/dedupe';

import ModalStore from '../stores/ModalStore';

export default class GlobalModalManager {
  constructor() {
    this._onChange = this._onChange.bind(this);

    ModalStore.addChangeListener(this._onChange);
  }

  bind(element) {
    this.element = element;
    this._onChange();
  }

  _onChange() {
    if (!this.element) {
      return;
    }

    const classes = this.element.className.split(' ');
    this.element.className = classNames(classes, {
      'has-open-modal': ModalStore.showingModal(),
      'has-open-touchShelf': ModalStore.showingModal() === 'modal-touchShelf',
    });
  }
}

setTimeout(() => {
  const modalManager = new GlobalModalManager();
  modalManager.bind(document.querySelector('html'));
});
