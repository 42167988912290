import deepcopy from 'clone';
import window from 'window-shim';

import Component from '../utils/Component';
import virtualize from '../utils/virtualize';

import FormStore from '../stores/FormStore';

function getState(formName) {
  return {
    currency: FormStore.currentFormState(formName).currency,
  };
}

export class CurrencyPlaceholder extends Component {
  constructor(element, options = {}) {
    super(element, options);
    this.template = virtualize(element);
    this.formName = element.getAttribute('data-form-name');
    this._onChange = this._onChange.bind(this);
    this._onChange();
  }

  componentHasMounted() {
    FormStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    this.setState(getState(this.formName), false);
  }

  render(template = this.template) {
    const vhtml = deepcopy(template, false);
    const currency = window.config.currencies[this.state.currency];
    vhtml.properties.placeholder = currency ? currency.symbol : '$';
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-placeholder="currency-placeholder"]')) {
    const component = new CurrencyPlaceholder(elem);
    component.replace(elem);
  }
});
