import select from 'vtree-select';
import h from 'virtual-dom/h';

import CalculatorStore from '../stores/Calculator';
import CalculatorActions from '../actions/Calculator';
import CalculatorConstants from '../constants/Calculator';
import Calculator from '../components/Calculator';

function getState() {
  return {
    type: CalculatorStore.getType(),
    paymentMethodOptions: CalculatorStore.getPaymentMethodOptions(),
  };
}

export default class ExtendedCalculator extends Calculator {
  constructor(element) {
    super(element);

    this.addEventListener(
      'change',
      'select[data-component="calculator-payment-method"]',
      this.onPaymentMethodChange.bind(this)
    );

    this.addEventListener(
      'change',
      'select[data-component="calculator-transaction-type"]',
      this._onInputChange.bind(this)
    );

    this.setState(getState());

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const vhtml = super.render();

    // Payment method list population
    const methodElements = select('[data-component="calculator-payment-method"]')(vhtml);
    if (methodElements) {
      const methodElem = methodElements[0];
      methodElem.children = [];
      for (const item of this.state.paymentMethodOptions) {
        methodElem.children.push(h('option', { value: item.value, label: item.name }, item.name));
      }
    }

    // Hide Motor Vehicles if Broker selected on Fee Calculator page.
    if (this.state.role === CalculatorConstants.ROLE_BROKER) {
      const typeElem = select('[data-component="calculator-transaction-type"]')(vhtml)[0];
      if (typeElem) {
        const options = select(`[value="${CalculatorConstants.MOTOR_VEHICLE_TRANSACTION_TYPE}"]`)(
          typeElem
        );
        if (options) {
          for (const option of options) {
            typeElem.children.splice(typeElem.children.indexOf(option), 1);
          }
        }
      }
    }
    return vhtml;
  }

  _updateValues() {
    const amount = Number(
      this.rootNode.querySelector('input[data-component="calculator-price"]').value
    );
    const currency = this.rootNode.querySelector(
      'select[data-component="calculator-currency"]'
    ).value;
    const role = this.rootNode.querySelector('select[data-component="calculator-role"]').value;
    const e = this.rootNode.querySelector('select[data-component="calculator-transaction-type"]');
    const type = e.value;
    const description = e.options[e.selectedIndex].text;

    CalculatorActions.input({
      currency,
      amount,
      role,
      type,
      description,
    });

    // Reset type dropdown to first position on role change.
    const typeDropdown = this.rootNode.querySelector(
      '[data-component="calculator-transaction-type"]'
    );
    if (typeDropdown && role !== this.state.role) {
      typeDropdown.selectedIndex = 0;
    }
  }

  onPaymentMethodChange(event) {
    CalculatorActions.changePaymentMethod(event.target.value);
  }

  onChange() {
    super._onChange();
    this.setState(getState());
  }

  componentHasMounted() {
    CalculatorStore.addChangeListener(this.onChange);
    this._updateValues();
  }

  componentWillDismount() {
    CalculatorStore.removeChangeListener(this.onChange);
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="calculator-extended"]')) {
    new ExtendedCalculator(e).replace(e);
  }
});
