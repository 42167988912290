var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/otp-verified.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div>\n  Your phone number ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "phoneNumber"), env.opts.autoescape);
output += " is verified.<br/></br>\n  To complete your verification, please complete the remainder of this form and click\n  ";
if(runtime.contextOrFrameLookup(context, frame, "onAccountInfo")) {
output += "\n    'Save Changes'\n  ";
;
}
else {
output += "\n    'Submit'\n  ";
;
}
output += "\n  below\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/otp-verified.html"] , dependencies)