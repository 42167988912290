var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/buttons.html"] = require( "components/buttons.html" );
dependencies["components/search.html"] = require( "components/search.html" );
dependencies["icons/close.html"] = require( "icons/close.html" );
dependencies["navigation/loggedIn.html"] = require( "navigation/loggedIn.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/sections/touchShelf.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/buttons.html", false, "escrow_www/templates/sections/touchShelf.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("button", t_1);
env.getTemplate("components/search.html", false, "escrow_www/templates/sections/touchShelf.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("search", t_4);
env.getTemplate("icons/close.html", false, "escrow_www/templates/sections/touchShelf.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(context.getVariables(), frame, function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(t_7.hasOwnProperty("close")) {
var t_10 = t_7.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_10);
output += "<div class=\"touchShelf\" data-component=\"touchShelf\" data-target=\"modal-touchShelf\" tabIndex=\"0\">\n  <div class=\"touchShelf-closeContainer\" data-target-modal=\"touchShelf\" data-target-modal-toggle>\n    ";
output += runtime.suppressValue((lineno = 6, colno = 13, runtime.callWrap(t_10, "closeIcon", context, [])), env.opts.autoescape);
output += "\n  </div>\n  <div class=\"touchShelf-container\">\n    <div class=\"touchShelf-containerInner\">\n      <div class=\"touchShelf-user\">\n        <div ";
if(runtime.contextOrFrameLookup(context, frame, "isLoggedIn")) {
output += "class=\"is-hidden\"";
;
}
output += " data-target=\"loggedout-section\">\n          ";
output += runtime.suppressValue((lineno = 12, colno = 25, runtime.callWrap(runtime.memberLookup((t_1),"primary"), "button[\"primary\"]", context, [(lineno = 12, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Register"])),runtime.makeKeywordArgs({"specifier": "touchShelf-signUp","link": (lineno = 12, colno = 90, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".signup_page"])),"attrs": {"data-auth-required": "transaction-choice","data-e2e-target": "touchShelf-register"}})])), env.opts.autoescape);
output += "\n          ";
output += runtime.suppressValue((lineno = 13, colno = 25, runtime.callWrap(runtime.memberLookup((t_1),"primary"), "button[\"primary\"]", context, [(lineno = 13, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Log in"])),runtime.makeKeywordArgs({"specifier": "touchShelf-logIn","link": (lineno = 13, colno = 87, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url_for"), "url_for", context, [".login_page"])),"attrs": {"data-auth-required": "loggedin-landing-page","data-e2e-target": "touchShelf-login"}})])), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"touchShelf-loggedIn ";
if(!runtime.contextOrFrameLookup(context, frame, "isLoggedIn")) {
output += " is-hidden";
;
}
output += "\" data-target=\"loggedin-section\">\n          ";
output += runtime.suppressValue((lineno = 16, colno = 25, runtime.callWrap(runtime.memberLookup((t_1),"primary"), "button[\"primary\"]", context, [(lineno = 16, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["My transactions"])),runtime.makeKeywordArgs({"specifier": "touchShelf-transactions","link": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "config")),"my_transactions_page")})])), env.opts.autoescape);
env.getTemplate("navigation/loggedIn.html", false, "escrow_www/templates/sections/touchShelf.html", null, function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
t_11.render(context.getVariables(), frame, function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
output += t_12
output += "</div>\n      </div>\n        <div class=\"touchShelf-search\" data-target=\"menu-search-container\">\n        ";
output += runtime.suppressValue((lineno = 22, colno = 21, runtime.callWrap(runtime.memberLookup((t_4),"large"), "search[\"large\"]", context, [runtime.makeKeywordArgs({"specifier": "touchShelf-searchForm","name": "touchShelf-search","suggestion_class": "search-suggestion--light","attrs": {"data-target": "searchOverlay-input"},"button_attrs": {"data-target-modal": "searchOverlay","data-e2e-target": "touchShelf-search-btn"},"input_attrs": {"data-e2e-target": "touchShelf-search-input"}})])), env.opts.autoescape);
output += "\n      </div>\n      <nav class=\"touchShelf-nav\" aria-label=\"main navigation\">\n          ";
frame = frame.push();
var t_17 = runtime.contextOrFrameLookup(context, frame, "menu");
if(t_17) {var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("item", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\n              <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_18),"link"), env.opts.autoescape);
output += "\" class=\"touchShelf-link\" data-target=\"menu-";
output += runtime.suppressValue(runtime.memberLookup((t_18),"tracking"), env.opts.autoescape);
output += "\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((t_18),"title"), env.opts.autoescape);
output += "\n                <span class=\"touchShelf-linkRubric\">";
output += runtime.suppressValue(runtime.memberLookup((t_18),"rubric"), env.opts.autoescape);
output += "</span>\n              </a>\n          ";
;
}
}
frame = frame.pop();
output += "\n        </ul>\n      </nav>\n    </div>\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/sections/touchShelf.html"] , dependencies)