import classNames from 'classnames/dedupe';
import deepcopy from 'clone';
import virtualize from '../utils/virtualize';

import Component from '../utils/Component';
import TabActions from '../actions/TabActions';
import TabStore from '../stores/TabStore';

export default class TabSelector extends Component {
  constructor(element, props = {}) {
    super();

    if (!props.tabGroup) {
      throw new Error('You must define the tabGroup for a TabSelector');
    }
    if (!props.tabId) {
      throw new Error('You must define the tabId for a TabSelector');
    }

    this.props = props;
    this.template = virtualize(element);
    this._onChange = this._onChange.bind(this);
    this._onClick = this._onClick.bind(this);

    this.setState(this._getState());
    element.addEventListener('click', this._onClick);
  }

  _onChange() {
    this.setState(this._getState());
  }

  _onClick(event) {
    event.preventDefault();
    const href = event.currentTarget.href;
    TabActions.select(this.props.tabId, this.props.tabGroup, href);
  }

  _getState() {
    let active;
    if (TabStore.getCurrentTab(this.props.tabGroup)) {
      active = TabStore.getCurrentTab(this.props.tabGroup) === this.props.tabId;
    } else if (this.props.default) {
      active = true;
    }

    return {
      isActive: active,
    };
  }

  componentHasMounted() {
    TabStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    TabStore.removeClass(this._onChange);
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    vhtml.properties.className = classNames(vhtml.properties.className, {
      'is-active-tab': this.state.isActive,
      'is-inactive-tab': !this.state.isActive,
    });
    return vhtml;
  }
}

const hash = window.location.hash.substring(1);

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="tabselector"]')) {
    const tabId = e.getAttribute('data-tab-id');
    const tabGroup = e.getAttribute('data-tab-group');
    const _default = e.hasAttribute('data-tab-default');
    const component = new TabSelector(e, { tabId: tabId, tabGroup: tabGroup, default: _default });

    if (e.getAttribute('data-tab-id') === hash) {
      TabActions.select(tabId, tabGroup, null, false);
    }
    component.replace(e);
  }
});
