import window from 'window-shim';
import url from 'url';
import request from 'browser-request';
import { gettext } from '../utils/filters';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ModifyTransactionConstants from '../constants/ModifyTransactionConstants';
import FormConstants from '../constants/FormConstants';

export class ModifyTransactionActions {
  getTransaction(tid, token) {
    request.get(
      {
        url: url.format({
          pathname: `${window.config.transaction_api_endpoint}/public`,
          query: {
            tid: tid,
            token: token,
          },
        }),
      },
      (error, response) => {
        if (!error && response) {
          AppDispatcher.handleServerAction({
            actionType: ModifyTransactionConstants.INITIALISE_VALUES,
            value: JSON.parse(response.body),
          });
        } else {
          AppDispatcher.handleViewAction({
            actionType: FormConstants.SHOW_FORM_ERROR,
            form: ModifyTransactionConstants.FORM_NAME,
            title: gettext('Cannot modify transaction, please try again.'),
          });
        }
      }
    );
  }
}

export default new ModifyTransactionActions();
