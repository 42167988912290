import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import NavigationStore from '../stores/NavigationStore';
import NavigationConstants from '../constants/NavigationConstants';
import TabConstants from '../constants/TabConstants';

export class TabStore extends ChangeEmitter {
  constructor() {
    super();

    this.tabs = Object.assign({}, NavigationStore.getState().tabs);
    this.handleViewAction = this.handleViewAction.bind(this);
  }

  getCurrentTab(tabGroup) {
    return this.tabs[tabGroup];
  }

  handleViewAction(action) {
    if (action.actionType === TabConstants.TAB_SELECT) {
      this.tabs[action.tabGroup] = action.tabId;
      this.emitChange();
    } else if (action.actionType === NavigationConstants.HISTORY_POP) {
      AppDispatcher.waitFor([NavigationStore.dispatchToken]);
      this.tabs = Object.assign({}, NavigationStore.getState().tabs);
      this.emitChange();
    }
  }
}

const tabStore = new TabStore();
tabStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    tabStore.handleViewAction(action);
  }
});

export default tabStore;
