import deepcopy from 'clone';

import NewsTpl from 'templates/sections/news.html';
import virtualize from '../utils/virtualize';
import Component from '../utils/Component';
import TimelineStore from '../stores/TimelineStore';
import TimelineAction from '../actions/TimelineActions';

function getState() {
  return {
    items: TimelineStore.newsItems,
    showLoadBtn: TimelineStore.showLoadBtn,
  };
}

export default class Timeline extends Component {
  constructor(element) {
    super();
    this.template = virtualize(element);
    this.addEventListener('click', '[data-target="load-more-news"]', this._onClick.bind(this));
    this._onChange = this._onChange.bind(this);
  }

  componentHasMounted() {
    TimelineStore.addChangeListener(this._onChange);
  }

  _onClick() {
    TimelineAction.fetchNewsItems(TimelineStore.page, TimelineStore.page);
  }

  _onChange() {
    this.setState(getState());
  }

  processNewsItem(newsItems) {
    const newsItemsCopy = deepcopy(newsItems, false);
    let yearProceeding = new Date(newsItemsCopy[0].date).getFullYear();
    const yearElements = [];
    newsItemsCopy.forEach((element, index) => {
      const yearPreceeding = new Date(element.date).getFullYear();
      if (yearProceeding > yearPreceeding) {
        const yearElement = {};
        yearElement.type = 'year';
        yearElement.year = yearProceeding;
        yearElement.index = index;
        yearElements.push(yearElement);
        yearProceeding = yearPreceeding;
      }
      newsItems[index].type = 'news';
    });

    yearElements.forEach((element, index) => {
      newsItems.splice(index + element.index, 0, element);
    });

    return newsItems;
  }

  render() {
    const html = NewsTpl.render({
      newsItems: this.processNewsItem(deepcopy(TimelineStore.newsItems, false)),
      showLoadBtn: TimelineStore.showLoadBtn,
    });
    return virtualize.fromHTML(html);
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="timeline"]')) {
    const component = new Timeline(e);
    component.replace(e);
  }
});
