import window from 'window-shim';

import FormActions from '../actions/FormActions';
import FormStore from '../stores/FormStore';
import Constants from '../constants/AutocompleteAddressConstants';
import { updateIcon } from '../utils/select';

export class AutocompleteAddress {
  constructor(googleMaps, elem) {
    this.elem = elem;
    this.formName = this.elem.form.getAttribute('data-form-name');
    this.fieldsPrefix = this.elem.getAttribute('data-fields-prefix') || '';

    // This defines the mapping from Googles field naming to our field naming.
    this.fields = {};
    for (const field of Object.keys(Constants.GOOGLE_MAPS_FIELDS)) {
      this.fields[field] = this.elem.querySelector(
        `[name="${this.fieldsPrefix}${Constants.GOOGLE_MAPS_FIELDS[field]}"]`
      );
    }

    // Listen to input from the first address field (Street1)
    const input = this.elem.querySelector(`[name="${this.fieldsPrefix}Street1"]`);

    // 'address' restricts the search to full addresses (not just places).
    this.autocomplete = new googleMaps.places.Autocomplete(input, { types: ['address'] });
    this.autocomplete.addListener('place_changed', this.fillAddress.bind(this));

    // Prevent enter on the google input from causing form submission
    input.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
  }

  fillAddress() {
    const place = this.autocomplete.getPlace();
    let placesState;

    let validAutocompleteResult = !!place.address_components;

    const values = {};
    for (const component of place.address_components || []) {
      const addressType = component.types[0];

      // remember the state component for later
      if (addressType === 'administrative_area_level_1') {
        placesState = component;
      }

      if (this.fields[addressType]) {
        const fieldName = `${this.fieldsPrefix}${Constants.GOOGLE_MAPS_FIELDS[addressType]}`;
        values[fieldName] = `${values[fieldName] || ''} ${component.long_name}`.trim();
        const maxLength = this.fields[addressType].getAttribute('data-max-length');
        if (maxLength && values[fieldName].length > maxLength) {
          validAutocompleteResult = false;
        }
      }
    }

    if (validAutocompleteResult) {
      for (const key of Object.keys(this.fields)) {
        if (this.fields[key]) {
          this.fields[key].value = values[this.fields[key].name];
        }
      }

      // use short_name for the state if the country is US (legacy support)
      if (placesState && this.fields.country.value === 'United States') {
        this.fields.administrative_area_level_1.value = placesState.short_name;
      }

      const countryField = this.elem.querySelector('[data-field-type="country"]');

      if (countryField) {
        countryField.value = countryField.value || '---';
      }
      updateIcon(countryField);

      const fieldNames = [];
      const fieldContents = [];
      const fieldTypes = [];
      const fieldsRequired = [];

      for (const key of Object.keys(this.fields)) {
        const field = this.fields[key];
        if (field) {
          fieldNames.push(field.name);
          fieldContents.push(field.value);
          fieldTypes.push(field.type);
          fieldsRequired.push(field.required);
        }
      }

      FormActions.bulkValidate(
        this.formName,
        fieldNames,
        fieldTypes,
        fieldContents,
        fieldsRequired
      );

      // only show validation state for non-empty fields
      FormActions.showValidationStates(
        this.formName,
        fieldNames.filter((name, i) => fieldContents[i])
      );
    } else {
      // no autocomplete address components found
      const street1 = FormStore.currentFormState(this.formName)[`${this.fieldsPrefix}Street1`];
      const street1Field = this.elem.querySelector(`input[name="${this.fieldsPrefix}Street1"]`);
      if (street1Field) {
        street1Field.value = street1;
      }
    }
  }
}

setTimeout(() => {
  if (window.google && window.google.maps) {
    for (const elem of document.querySelectorAll('[data-component="address-autocomplete"]')) {
      new AutocompleteAddress(window.google.maps, elem); // eslint-disable-line no-new
    }
  }
});
