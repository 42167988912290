import classNames from 'classnames/dedupe';
import select from 'vtree-select';

import TouchShelfTpl from 'templates/sections/touchShelf.html';
import virtualize from '../utils/virtualize';
import AuthenticationActions from '../actions/AuthenticationActions';
import AuthenticationStore from '../stores/AuthenticationStore';
import Modal from './Modal';
import ModalActions from '../actions/ModalActions';
import SearchConstants from '../constants/SearchConstants';
import SearchInput from './SearchInput';

export class TouchShelf extends Modal {
  constructor(element, props = {}) {
    super(element, props);
    this._onAuthChange = this._onAuthChange.bind(this);
    this.setState({
      isAuthenticated: AuthenticationStore.isAuthenticated(),
      email: AuthenticationStore.getEmail(),
    });

    this.addEventListener('click', '[data-target="logout"]', this._onClickLogout.bind(this));

    // This only gets created after our component has mounted.
    this.searchComponent = null;
  }

  _onAuthChange() {
    this.setState({
      isAuthenticated: AuthenticationStore.isAuthenticated(),
      email: AuthenticationStore.getEmail(),
    });
  }

  _onClickLogout() {
    AuthenticationActions.logout();
    ModalActions.close(this.props.name);
  }

  componentHasMounted() {
    super.componentHasMounted();
    AuthenticationStore.addChangeListener(this._onAuthChange);

    const searchInputElement = this.rootNode.querySelector('[data-target="searchOverlay-input"]');
    this.searchComponent = new SearchInput(searchInputElement, {
      category: SearchConstants.CATEGORY_MENU,
    }).replace(searchInputElement);
  }

  componentWillDismount() {
    super.componentWillDismount();
    AuthenticationStore.removeChangeListener(this._onAuthChange);
  }

  render() {
    const html = TouchShelfTpl.render({
      isLoggedIn: this.state.isAuthenticated,
      email: this.state.email,
      myTransactionsPage: window.config.my_transactions_page,
      menu: window.navigation_menu,
      config: window.config,
    });

    const vhtml = virtualize.fromHTML(html);
    vhtml.properties.className = classNames(vhtml.properties.className, {
      'is-visible': !!this.state.show,
    });

    if (this.searchComponent) {
      const searchContainer = select('[data-target="menu-search-container"]')(vhtml)[0];
      searchContainer.children = [this.searchComponent.render()];
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="touchShelf"]')) {
    new TouchShelf(e, {
      name: e.attributes.getNamedItem('data-target').value,
      persistent: false,
    }).replace(e);
  }
});
