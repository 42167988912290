import select from 'vtree-select';
import deepcopy from 'clone';
import classNames from 'classnames/dedupe';

import Component from '../utils/Component';
import virtualize from '../utils/virtualize';
import ModifyTransactionStore from '../stores/ModifyTransactionStore';

function getState() {
  return {
    modified: ModifyTransactionStore.modified(),
  };
}

export default class ModifyTransaction extends Component {
  constructor(element) {
    super();
    this.template = virtualize(element);
    this.setState(getState());
  }

  componentWillMount() {
    ModifyTransactionStore.addChangeListener(this._onChange.bind(this));
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    const showWhenModified = select('[data-target="transaction-modified"]')(vhtml);
    if (showWhenModified) {
      for (const elem of showWhenModified) {
        elem.properties.className = classNames(elem.properties.className, {
          'is-hidden': !this.state.modified,
        });
      }
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="modifyTransaction"]')) {
    const component = new ModifyTransaction(elem);
    component.replace(elem, component.initialTemplate);
  }
});
