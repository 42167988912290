// utility method for removing prefixes from object keys
export function removePrefix(prefix, object) {
  Object.keys(object).map((key) => {
    object[key.replace(prefix, '')] = object[key];
    delete object[key];
  });
  return object;
}

export function addPrefix(prefix, object) {
  Object.keys(object).map((key) => {
    object[`${prefix}${key}`] = object[key];
    delete object[key];
  });
  return object;
}
