import deepcopy from 'clone';
import select from 'vtree-select';
import VText from 'virtual-dom/vnode/vtext';
import { PhoneNumberType } from 'google-libphonenumber';
import { RegionsByCountry, CountryToAlpha2 } from 'escrow-common-js/dist/constants';

import TradingNameSelectBoxTpl from 'templates/js/trading-name-selectbox.html';
import OtpCountdownTpl from 'templates/js/otp-countdown.html';
import OtpFailureTpl from 'templates/js/otp-failure.html';
import OtpVerifedTpl from 'templates/js/otp-verified.html';
import Select from 'templates/js/select.html';
import PhoneVerifyCardSentDescTpl from 'templates/js/phone-verify-card-sent-desc.html';

import { VatDetails as VatDetailsComponent } from './VatDetails';
import { Form } from '../components/Form';
import VerifyUserStore from '../stores/VerifyUserStore';
import VerifyUserActions from '../actions/VerifyUserActions';
import API from '../api/API';
import FormActions from '../actions/FormActions';
import FormStore from '../stores/FormStore';
import AuthenticationStore from '../stores/AuthenticationStore';
import { setVnodeClasses } from '../utils/set-vnode-classes';
import Component from '../utils/Component';
import virtualize from '../utils/virtualize';
import { gettext } from '../utils/filters';
import { formatPhoneNumber, getNumberType } from '../utils/parse-phone';

function getState(name) {
  const formState = FormStore.currentFormState(name);
  const phoneNumber = formatPhoneNumber(formState['Phone-prefix'], formState.Phone);
  const otpState = Object.assign(VerifyUserStore.getOtpState(name, phoneNumber), {
    SMSCode: formState.SMSCode,
  });
  return {
    customerID: AuthenticationStore.getCustomerID(),
    validFields: VerifyUserStore.validFields(name),
    invalidFieldCount: VerifyUserStore.invalidFieldCount(name),
    billingAddressAsShipping:
      VerifyUserStore.companyAddressAsShipping(name) ||
      VerifyUserStore.personalAddressAsShipping(name),
    initialFormState: VerifyUserStore.initialFormState,
    phoneVerificationUseVoice: VerifyUserStore.phoneVerificationUseVoice,
    phoneNumber: phoneNumber,
    onAccountInfo: /account-info/.test(window.location.href),
    otpState: otpState,
    canSMSCodeBeSent: VerifyUserStore.canSMSCodeBeSent(name, phoneNumber),
  };
}

export class AccountInfo extends Form {
  constructor(element, props = {}) {
    super(element, props);
    this._onChange = this._onChange.bind(this);
    this.props = props;

    this.addEventListener('click', '[data-target="send-otp-code"]', this._onSendOtpCode.bind(this));
    this.addEventListener(
      'click',
      '[data-target="send-otp-code-link"]',
      this._onSendOtpCode.bind(this)
    );
    this.addEventListener(
      'click',
      '[data-target="verify-otp-code"]',
      this._onVerifyOtpCode.bind(this)
    );
    this.addEventListener(
      'click',
      '[data-target="toggle-phone-verification-strategy"]',
      this._onTogglePhoneVerificationStrategy.bind(this)
    );
    this.addEventListener('change', '[name="Phone"]', this._onPhoneChange.bind(this));
    this.addEventListener('keydown', '[name="Phone"]', (event) => {
      if (event.keyCode === 13 && this.state.canSMSCodeBeSent) {
        // Enter
        event.preventDefault();
        this._onSendOtpCode(event);
      }
    });
    this.addEventListener('keydown', '[name="SMSCode"]', (event) => {
      if (event.keyCode === 13) {
        // Enter
        event.preventDefault();
        this._onVerifyOtpCode();
      }
    });
    this.addEventListener(
      'click',
      'input[data-target="field-focusable"][type="radio"][name="IsCompany"]',
      this._validateField.bind(this)
    );

    VerifyUserActions.initialiseForm(this.formName);
    this._onChange();
  }

  componentHasMounted() {
    VerifyUserStore.addChangeListener(this._onChange);
    FormStore.addChangeListener(this._onChange);
    AuthenticationStore.addChangeListener(this._onChange);
    this.prefillPhoneFields(VerifyUserStore.initialFormState);
    this.validateRequiredFields();
    this.validateCompanyFields(this.state.formState.IsCompany === 'true');
    this._onPhoneChange();
  }

  componentWillDismount() {
    AuthenticationStore.removeChangeListener(this._onChange);
    FormStore.removeChangeListener(this._onChange);
    VerifyUserStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    const oldFormState = this.state.formState;
    super._onChange(getState(this.formName));
    const resetFields = [
      { parentField: 'Country', childField: 'State' },
      { parentField: 'CompanyAddressCountry', childField: 'CompanyAddressState' },
    ];
    for (const { parentField: p, childField: c } of resetFields) {
      if (oldFormState[p] && oldFormState[p] !== this.state.formState[p]) {
        this._resetFormStateField(c);
      }
    }
  }

  async _onSubmission(event) {
    event.preventDefault();
    if (this.props.vatDetailsComponent) {
      if (
        !this.props.vatDetailsComponent.isValidationDone() &&
        !this.props.vatDetailsComponent.isVatEmpty() &&
        !this.props.vatDetailsComponent.isVatNumberValid() &&
        this.props.vatDetailsComponent.userInVatCountry()
      ) {
        await this.props.vatDetailsComponent.handleVatValidate();
        if (!this.props.vatDetailsComponent.isVatNumberValid()) {
          this.showFormErrors();
          return;
        }
      }
    }

    if (!this.state.invalidFieldCount) {
      FormActions.startSubmission(this.formName);
      if (
        this.props.vatDetailsComponent &&
        !this.props.vatDetailsComponent.isVatEmpty() &&
        this.props.vatDetailsComponent.userInVatCountry()
      ) {
        try {
          await this.props.vatDetailsComponent.submitVatNumber();
        } catch (error) {
          let errorMessage =
            'VAT details could not be submitted. Please refresh the page and try again or contact support@escrow.com';
          if (error.requestId) {
            errorMessage = `${errorMessage}. To help us assist you, please quote reference id: ${error.requestId}`;
          }
          FormActions.submissionFailure(this.formName, errorMessage);
          return;
        }
      }
      VerifyUserActions.submitCustomerDetails(
        this.formName,
        AuthenticationStore.getCustomerID(),
        VerifyUserStore.getCustomerDetails(this.formName),
        this.state.otpState
      );
    } else {
      this.showFormErrors();
    }
  }

  validateCompanyFields(showCompanyFields) {
    // When company fields are shown, ensure that the values are re-validated,
    // when the are hidden, ensure that the fields are removed from FormStore
    const fieldsets = this.rootNode.querySelectorAll('[data-target="company-fields"]');
    for (const fieldset of fieldsets) {
      const fields = fieldset.querySelectorAll('[data-target="field-focusable"]');
      if (showCompanyFields) {
        this.validateFields(fields);
      } else {
        this.resetFields(fields);
      }
    }
  }

  renderStateSelect(component, country, changedState, company = false) {
    const selectedState =
      changedState ||
      (company
        ? this.state.initialFormState.CompanyAddress.State
        : this.state.initialFormState.PersonalAddress.State);
    const regions = RegionsByCountry[CountryToAlpha2[country]];
    if (!regions) return;
    const uniqueName = (reg) =>
      regions.filter((r) => r.name === reg.name).length === 1
        ? reg.name
        : `${reg.name} (${reg.type})`;
    const options = regions.map((r) => ({
      label: uniqueName(r),
      selected:
        r.name === selectedState || uniqueName(r) === selectedState || r.code === selectedState,
    }));
    Object.assign(
      component,
      virtualize.fromHTML(
        Select.render({
          name: component.properties.name,
          id: component.properties.id,
          required: true,
          class: 'defaultSelect-select',
          attrs: { 'data-target': 'field-focusable' },
          options: [
            {
              placeholder: true,
              label: '-- Please select your state --',
              selected: options.filter((o) => o.selected).length === 0,
              disabled: true,
            },
            ...options,
          ],
        })
      )
    );
  }

  _onPhoneChange() {
    const phoneType = getNumberType(this.state.phoneNumber);
    if (phoneType === PhoneNumberType.FIXED_LINE && !this.state.phoneVerificationUseVoice) {
      this._onTogglePhoneVerificationStrategy();
    } else if (phoneType === PhoneNumberType.MOBILE && this.state.phoneVerificationUseVoice) {
      this._onTogglePhoneVerificationStrategy();
    }
  }

  _onSendOtpCode(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      event.preventDefault();
    }
    if (!this.state.canSMSCodeBeSent || this.state.otpState.sending) return;
    if (this.state.phoneVerificationUseVoice) {
      VerifyUserActions.sendCodeViaPhoneCall(
        this.formName,
        this.state.customerID,
        this.state.phoneNumber
      );
    } else {
      VerifyUserActions.requestSMSCode(
        this.formName,
        this.state.customerID,
        this.state.phoneNumber
      );
    }
  }

  _onVerifyOtpCode() {
    VerifyUserActions.verifySMSCode(
      this.formName,
      this.state.customerID,
      this.state.formState.SMSCode,
      this.state.phoneNumber
    );
  }

  _onTogglePhoneVerificationStrategy(event) {
    if (event) {
      event.preventDefault();
    }
    VerifyUserActions.togglePhoneVerificationStrategy(this.formName, this.state.phoneNumber);
  }

  _resetFormStateField(fieldName) {
    select(`[name="${fieldName}"]`)(this.template)[0].properties.value = '';
    this.setState(
      {
        formState: {
          ...this.state.formState,
          [fieldName]: '',
        },
      },
      true
    );
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    const shippingAddressFields = select('[data-target="shipping-address-fields"]')(vhtml);
    if (shippingAddressFields) {
      setVnodeClasses(shippingAddressFields[0], {
        'is-hidden': this.state.billingAddressAsShipping,
      });
    }

    const companyDetailFields = select('[data-target="company-fields"]')(vhtml);
    for (const companyFieldset of companyDetailFields || []) {
      setVnodeClasses(companyFieldset, { 'is-hidden': this.state.formState.IsCompany !== 'true' });
    }

    const tradingNameSelectBoxs = select('[data-dynamic-select="trading-name-select"]')(vhtml);
    const currentUsername = this.state.formState.Username
      ? this.state.formState.Username
      : this.state.initialFormState.Username;
    const displayNameType = this.state.formState.DisplayNameType
      ? this.state.formState.DisplayNameType
      : this.state.initialFormState.DisplayNameType;
    for (const tradingNameSelectBox of tradingNameSelectBoxs || []) {
      let fullName = this.state.formState.FirstName || '';
      const middleName = this.state.formState.MiddleName || this.state.initialFormState.MiddleName;
      fullName += middleName ? ` ${middleName}` : '';
      fullName += this.state.formState.LastName ? ` ${this.state.formState.LastName}` : '';
      tradingNameSelectBox.children = virtualize.fromHTML(
        TradingNameSelectBoxTpl.render({
          customer: {
            FullName: fullName,
            CompanyName: this.state.formState.CompanyName,
            Username: currentUsername,
            CompanyForm: window.location.href.indexOf('company'),
            DisplayNameType: displayNameType,
            ab_test_username:
              JSON.parse(window.localStorage.getItem('currentABTests'))['account-info-username'] !==
              'control',
          },
        })
      ).children;
    }

    const stateField = select('#field-State')(vhtml);
    if (stateField && this.state.formState.Country) {
      this.renderStateSelect(
        stateField[0],
        this.state.formState.Country,
        this.state.formState.State
      );
    }
    const companyStateField = select('#field-CompanyAddressState')(vhtml);
    if (companyStateField && this.state.formState.CompanyAddressCountry) {
      this.renderStateSelect(
        companyStateField[0],
        this.state.formState.CompanyAddressCountry,
        this.state.formState.CompanyAddressState,
        true
      );
    }

    const usernameContainer = select('[data-field="Username"]')(vhtml);
    if (
      !(
        this.state.formState.DisplayNameType === 'username' &&
        this.state.initialFormState.Username === null
      ) &&
      usernameContainer
    ) {
      setVnodeClasses(usernameContainer[0], { 'is-hidden': true });
    }

    if (!this.state.validFields.Phone) {
      const sendOtpButton = (select('[data-target="send-otp-code"]')(vhtml) || [])[0];
      if (sendOtpButton) {
        sendOtpButton.properties.disabled = true;
      }
    }

    if (this.state.phoneVerificationUseVoice) {
      const sendOtpButton = (select('[data-target="send-otp-code"]')(vhtml) || [])[0];
      const cardDesc = (select('[data-target="phone-verification-desc"]')(vhtml) || [])[0];
      const toggleLink = (select('[data-target="toggle-phone-verification-strategy"]')(vhtml) ||
        [])[0];
      if (sendOtpButton) {
        sendOtpButton.children = [new VText(gettext('Call me'))];
      }
      if (cardDesc) {
        cardDesc.children[0].text = cardDesc.children[0].text.replace(/landline/, 'mobile');
      }
      if (toggleLink) {
        toggleLink.children[0].text = toggleLink.children[0].text.replace(/a call/, 'an SMS');
      }
    }

    if (this.state.otpState.sent) {
      const otpVerificationDiv = (select('[data-target="otp-verify-block"]')(vhtml) || [])[0];
      const cardDesc = (select('[data-target="phone-verification-desc"]')(vhtml) || [])[0];
      const otpExpiryDiv = (select('[data-target="otp-expiry-countdown"]')(vhtml) || [])[0];
      if (otpVerificationDiv) {
        setVnodeClasses(otpVerificationDiv, { 'is-hidden': false });
        otpExpiryDiv.children = virtualize.fromHTML(
          OtpCountdownTpl.render({
            timeString: this.state.otpState.expires.toLocaleTimeString(),
          })
        ).children;
      }
      if (otpExpiryDiv) {
        otpExpiryDiv.children = virtualize.fromHTML(
          OtpCountdownTpl.render({
            timeString: this.state.otpState.expires.toLocaleTimeString(),
          })
        ).children;
        setVnodeClasses(otpExpiryDiv, { 'is-hidden': false });
      }
      if (cardDesc && !(this.state.otpState.attempts >= 3)) {
        cardDesc.children = virtualize.fromHTML(
          PhoneVerifyCardSentDescTpl.render({
            phoneNumber: this.state.phoneNumber,
            useVoice: this.state.phoneVerificationUseVoice,
          })
        ).children;
      }
    }

    if (this.state.otpState.sent && this.state.otpState.invalidCode) {
      const otpErrorMessage = (select('[data-field="SMSCode"] [class="field-errorMsg"]')(vhtml) ||
        [])[0];
      if (otpErrorMessage) {
        otpErrorMessage.children = [
          new VText(
            gettext(
              `The code you entered is incorrect, you have
         ${3 - this.state.otpState.attempts} attempts left.
          Sent to the wrong number? Adjust your number and request a new code
          by clicking the 'Send Code' button again`
            )
          ),
        ];
      }
    }

    if (this.state.otpState.verified) {
      const sendOtpButton = (select('[data-target="send-otp-code"]')(vhtml) || [])[0];
      const cardDesc = (select('[data-target="phone-verification-desc"]')(vhtml) || [])[0];
      const phoneNumberWrapperDiv = (select('[data-target="phone-number-wrapper"]')(vhtml) ||
        [])[0];
      if (phoneNumberWrapperDiv && this.state.otpState.hidePhoneNumber) {
        setVnodeClasses(phoneNumberWrapperDiv, { 'is-hidden': true });
      }
      if (sendOtpButton) {
        setVnodeClasses(sendOtpButton, { 'is-hidden': true });
      }
      if (cardDesc) {
        cardDesc.children = virtualize.fromHTML(
          OtpVerifedTpl.render({
            phoneNumber: this.state.phoneNumber,
            onAccountInfo: this.state.onAccountInfo,
          })
        ).children;
      }
    }

    if (!this.state.otpState.verified && this.state.otpState.attempts === 3) {
      const otpVerificationDiv = (select('[data-target="otp-verify-block"]')(vhtml) || [])[0];
      const sendOtpButton = (select('[data-target="send-otp-code"]')(vhtml) || [])[0];
      if (sendOtpButton) {
        setVnodeClasses(sendOtpButton, {
          'btn--primary': false,
          'btn--warning': true,
        });
        sendOtpButton.children = [new VText(gettext('Unverified'))];
        sendOtpButton.properties.disabled = true;
      }
      if (otpVerificationDiv) {
        otpVerificationDiv.children = virtualize.fromHTML(OtpFailureTpl.render()).children;
      }
    }

    if (this.state.otpState.sending) {
      const sendOtpButton = (select('[data-target="send-otp-code"]')(vhtml) || [])[0];
      if (sendOtpButton) {
        setVnodeClasses(sendOtpButton, { 'is-loading': true });
        sendOtpButton.properties.disabled = true;
      }
    }

    if (this.state.otpState.verifying) {
      const verifyOtpButton = (select('[data-target="verify-otp-code"]')(vhtml) || [])[0];
      if (verifyOtpButton) {
        setVnodeClasses(verifyOtpButton, { 'is-loading': true });
      }
    }

    if (this.state.otpState.apiError) {
      const apiErrorDiv = (select('[data-target="phone-verify-generic-error"]')(vhtml) || [])[0];
      if (apiErrorDiv) {
        setVnodeClasses(apiErrorDiv, { 'is-hidden': false });
      }
    }

    if (
      !this.state.otpState.verified &&
      this.state.otpState.attempts >= 3 &&
      (this.state.otpState.apiError || this.state.otpState.invalidCode)
    ) {
      const infoBox = (select('[data-target="phone-verify-info-card"]')(vhtml) || [])[0];
      if (infoBox) {
        setVnodeClasses(infoBox, { 'is-hidden': false });
      }
    }
    return super.render(vhtml);
  }
}

class DisbursementDeleteButton extends Component {
  constructor(element) {
    super(element);
    this.template = virtualize(element);
    this.state = {
      disbursementId: element.getAttribute('id'),
    };
    this.addEventListener('click', 'a', this.deleteDisbursement.bind(this));
  }

  deleteDisbursement(event) {
    event.preventDefault();
    API.deleteCustomerDisbursementMethod(this.state.disbursementId).then(() =>
      window.location.reload()
    );
  }

  render() {
    return this.template;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="account-info-form"]')) {
    const vatDetailsSection = elem.querySelector('[data-component="vat-details"]');
    let vatDetailsComponent = null;
    if (vatDetailsSection) {
      vatDetailsComponent = new VatDetailsComponent(vatDetailsSection, {
        parentFormName: vatDetailsSection.getAttribute('data-form-name'),
      });
      vatDetailsComponent.replace(vatDetailsSection);
    }
    const component = new AccountInfo(elem, {
      vatDetailsComponent: vatDetailsComponent,
    });
    component.replace(elem);
  }

  for (const elem of document.querySelectorAll('[data-component="delete-disbursement"]')) {
    const component = new DisbursementDeleteButton(elem);
    component.replace(elem);
  }
});
