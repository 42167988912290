import select from 'vtree-select';
import deepcopy from 'clone';
import VText from 'virtual-dom/vnode/vtext';
import classNames from 'classnames/dedupe';

import ModifyTransactionStore from '../stores/ModifyTransactionStore';
import Constants from '../constants/TransactionTermsConstants';
import Component from '../utils/Component';
import virtualize from '../utils/virtualize';
import { gettext } from '../utils/filters';

function getState() {
  return {
    modified: ModifyTransactionStore.modified(),
    fields: ModifyTransactionStore.getModifiedFields(),
  };
}

export default class TransactionTerms extends Component {
  constructor(element) {
    super();
    this.template = virtualize(element);
    this.setState(getState());
  }

  _onChange() {
    this.setState(getState());
  }

  componentHasMounted() {
    ModifyTransactionStore.addChangeListener(this._onChange.bind(this));
  }

  _getDisplayText(field, value) {
    if (field in Constants.DISPLAY_TEXT) {
      return Constants.DISPLAY_TEXT[field][value];
    } else if (field === 'InspectionLength') {
      return `${value} ${gettext('calendar days')}`;
    }
    return value;
  }

  render() {
    const vhtml = deepcopy(this.template, false);

    if (this.state.fields) {
      for (const field of Object.keys(this.state.fields)) {
        const elem = select(`[data-field="${field}"]`)(vhtml);
        if (elem) {
          elem[0].properties.className = classNames(elem[0].properties.className.split(' '), {
            'is-hidden': false,
          });
          const value = select('[data-value]')(elem[0])[0];
          const displayText = this._getDisplayText(field, this.state.fields[field]);
          value.children = [new VText(displayText)];
        }
      }
    }

    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="transactionTerms"]')) {
    const component = new TransactionTerms(elem);
    component.replace(elem, component.initialTemplate);
  }
});
