import classNames from 'classnames/dedupe';
import deepcopy from 'clone';
import virtualize from '../utils/virtualize';

import Component from '../utils/Component';
import TabStore from '../stores/TabStore';

export default class TabContent extends Component {
  constructor(element, props = {}) {
    super();

    if (!props.tabGroup) {
      throw new Error('You must define the tabGroup for a TabContent');
    }
    if (!props.tabId) {
      throw new Error('You must define the tabId for a TabContent');
    }

    this.props = props;
    this.template = virtualize(element);
    this._onChange = this._onChange.bind(this);
    this.setState(this._getState());
  }

  _onChange() {
    this.setState(this._getState());
  }

  _getState() {
    let active;
    if (TabStore.getCurrentTab(this.props.tabGroup)) {
      active = TabStore.getCurrentTab(this.props.tabGroup) === this.props.tabId;
    } else if (this.props.default) {
      active = true;
    }

    return {
      isActive: active,
    };
  }

  componentHasMounted() {
    TabStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    TabStore.removeClass(this._onChange);
  }

  render() {
    const vhtml = deepcopy(this.template, false);
    vhtml.properties.className = classNames(vhtml.properties.className, {
      'is-active-tab-content': this.state.isActive,
      'is-inactive-tab-content': !this.state.isActive,
    });
    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="tabcontent"]')) {
    const component = new TabContent(e, {
      tabId: e.getAttribute('data-tab-id'),
      tabGroup: e.getAttribute('data-tab-group'),
      default: e.hasAttribute('data-tab-default'),
    });
    component.replace(e);
  }
});
