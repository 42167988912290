import serialize from 'form-serialize';

import { Form } from './Form';
import FormStore from '../stores/FormStore';
import InlineAuthStore from '../stores/InlineAuthStore';
import InlineAuthConstants from '../constants/InlineAuthConstants';
import InlineAuthActions from '../actions/InlineAuthActions';

export class InlineAuth extends Form {
  constructor(element, options = {}) {
    super(element, options);

    const type = element.getAttribute('data-inline-auth-type');
    if (type && InlineAuthConstants.TYPES.indexOf(type) !== -1) {
      this.authType = type;
    } else {
      this.authType = InlineAuthConstants.TYPE_AGREE;
    }

    this._onChange = this._onChange.bind(this);
    this._onChange();
  }

  componentHasMounted() {
    FormStore.addChangeListener(this._onChange);
    InlineAuthStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    FormStore.removeChangeListener(this._onChange);
    InlineAuthStore.removeChangeListener(this._onChange);
  }

  _onSubmission(event) {
    event.preventDefault();
    this.validateRequiredFields();

    if (!this.state.invalidFieldCount && this.options.handleSubmission) {
      const data = serialize(event.target, { hash: true });
      InlineAuthActions.submit(data, this.formName, this.authType);
    } else {
      this.showFormErrors();
    }
  }

  _validateField(event) {
    if (event.target.name !== 'password') {
      super._validateField(event);
    }
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="inlineAuth"]')) {
    const component = new InlineAuth(elem);
    component.replace(elem, component.initialTemplate);
  }
});
