import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import Constants from '../constants/InlineAuthConstants';
import AuthenticationConstants from '../constants/AuthenticationConstants';
import NavigationStore from '../stores/NavigationStore';

export class InlineAuthStore extends ChangeEmitter {
  constructor() {
    super();
    this.signupShadowState = Constants.SIGNUP_STATES.INITIAL;
  }

  _signupShadowSuccess(email) {
    if (this.signupShadowState === Constants.SIGNUP_STATES.IN_PROGRESS) {
      this.signupShadowState = Constants.SIGNUP_STATES.INITIAL;
      NavigationStore.startTransactionPage(email);
    }
  }

  _signupShadowFailure() {
    this.signupShadowState = Constants.SIGNUP_STATES.INITIAL;
  }

  handleServerAction(action) {
    const actionType = action.actionType;
    if (actionType === AuthenticationConstants.SIGNUP_SHADOW_SUCCESS) {
      this._signupShadowSuccess(action.attributes.email);
    } else if (actionType === AuthenticationConstants.SIGNUP_SHADOW_FAILURE) {
      this._signupShadowFailure();
    }
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === Constants.SIGNUP_STATES.IN_PROGRESS) {
      this.signupShadowState = Constants.SIGNUP_STATES.IN_PROGRESS;
    }
  }
}

const inlineAuthStore = new InlineAuthStore();
inlineAuthStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    inlineAuthStore.handleViewAction(action);
  } else if (source === 'SERVER_ACTION') {
    inlineAuthStore.handleServerAction(action);
  }
});

export default inlineAuthStore;
