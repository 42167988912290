var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/icons/user.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push(true);
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "<svg version=\"1.1\" class=\"icon icon--user\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 20 20\" enable-background=\"new 0 0 20 20\" xml:space=\"preserve\">\n  <path d=\"M14.5,11c0.7-1.1,1.1-2.6,1.1-4.1C15.6,3.1,13.1,0,10,0C6.9,0,4.4,3.1,4.4,6.9c0,1.5,0.4,3,1.1,4.1\n  \tc-2.9,1.4-4.9,4.1-4.9,7.1v0.6c0,0.7,0.6,1.3,1.2,1.3h16.3c0.7,0,1.2-0.6,1.2-1.3v-0.6C19.4,15,17.4,12.4,14.5,11z M10,1.9\n  \tc2,0,3.7,2.3,3.7,5c0,2.7-1.7,5-3.7,5c-2,0-3.8-2.3-3.8-5C6.3,4.2,8,1.9,10,1.9z M2.5,18.1c0-2.5,1.7-4.6,4.3-5.6\n  \tc0.9,0.8,2,1.3,3.2,1.3s2.3-0.5,3.2-1.3c2.5,1,4.3,3.2,4.3,5.6H2.5z\"/>\n</svg>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("user");
context.setVariable("user", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/icons/user.html"] , dependencies)