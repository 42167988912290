var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/warning.html"] = require( "icons/warning.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/otp-failure.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/warning.html", false, "escrow_www/templates/js/otp-failure.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("warning")) {
var t_4 = t_1.warning;
} else {
cb(new Error("cannot import 'warning'")); return;
}
context.setVariable("warningIcon", t_4);
output += "<div>\n  <div class=\"field is-invalid\" data-target=\"phone-verify-generic-error\">\n    <div class=\"field-error\" data-target=\"phone-verify-generic-error\">\n      <span class=\"field-errorIcon\">";
output += runtime.suppressValue((lineno = 5, colno = 47, runtime.callWrap(t_4, "warningIcon", context, [])), env.opts.autoescape);
output += "</span>\n      <span class=\"field-errorMsg\" id=\"error-phone-verify-generic\">\n        We could not verify your phone. If you are having trouble verifying,\n        please contact us on <a href=\"mailto:support@escrow.com\">support@escrow.com</a>\n      </span>\n    </div>\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/otp-failure.html"] , dependencies)