import QueryString from 'query-string';
import serialize from 'form-serialize';
import window from 'window-shim';

import { Form } from './Form';
import FormActions from '../actions/FormActions';
import FormStore from '../stores/FormStore';
import NavigationActions from '../actions/NavigationActions';

export class ContactInformation extends Form {
  // Override the _onSubmission event from Form
  _onSubmission(event) {
    event.preventDefault();

    if (!this.state.invalidFieldCount) {
      const requestData = {
        json: serialize(event.target, {
          hash: true,
          empty: true,
        }),
        withCredentials: true,
      };
      FormActions.submit(this.endpoint, requestData, this.formName, this.successModal);
    } else {
      this.showFormErrors();
    }
  }

  // Override the _onChange event from Form
  _onChange() {
    const success = FormStore.submissionSuccess(this.formName);
    if (success) {
      const query = QueryString.parse(window.location.search);
      setTimeout(() => {
        NavigationActions.transactionInformation(query.tid);
      });
    }

    super._onChange();
  }

  render() {
    return super.render();
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="contactInformation"]')) {
    const component = new ContactInformation(elem);
    component.replace(elem, component.initialTemplate);
  }
});
