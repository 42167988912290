import h from 'virtual-dom/h';
import select from 'vtree-select';

import { Form } from '../components/Form';
import VerifyUserActions from '../actions/VerifyUserActions';
import FormStore from '../stores/FormStore';
import AuthenticationStore from '../stores/AuthenticationStore';
import { gettext } from '../utils/filters';
import AppDispatcher from '../dispatcher/AppDispatcher';
import FormConstants from '../constants/FormConstants';
import { validatePassword } from '../utils/FormValidation';
import { setVnodeClasses } from '../utils/set-vnode-classes';

function validateStateClientSide(prevState) {
  const currentPassword = { ...prevState.currentPassword };
  const newPassword = { ...prevState.newPassword };

  if (currentPassword.content === '') {
    currentPassword.errorMsg = 'Please enter your current password.';
  }

  if (newPassword.content === '') {
    newPassword.errorMsg = 'Please enter your new password.';
  }

  if (currentPassword.content === newPassword.content && newPassword.errorMsg === '') {
    newPassword.errorMsg = 'Your new password must be different from your current password.';
  }

  if (!validatePassword(newPassword.content) && newPassword.errorMsg === '') {
    newPassword.errorMsg = h('span', [
      'Strong passwords have:',
      h('ul', [
        h('li', '- At least 7 characters'),
        h('li', '- At least one UPPER case and one lower case character'),
        h('li', '- At least one number or special character'),
      ]),
    ]);
  }

  return {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };
}

function updateStateContents(name, prevState) {
  const formState = FormStore.currentFormState(name);
  const { currentPassword, newPassword } = prevState;
  return {
    currentPassword: {
      ...currentPassword,
      content: formState.currentPassword,
    },
    newPassword: {
      ...newPassword,
      content: formState.newPassword,
    },
  };
}

function resetStateErrorMsgs(prevState) {
  const { currentPassword, newPassword } = prevState;
  return {
    currentPassword: {
      ...currentPassword,
      errorMsg: '',
    },
    newPassword: {
      ...newPassword,
      errorMsg: '',
    },
  };
}

export class EditPassword extends Form {
  constructor(element, options = {}) {
    super(element, options);
    this.removeEventListeners('input', 'input[data-target="field-focusable"]');
    this.removeEventListeners('blur', '[data-target="field-focusable"]');

    this.addEventListener(
      'input',
      'input[data-target="field-focusable"]',
      this.handleFieldChange.bind(this)
    );

    const initState = {
      currentPassword: {
        type: 'password',
        fieldName: 'currentPassword',
        content: '',
        errorMsg: '',
      },
      newPassword: {
        type: 'password',
        fieldName: 'newPassword',
        content: '',
        errorMsg: '',
      },
    };
    super._onChange(initState);
  }

  handleFieldChange(event) {
    const fieldName = event.target.name;
    const type = event.target.getAttribute('data-type') || event.target.type;
    const contents = event.target.value;

    AppDispatcher.handleViewAction({
      actionType: FormConstants.FIELD_CHANGED,
      attributes: {
        form: this.formName,
        field: fieldName,
        contents: contents,
        type: type,
        override: false,
        required: true,
        skipValidation: true,
      },
    });
  }

  _onChange() {
    super._onChange(updateStateContents(this.formName, this.state));
  }

  _onSubmission(event) {
    let newState = resetStateErrorMsgs(this.state);
    newState = validateStateClientSide(newState);
    super._onChange(newState);

    event.preventDefault();
    let sendRequest = true;
    const { newPassword, currentPassword } = newState;
    if (newPassword.errorMsg !== '') {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELD_INVALID,
        attributes: {
          form: this.formName,
          field: newPassword.fieldName,
          contents: newPassword.content,
          type: newPassword.type,
          override: false,
          required: true,
          skipValidation: true,
        },
      });
      sendRequest = false;
    } else {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELD_VALID,
        attributes: {
          form: this.formName,
          field: newPassword.fieldName,
          contents: newPassword.content,
          type: newPassword.type,
          override: false,
          required: true,
          skipValidation: true,
        },
      });
    }

    if (currentPassword.errorMsg !== '') {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.FIELD_INVALID,
        attributes: {
          form: this.formName,
          field: currentPassword.fieldName,
          contents: currentPassword.content,
          type: currentPassword.type,
          override: false,
          required: true,
          skipValidation: true,
        },
      });
      sendRequest = false;
    } else {
      AppDispatcher.handleViewAction({
        actionType: FormConstants.CLEAR_FIELD_VALIDATION,
        attributes: {
          form: this.formName,
          field: currentPassword.fieldName,
          contents: currentPassword.content,
          type: currentPassword.type,
          override: false,
          required: true,
          skipValidation: true,
        },
      });
    }

    if (sendRequest) {
      const handleSuccess = () => {
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_SUCCESS,
          name: this.formName,
        });
      };
      const handleFailure = (errorMessage) => {
        super._onChange({
          currentPassword: {
            ...this.state.currentPassword,
            errorMsg: 'The entered password is incorrect. Please try again.',
          },
        });
        AppDispatcher.handleServerAction({
          actionType: FormConstants.SUBMISSION_FAILURE,
          name: this.formName,
          title: errorMessage,
        });
      };
      VerifyUserActions.submitEditPassword(
        this.formName,
        AuthenticationStore.getCustomerID(),
        this.state.currentPassword.content,
        this.state.newPassword.content,
        handleSuccess,
        handleFailure
      );
    } else {
      this.showFormErrors();
    }
  }

  render() {
    const vhtml = super.render();

    // Clear valid/invalid classes for fields without validationState and errorMsg
    const validationState = this.state.validFields;
    if (validationState) {
      const fields = select('[data-target="field"]')(vhtml) || [];
      for (const field of fields) {
        const child = select('[data-target="field-focusable"]')(field)[0];
        const name = child.properties.name;
        const valid = validationState[name];

        if (valid === undefined && this.state[name].errorMsg === '') {
          setVnodeClasses(field, { 'is-invalid': false });
        }
      }

      const currentPasswordError = select('[id="error-currentPassword"]')(vhtml)[0];
      if ('currentPassword' in this.state && this.state.currentPassword.errorMsg !== '') {
        const msg = h('span', gettext(this.state.currentPassword.errorMsg));
        currentPasswordError.children = [msg];
      }
      const newPasswordError = select('[id="error-newPassword"]')(vhtml)[0];
      if ('newPassword' in this.state && this.state.newPassword.errorMsg !== '') {
        const msg = h('span', this.state.newPassword.errorMsg);
        newPasswordError.children = [msg];
      }
    }

    const currentPasswordError = select('[id="error-currentPassword"]')(vhtml)[0];
    if ('currentPassword' in this.state && this.state.currentPassword.errorMsg !== '') {
      const msg = h('span', gettext(this.state.currentPassword.errorMsg));
      currentPasswordError.children = [msg];
    }
    const newPasswordError = select('[id="error-newPassword"]')(vhtml)[0];
    if ('newPassword' in this.state && this.state.newPassword.errorMsg !== '') {
      const msg = h('span', this.state.newPassword.errorMsg);
      newPasswordError.children = [msg];
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="edit-password-form"]')) {
    const component = new EditPassword(elem);
    component.replace(elem);
  }
});
