import classNames from 'classnames/dedupe';
import cuid from 'cuid';
import select from 'vtree-select';
import virtualize from '../utils/virtualize';

import AnnouncementActions from '../actions/AnnouncementActions';
import AnnouncementStore from '../stores/AnnouncementStore';
import Component from '../utils/Component';
import { setStickyNavOffset, updateStickyNav } from '../navigation/sticky-nav';

function getState(announcementId) {
  return {
    show: AnnouncementStore.shouldShow(announcementId),
    expanded: AnnouncementStore.isExpanded(announcementId),
  };
}

export default class Announcement extends Component {
  constructor(element, props = {}) {
    super();

    if (!props.announcementId) {
      props.announcementId = cuid();
    }

    this.props = props;
    this.template = element.outerHTML;

    this.addEventListener('click', '[data-target="announcement-close"]', this._onClose.bind(this));

    this.addEventListener(
      'click',
      '[data-target="announcement-extra-expand"]',
      this._onExpand.bind(this)
    );

    this._onChange = this._onChange.bind(this);
    this.setState(getState(this.props.announcementId));
    updateStickyNav();
  }

  componentDidUpdate() {
    // The header offset needs to be recalculated when the banner is hidden
    setStickyNavOffset();
  }

  componentHasMounted() {
    AnnouncementStore.addChangeListener(this._onChange);
  }

  componentWillDismount() {
    AnnouncementStore.removeChangeListener(this._onChange);
  }

  _onClose(event) {
    event.preventDefault();
    AnnouncementActions.hide(this.props.announcementId, this.props.persistent);
    return false;
  }

  _onChange() {
    this.setState(getState(this.props.announcementId));
  }

  _onExpand(event) {
    event.preventDefault();
    AnnouncementActions.expand(this.props.announcementId);
    return false;
  }

  render() {
    const vhtml = virtualize.fromHTML(this.template);

    // Handle the case when the user has closed the announcement
    vhtml.properties.className = classNames(vhtml.properties.className.split(' '), {
      'is-hidden': !this.state.show,
    });

    // Allow the user to expand the announcement
    const expandElements = select('[data-target="announcement-extra"]')(vhtml);
    if (expandElements) {
      for (const n of expandElements) {
        n.properties.className = classNames(n.properties.className.split(' '), {
          'is-contracted': !this.state.expanded,
        });
      }
    }
    return vhtml;
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="announcement"]')) {
    new Announcement(e, {
      announcementId: e.getAttribute('data-announcement-id'),
      persistent: e.getAttribute('data-announcement-close-persists') === 'true',
    }).replace(e);
  }
});
