import window from 'window-shim';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import ContentFeedbackConstants from '../constants/ContentFeedbackConstants';

export class ContentFeedbackStore extends ChangeEmitter {
  constructor() {
    super();

    this.currentPanel = ContentFeedbackConstants.BASE_PANEL;
  }

  getCurrentPanel() {
    return this.currentPanel;
  }

  changeDisplayedPanel(panel) {
    this.currentPanel = panel;
    this.emitChange();
  }

  sendFeedback(feedback, section) {
    const feedbackEvent = {
      event: 'escrow_feedback_action',
      eventFeedback: feedback,
      eventSection: section,
    };

    window.dataLayer.push(feedbackEvent);

    this.currentPanel = ContentFeedbackConstants.THANKS_PANEL;
    this.emitChange();
  }

  handleViewAction(action) {
    if (action.actionType === ContentFeedbackConstants.CHANGE_DISPLAYED_PANEL) {
      this.changeDisplayedPanel(action.attributes.panel);
    } else if (action.actionType === ContentFeedbackConstants.SEND_FEEDBACK) {
      this.sendFeedback(action.attributes.feedback, action.attributes.section);
    }
  }
}

const contentFeedbackStore = new ContentFeedbackStore();
contentFeedbackStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    contentFeedbackStore.handleViewAction(action);
  }
});

export default contentFeedbackStore;
