var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["components/inputs.html"] = require( "components/inputs.html" );
dependencies["components/buttons.html"] = require( "components/buttons.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/forms/apiKeyInput.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("components/inputs.html", false, "escrow_www/templates/forms/apiKeyInput.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("input", t_1);
env.getTemplate("components/buttons.html", false, "escrow_www/templates/forms/apiKeyInput.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(context.getVariables(), frame, function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("button", t_4);
output += "<form data-component=\"api-key-input\" class=\"card apiDashboard-form\">\n  <div class=\"card-header card-inner\">\n    <h3 class=\"card-header-title\">Generate API Key</h3>\n  </div>\n  <div class=\"card-inner\">\n    ";
output += runtime.suppressValue((lineno = 8, colno = 15, runtime.callWrap(runtime.memberLookup((t_1),"text"), "input[\"text\"]", context, [(lineno = 8, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["New Key Name"])),runtime.makeKeywordArgs({"name": "api_key_name","placeholder": (lineno = 8, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["API Key Name"])),"required": "true","error": (lineno = 8, colno = 129, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Please enter a name"])),"attrs": {"data-max-length": "50","data-e2e-target": "new-api-key"},"shorten": runtime.contextOrFrameLookup(context, frame, "True")})])), env.opts.autoescape);
output += "\n\n    ";
output += runtime.suppressValue((lineno = 10, colno = 21, runtime.callWrap(runtime.memberLookup((t_4),"secondary"), "button[\"secondary\"]", context, [(lineno = 10, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "gettext"), "gettext", context, ["Generate API Key"])),runtime.makeKeywordArgs({"specifier": "accountForm-btn","attrs": {"data-target": "form-submit","data-e2e-target": "start-transaction-button"},"type": "submit"})])), env.opts.autoescape);
output += "\n\n    ";
if(runtime.contextOrFrameLookup(context, frame, "error")) {
output += "\n      <div class=\"defaultForm-errors\" data-target=\"form-error\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "error"), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n  </div>\n</form>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/forms/apiKeyInput.html"] , dependencies)