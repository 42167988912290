import { Form } from '../components/Form';
import FormStore from '../stores/FormStore';
import FormActions from '../actions/FormActions';
import API from '../api/API';

function getState(formName) {
  return {
    formState: FormStore.currentFormState(formName),
    invalidFieldCount: FormStore.invalidFieldCount(formName),
  };
}

export class DomainReport extends Form {
  _onSubmission(event) {
    event.preventDefault();

    const state = getState(this.formName);
    if (!state.invalidFieldCount) {
      const email = state.formState['email-address'];
      FormActions.startSubmission(this.formName);
      API.sendDomainReportRequest(email)
        .then(() => {
          FormActions.submissionSuccess(this.formName);
          const formDiv = document.querySelector('[data-component="domain-report-form"]');
          const formSuccessDiv = document.querySelector('[data-target="domain-report-success"]');
          formDiv.classList.add('is-hidden');
          formSuccessDiv.classList.remove('is-hidden');
        })
        .catch(() => {
          FormActions.submissionFailure(
            this.formName,
            'An unexpected error occurred. Please contact support@escrow.com'
          );
        });
    } else {
      this.showFormErrors();
    }
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="domain-report-form"]')) {
    const component = new DomainReport(elem, {
      formName: elem.getAttribute('data-form-name'),
    });
    component.replace(elem);
  }
});
