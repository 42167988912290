import AppDispatcher from '../dispatcher/AppDispatcher';
import NavigationActions from '../actions/NavigationActions';
import TabConstants from '../constants/TabConstants';

export class TabActions {
  select(tabId, tabGroup, url = null, navigate = true) {
    AppDispatcher.handleViewAction({
      actionType: TabConstants.TAB_SELECT,
      tabId: tabId,
      tabGroup: tabGroup,
    });

    if (navigate) {
      NavigationActions.pushState(
        {
          tabs: {
            [tabGroup]: tabId,
          },
        },
        null,
        url
      );
    }
  }
}

export default new TabActions();
