export default {
  INITIALISE_BOX: 'UPLOAD_INITIALISE_BOX',
  REMOVE_FILE: 'REMOVE_FILE',
  ADD_FILE: 'ADD_FILE',
  FILE: 'file',
  FILE_MAX_SIZE: 100000000,
  FILE_UPLOAD_FIELD: 'file-upload',
  KILOBYTE: 1000,
  MEGABYTE: 1000000,
  ALLOWED_FILE_TYPES: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/tiff',
    'image/bmp',
    'application/pdf',
  ],
  UPLOAD_FAIL: 'UPLOAD_FAIL',
  UPLOAD_UNAUTHORIZED: 'UPLOAD_UNAUTHORIZED',
  UPLOAD_INVALID: 'UPLOAD_INVALID',
  UPLOAD_OK: 'UPLOAD_OK',
  UPLOAD_START: 'UPLOAD_START',
  UPLOAD_IN_PROGRESS: 'UPLOAD_IN_PROGRESS',
  DRAG_ENTER: 'UPLOAD_DRAG_ENTER',
  DRAG_LEAVE: 'UPLOAD_DRAG_LEAVE',
  LOCALSTORAGE_FILE_KEY: 'persistentFiles',
  SESSION_TIMEOUT_MODAL: 'modal-session-timeout',
  SET_FILE_PERSISTENCE: 'UPLOAD_SET_FILE_PERSISTENCE',
};
