import window from 'window-shim';

import { SimpleForm } from '../utils/SimpleForm';
import StartTransactionActions from '../actions/StartTransactionActions';
import TrackingActions from '../actions/TrackingActions';
import AuthenticationStore from '../stores/AuthenticationStore';

export class CheckoutStartTransaction extends SimpleForm {
  constructor(element, options = {}) {
    super(element, options);
    this.addEventListener('submit', 'form', this.onSubmission.bind(this));
  }

  componentHasMounted() {
    super.componentHasMounted();
    TrackingActions.track({
      event: 'escrow_user_action',
      section: 'buy-it-now-button',
      name: 'buy-it-now-button-checkout-view',
      page: window.location.referrer,
      value: window.checkout_data.item.domain,
      label: window.checkout_data.non_initiator.id,
      action: 'page-view',
    });
  }

  onSubmission(event) {
    if (!super.onSubmission(event)) {
      return false;
    }

    const data = Object.assign({}, window.checkout_data);

    const loginData = {
      email: this.state.formState['email-address'],
      password: this.state.formState.password,
      returning: this.state.formState.returning === 'login',
    };

    const buyerEmail = this.state.formState['email-address'] || AuthenticationStore.email;

    const feeList = [];
    if (data.fee_payer === 'buyer') {
      feeList.push({
        payer_customer: buyerEmail,
        split: 1,
        type: 'escrow',
      });
    } else if (data.fee_payer === 'seller') {
      feeList.push({
        payer_customer: data.non_initiator.email,
        split: 1,
        type: 'escrow',
      });
    } else {
      feeList.push({
        payer_customer: buyerEmail,
        split: 0.5,
        type: 'escrow',
      });
      feeList.push({
        payer_customer: data.non_initiator.email,
        split: 0.5,
        type: 'escrow',
      });
    }

    let currency = 'usd';
    if (data.currency === 'EUR') {
      currency = 'euro';
    }

    const formData = {
      parties: [
        {
          role: 'buyer',
          customer: buyerEmail,
        },
        {
          role: 'seller',
          customer: data.non_initiator.email,
          agreed: true,
        },
      ],
      currency: currency,
      description: data.item.domain,
      items: [
        {
          title: data.item.domain,
          description: data.item.domain,
          type: 'domain_name',
          inspection_period: data.inspection_period * 86400,
          quantity: 1,
          schedule: [
            {
              amount: data.item.price,
              payer_customer: buyerEmail,
              beneficiary_customer: data.non_initiator.email,
            },
          ],
          extra_attributes: {
            with_content: data.item.with_content,
            concierge: data.upgrades.concierge,
          },
          fees: feeList,
        },
      ],
    };

    StartTransactionActions.submitCheckout(formData, loginData, this.formName);
    return false;
  }
}

setTimeout(() => {
  for (const elem of document.querySelectorAll('[data-component="checkout-start-transaction"]')) {
    const component = new CheckoutStartTransaction(elem);
    component.replace(elem, component.initialTemplate);
  }
});
