var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../lib/nunjucks/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["icons/close.html"] = require( "icons/close.html" );
dependencies["icons/tick.html"] = require( "icons/tick.html" );
dependencies["icons/warning.html"] = require( "icons/warning.html" );




var shim = require("/code/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["escrow_www/templates/js/verifyUploadListItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("icons/close.html", false, "escrow_www/templates/js/verifyUploadListItem.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(context.getVariables(), frame, function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(t_1.hasOwnProperty("close")) {
var t_4 = t_1.close;
} else {
cb(new Error("cannot import 'close'")); return;
}
context.setVariable("closeIcon", t_4);
env.getTemplate("icons/tick.html", false, "escrow_www/templates/js/verifyUploadListItem.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(context.getVariables(), frame, function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(t_5.hasOwnProperty("tick")) {
var t_8 = t_5.tick;
} else {
cb(new Error("cannot import 'tick'")); return;
}
context.setVariable("tickIcon", t_8);
env.getTemplate("icons/warning.html", false, "escrow_www/templates/js/verifyUploadListItem.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(context.getVariables(), frame, function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(t_9.hasOwnProperty("warning")) {
var t_12 = t_9.warning;
} else {
cb(new Error("cannot import 'warning'")); return;
}
context.setVariable("warningIcon", t_12);
output += "<div class=\"uploadedFiles-item\">\n  <span class=\"uploadedFiles-details\">\n    ";
if(runtime.contextOrFrameLookup(context, frame, "status") == "UPLOAD_IN_PROGRESS") {
output += "\n      <div class=\"uploadedFiles-progressBar progressBar\">\n        <div class=\"progressBar-value\" style=\"width: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "progress"), env.opts.autoescape);
output += "%;\"></div>\n      </div>";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") == "UPLOAD_OK") {
output += "<div class=\"uploadedFiles-progressBar progressBar\">\n        <div class=\"progressBar-value\" style=\"width: 100%;\"></div>\n      </div>\n      <span class=\"uploadedFiles-statusIcon uploadedFiles-statusIcon--success\">\n        ";
output += runtime.suppressValue((lineno = 15, colno = 17, runtime.callWrap(t_8, "tickIcon", context, [])), env.opts.autoescape);
output += "\n      </span>";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") == "UPLOAD_FAIL" || runtime.contextOrFrameLookup(context, frame, "status") == "UPLOAD_INVALID") {
output += "<span class=\"uploadedFiles-statusIcon uploadedFiles-statusIcon--error\">\n        ";
output += runtime.suppressValue((lineno = 19, colno = 20, runtime.callWrap(t_12, "warningIcon", context, [])), env.opts.autoescape);
output += "\n      </span>";
;
}
;
}
;
}
output += "<span class=\"uploadedFiles-fileName\">";
if(runtime.contextOrFrameLookup(context, frame, "status") == "UPLOAD_INVALID") {
output += "<strong>";
output += runtime.suppressValue(env.getExtension("TranslationExtension")["run"](context,function(cb) {
if(!cb) { cb = function(err) { if(err) { throw err; }}}
var t_13 = "";t_13 += "(Invalid file) ";
cb(null, t_13);
;
return t_13;
}
), true && env.opts.autoescape);
output += "</strong>";
;
}
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "\n    </span>\n  </span>\n  <span class=\"uploadedFiles-control\">\n    <span class=\"uploadedFiles-size\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
output += "</span>\n    <span>\n      <button type=\"button\" class=\"uploadedFiles-btn\" data-remove-file=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "number"), env.opts.autoescape);
output += "\">\n        ";
output += runtime.suppressValue((lineno = 32, colno = 18, runtime.callWrap(t_4, "closeIcon", context, [])), env.opts.autoescape);
output += "\n      </button>\n    </span>\n  </span>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["escrow_www/templates/js/verifyUploadListItem.html"] , dependencies)