import { gettext } from '../utils/filters';

export default {
  DISPLAY_TEXT: {
    Fulfillment: {
      1: gettext('Standard shipping'),
      2: gettext('Domain name transfer'),
      3: gettext('Cargo shipping'),
      4: gettext('No shipping'),
    },
    EscrowPayment: {
      0: gettext('Buyer'),
      1: gettext('Seller'),
      2: gettext('50% Buyer / 50% Seller'),
      3: gettext('50% Broker / 50% Buyer'),
      4: gettext('Broker'),
      5: gettext('50% Broker / 50% Seller'),
    },
    Concierge: {
      DNC: gettext('Escrow.com concierge service'),
      '': gettext('Buyer and seller'),
    },
  },
};
