import h from 'virtual-dom/h';
import formatNumber from 'format-number';

import Component from '../utils/Component';
import CounterStore from '../stores/CounterStore';

function getState() {
  return {
    currentValue: CounterStore.getValue(),
  };
}

export default class Counter extends Component {
  constructor() {
    super();

    this.setState(getState());
  }

  componentWillMount() {
    CounterStore.addChangeListener(this._onChange.bind(this));
  }

  _onChange() {
    this.setState(getState());
  }

  render() {
    const formatter = formatNumber();
    return h('span', {}, formatter(this.state.currentValue));
  }
}

setTimeout(() => {
  for (const e of document.querySelectorAll('[data-component="counter"]')) {
    new Counter().placeInto(e);
  }
});
