import window from 'window-shim';

import Constants from '../constants/FileUploadConstants';
import ModalConstants from '../constants/ModalConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import FileUploadStore from '../stores/FileUploadStore';

export class FileUploadActions {
  initialiseUploadBox(boxName) {
    AppDispatcher.handleViewAction({
      actionType: Constants.INITIALISE_BOX,
      boxName: boxName,
    });
  }

  postFile(boxName, file) {
    const handleError = (error) => {
      let uploadStatus = error;
      // Default to UPLOAD_FAIL if the fetch call rejects
      if (
        error !== Constants.UPLOAD_FAIL &&
        error !== Constants.UPLOAD_INVALID &&
        error !== Constants.UPLOAD_UNAUTHORIZED
      ) {
        uploadStatus = Constants.UPLOAD_FAIL;
      }

      AppDispatcher.handleServerAction({
        actionType: Constants.ADD_FILE,
        boxName: boxName,
        attributes: {
          uploadStatus: uploadStatus,
          fileName: file.name,
          uniqueId: null,
          size: file.size,
          progress: 0,
        },
      });

      if (uploadStatus === Constants.UPLOAD_UNAUTHORIZED) {
        AppDispatcher.handleViewAction({
          actionType: ModalConstants.MODAL_OPEN,
          name: Constants.SESSION_TIMEOUT_MODAL,
        });
      }
    };

    const handleLoad = (response) => {
      if (response.target.status !== 200) {
        if (response.target.status === 401) {
          handleError(Constants.UPLOAD_UNAUTHORIZED);
        } else {
          handleError(Constants.UPLOAD_FAIL);
        }
      } else {
        const data = JSON.parse(response.target.response);
        AppDispatcher.handleServerAction({
          actionType: Constants.ADD_FILE,
          boxName: boxName,
          attributes: {
            uploadStatus: Constants.UPLOAD_OK,
            fileName: file.name,
            uniqueId: data.Id,
            size: file.size,
            progress: 100,
          },
        });
      }
    };

    const updateProgress = (event) => {
      if (event.lengthComputable) {
        // Set a max of 95% because this event may read 100% before the success
        // handler is called
        const progress = Math.min(100 * (event.loaded / event.total), 95);
        AppDispatcher.handleServerAction({
          actionType: Constants.ADD_FILE,
          boxName: boxName,
          attributes: {
            uploadStatus: Constants.UPLOAD_IN_PROGRESS,
            fileName: file.name,
            uniqueId: null,
            size: file.size,
            progress: progress,
          },
        });
      }
    };

    if (!FileUploadStore.hasFile(boxName, file)) {
      if (this.fileCheck(file)) {
        AppDispatcher.handleServerAction({
          actionType: Constants.ADD_FILE,
          boxName: boxName,
          attributes: {
            uploadStatus: Constants.UPLOAD_START,
            fileName: file.name,
            uniqueId: null,
            size: file.size,
            progress: 0,
          },
        });

        const formData = new FormData();
        formData.append(Constants.FILE, new Blob([file]), file instanceof Blob ? file.name : null);
        const xhr = new window.XMLHttpRequest();
        xhr.upload.onprogress = updateProgress;
        xhr.addEventListener('load', handleLoad);
        xhr.addEventListener('error', handleError);
        xhr.open('post', `${window.config.kyc_api_endpoint}/File`, true);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.withCredentials = true;
        xhr.send(formData);
      } else {
        handleError(Constants.UPLOAD_INVALID);
      }
    }
  }

  fileCheck(file) {
    let type = file.type;
    // IE 11 gives an empty type attribute for PDFs, fall back to checking the
    // file extension.
    if (!type && file.name.includes('.pdf')) {
      type = 'application/pdf';
    }
    if (file.size <= Constants.FILE_MAX_SIZE && Constants.ALLOWED_FILE_TYPES.indexOf(type) !== -1) {
      return true;
    }
    return false;
  }

  removeFile(boxName, fileNumber) {
    AppDispatcher.handleViewAction({
      actionType: Constants.REMOVE_FILE,
      boxName: boxName,
      fileNumber: fileNumber,
    });
  }

  dragEnter(boxName) {
    AppDispatcher.handleViewAction({
      actionType: Constants.DRAG_ENTER,
      boxName: boxName,
    });
  }

  dragLeave(boxName) {
    AppDispatcher.handleViewAction({
      actionType: Constants.DRAG_LEAVE,
      boxName: boxName,
    });
  }

  addFile(boxName) {
    AppDispatcher.handleViewAction({
      actionType: Constants.ADD_FILE,
      boxName: boxName,
    });
  }

  setFilePersistence(boxName, shouldPersist) {
    AppDispatcher.handleViewAction({
      actionType: Constants.SET_FILE_PERSISTENCE,
      boxName: boxName,
      shouldPersist: shouldPersist,
    });
  }
}

export default new FileUploadActions();
