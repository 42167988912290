import localStorage from 'local-storage';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { ChangeEmitter } from '../utils/ChangeEmitter';
import AnnouncementConstants from '../constants/Announcement';

export class AnnouncementStore extends ChangeEmitter {
  constructor() {
    super();

    this.hiddenAnnouncements = {};
    this.expandedAnnouncements = {};
  }

  shouldShow(announcementId) {
    if (this.hiddenAnnouncements[announcementId]) {
      return false;
    }
    if (localStorage.get(`announcements-${announcementId}`)) {
      return false;
    }

    return true;
  }

  hide(announcementId, persist) {
    this.hiddenAnnouncements[announcementId] = true;
    if (persist) {
      localStorage.set(`announcements-${announcementId}`, true);
    }
  }

  isExpanded(announcementId) {
    return this.expandedAnnouncements[announcementId];
  }

  expand(announcementId) {
    this.expandedAnnouncements[announcementId] = true;
  }

  handleViewAction(action) {
    const actionType = action.actionType;
    if (actionType === AnnouncementConstants.ANNOUNCEMENT_CLOSE) {
      this.hide(action.announcementId, action.persist);
      this.emitChange();
    }

    if (actionType === AnnouncementConstants.ANNOUNCEMENT_EXPAND) {
      this.expand(action.announcementId);
      this.emitChange();
    }
  }
}

const announcementStore = new AnnouncementStore();
announcementStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.action;
  const source = payload.source;

  if (source === 'VIEW_ACTION') {
    announcementStore.handleViewAction(action);
  }
});

export default announcementStore;
