import AppDispatcher from '../dispatcher/AppDispatcher';
import ABTestConstants from '../constants/ABTestConstants';

export class ABTestActions {
  registerVariant(description, parameter) {
    AppDispatcher.handleViewAction({
      actionType: ABTestConstants.REGISTER_VARIANT,
      testDescription: description,
      parameter: parameter,
    });
  }

  enrol(experimentName, split) {
    AppDispatcher.handleViewAction({
      actionType: ABTestConstants.ENROL,
      attributes: {
        experimentName,
        split,
      },
    });
  }

  enrolComplete(experimentName, bucket) {
    AppDispatcher.handleViewAction({
      actionType: ABTestConstants.ENROL_COMPLETE,
      attributes: {
        experimentName,
        bucket,
      },
    });
  }
}

export default new ABTestActions();
